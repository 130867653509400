
export class DashboardFilter {

    budgetManagerIds: Array<number>;
    departmentIds: Array<number>;
    divisionIds: Array<number>;
    costCenterIds: Array<number>;
    subTypeIds: Array<number>;
    typeIds: Array<number>;
    assetTypeIds: Array<number>;
    projectIds: Array<number>;
    reportType: number;
    filterDepartment: string;
    filterDivision: string;
    filterCostCenter: string;
    filterType: string;
    filterAssetType: string;
    filter: string;
}