import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppConfig } from '../../../config';
import { AppState } from '../../../model/api/common/app-state';
import { GenericHttpService } from '../generic.http.service';

@Injectable()
export class AppStateHttpService extends GenericHttpService<AppState, number> {
    constructor(public http: HttpClient) {
        super(http, "", "appstates");
    }

    getDetailByParentCode(parentCode: string): Observable<any> {
        return this.http.get(AppConfig.urlPrefix + this.url + `/parentCode/${parentCode}`).pipe(
            map((data: Response) => {
                return data;
            }));
    }
}