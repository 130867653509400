// import { Component, ElementRef } from '@angular/core';
// import { AppData } from '../../../app-data';
// import { AssetCategoryTotal } from '../../../model/api/assets/asset-category-total';
// import { AssetDepTotal } from '../../../model/api/assets/asset-dep-total';
// import { Param } from '../../../model/common/param';
// import { GenericTableList } from '../../generic/generic.table.list';

// @Component({
//     selector: 'budget-list',
//     templateUrl: '../../administrations/budget/budget.list.html',
//     inputs: [ 'listTemplate' ],
// })
// export class BudgetList extends GenericTableList<any, number> {
//     public query = '';
//     public filteredList = [];
//     public elementRef;
//     public listTemplate: string = 'BUGETS';
//     public loadType: string = '';
//     public totals: AssetDepTotal = null;
//     public categoryTotals: AssetCategoryTotal = null;


//     constructor(public myElement: ElementRef) {
//         super('invNo', 'asc');

//         this.columns = AppData.ColumnDefinitions[this.listTemplate];
//         this.elementRef = myElement;
//     }

//     public refresh(filters: Array<Param>) {
//         this.columns = AppData.ColumnDefinitions[this.listTemplate];
//         super.refresh(filters);
//     }

//     public select(item){
//         this.query = item;
//         this.filteredList = [];
//         if(item != null){
//             this.tableItems = this.tableItems.filter(function(el){
//                 return el.item.invNo.toString().toLowerCase().indexOf(item.toLowerCase()) > -1;
//             })
//         }
//     }
// }

import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, ViewChild } from '@angular/core';
import { MatButtonToggleGroup } from '@angular/material/button-toggle';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MessageService, TreeNode } from 'primeng/api';
import { Table, TableService } from 'primeng/table';
import { AppData } from '../../../app-data';
import { AssetDepTotal } from '../../../model/api/assets/asset-dep-total';
import { ForecastTotal } from '../../../model/api/assets/forecast-total';
import { Param } from '../../../model/common/param';
import { GenericPrimeNgDataTableList } from '../../generic/generic.prime-ng-data-table.list';
import { GenericTableList } from '../../generic/generic.table.list';


@Component({
    selector: 'app-budget-forecast-list',
    templateUrl: './budget-forecast.list.html',
    styleUrls: ['./budget-forecast.list.scss'],
    inputs: [ 'listTemplate' ],
    providers: [Table, TableService]
})
export class BudgetForecastListComponent extends GenericTableList<any, number> implements AfterViewInit {
// export class BudgetList extends GenericPrimeNgDataTableList<any, number> {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
    public columns: any = [];

    public query = '';
    public filteredList = [];
    public elementRef;
    public listTemplate: string = 'BUGETFORECASTS';
    public loadType: string = '';
    public totals: ForecastTotal = null;
    item: any;
    productDialog: boolean;
    selectedNodes3: TreeNode[];

    selection = new SelectionModel<any>(true, []);

    dataSourceMT: MatTableDataSource<any>;
    displayedColumns: string[] = ['id', 'code'];

    tables = [0];

    dataTree = {
      value: {
        name: 'Reports',
        owner: 'Eric',
        protected: true,
        backup: true
      },
      children: [
        {
          value: {
            name: 'Charts',
            owner: 'Stephanie',
            protected: false,
            backup: true
          },
          children: []
        },
        {
          value: {
            name: 'Sales',
            owner: 'Virginia',
            protected: false,
            backup: true
          },
          children: []
        },
        {
          value: {
            name: 'US',
            owner: 'Alison',
            protected: true,
            backup: false
          },
          children: [
            {
              value: {
                name: 'California',
                owner: 'Claire',
                protected: false,
                backup: false
              },
              children: []
            },
            {
              value: {
                name: 'Washington',
                owner: 'Colin',
                protected: false,
                backup: true
              },
              children: [
                {
                  value: {
                    name: 'Domestic',
                    owner: 'Oliver',
                    protected: true,
                    backup: false
                  },
                  children: []
                },
                {
                  value: {
                    name: 'International',
                    owner: 'Oliver',
                    protected: true,
                    backup: true
                  },
                  children: []
                }
              ]
            }
          ]
        }
      ]
    };


    // displayedColumns: string[] = ['name', 'count'];


    constructor(public myElement: ElementRef, private changeDetectorRefs: ChangeDetectorRef) {
        super('invNo', 'asc');

        this.columns = AppData.ColumnDefinitions[this.listTemplate];
        this.elementRef = myElement;

        // setTimeout(() => {
        //   this.dataSourceMT = new MatTableDataSource(this.tableItems);

        //   this.paginator.length = this.tableItems.length;
        //   this.dataSourceMT.paginator = this.paginator;
        //   this.dataSourceMT.sort = this.sort;
        // }, 3000);

        this.displayedColumns.length = 23;
        this.displayedColumns.fill('filler');

        // The first two columns should be position and name; the last two columns: weight, symbol
        this.displayedColumns[0] = 'select';
        this.displayedColumns[1] = 'totalLeft';
        this.displayedColumns[2] = 'budgetBaseCode';
        this.displayedColumns[3] = 'version';
        this.displayedColumns[4] = 'budgetManager';
        this.displayedColumns[5] = 'accMonth';
        this.displayedColumns[6] = 'appState';
        this.displayedColumns[7] = 'startMonth';
        this.displayedColumns[8] = 'depPeriod';
        this.displayedColumns[9] = 'depPeriodRem';
        this.displayedColumns[10] = 'april';
        this.displayedColumns[11] = 'may';
        this.displayedColumns[12] = 'june';
        this.displayedColumns[13] = 'july';
        this.displayedColumns[14] = 'august';
        this.displayedColumns[15] = 'september';
        this.displayedColumns[16] = 'octomber';
        this.displayedColumns[17] = 'november';
        this.displayedColumns[18] = 'december';
        this.displayedColumns[19] = 'january';
        this.displayedColumns[20] = 'february';
        this.displayedColumns[21] = 'march';
        this.displayedColumns[22] = 'totalRight';
    }
  ngAfterViewInit(): void {
    this.dataSourceMT = new MatTableDataSource(this.tableItems);

    this.paginator.length = this.totalItems;
    this.dataSourceMT.paginator = this.paginator;
    this.dataSourceMT.sort = this.sort;

    this.paginator.page.subscribe(
      (event) =>
      setTimeout(() => {
        // alert('DDD1');
        this.dataSourceMT = new MatTableDataSource(this.tableItems);
        this.paginator.length = this.totalItems;
        // this.dataSourceMT.paginator = this.paginator;
        // this.dataSourceMT.sort = this.sort;
      }, 500)
      );
  }

      public refresh(filters: Array<Param>) {
        this.columns = AppData.ColumnDefinitions[this.listTemplate];
        super.refresh(filters);

        // this.dataSourceMT = new MatTableDataSource(this.tableItems);

        setTimeout(() => {
          alert('DDD');
          this.dataSourceMT = new MatTableDataSource(this.tableItems);

          this.paginator.length = this.totalItems;
          // this.dataSourceMT.paginator = this.paginator;
          // this.dataSourceMT.sort = this.sort;
        }, 1000);

    }

        public setCurrentPageData(pageData: any) {
        this.totals = pageData.totals;
        super.setCurrentPageData(pageData);
    }

    public filter() {
        if (this.query !== '') {
            this.filteredList = this.tableItems.filter(function(el){
                return el.item.invNo.toString().toLowerCase().indexOf(this.query.toLowerCase()) > -1;
            }.bind(this));
        } else {
            this.filteredList = [];
        }
    }

    public select(item) {
        this.query = item;
        this.filteredList = [];
        if (item != null) {
            this.tableItems = this.tableItems.filter(function(el){
                return el.item.invNo.toString().toLowerCase().indexOf(item.toLowerCase()) > -1;
            });
        }
    }

    public filterCust(ev) {
        console.log(JSON.stringify(ev));
      }

      edit(item: any) {
        this.item = {...item};
        // this.productDialog = true;
    }

    progressBarClass(value): string {
        if (value < 70) {
            return 'redBar';
        }

        if (value < 90) {
            return 'yellowBar';
        }

        return 'greenBar';
    }

    isSticky(buttonToggleGroup: MatButtonToggleGroup, id: string) {
      return (buttonToggleGroup.value || []).indexOf(id) !== -1;
    }

    isAllSelected() {
      const numSelected = this.selection?.selected?.length;
      const numRows = this.dataSourceMT?.data?.length;
      return numSelected === numRows;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle() {
      if (this.isAllSelected()) {
        this.selection.clear();
        return;
      }
  
      this.selection?.select(...this.dataSourceMT?.data);
    }
  
    /** The label for the checkbox on the passed row */
    checkboxLabel(row?: any): string {
      if (!row) {
        return `${this.isAllChecked() ? 'deselect' : 'select'} all`;
      }
      return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
    }

    public changePage(pageEvent: PageEvent) {
      console.log(JSON.stringify(pageEvent));
      if (pageEvent.pageIndex === 0 || pageEvent.pageIndex === 1) {
        this.currentPage = pageEvent.pageIndex + 1;
      } else {
        this.currentPage = pageEvent.pageIndex;
      }
      this.pageSize = pageEvent.pageSize;
      this.refreshItems();
  }
}



interface Node<T> {
  value: T;
  children: Node<T>[];
}
