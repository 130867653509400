import { Component } from '@angular/core';
import { AppData } from '../../../app-data';
import { Param } from '../../../model/common/param';
import { GenericTableList } from '../../generic/generic.table.list';
import { EmployeeManager } from '../../../model/api/administration/employee-manager';
import { GenericPrimeNgDataTableList } from '../../generic/generic.prime-ng-data-table.list';
import { ExportService } from '../../../services/export.service';

@Component({
    selector: 'employee-manager-list',
    //templateUrl: '../../generic/generic.table.list.html',
    templateUrl: '../../generic/generic.prime-ng-data-table.list.html',
    inputs: [ 'listTemplate' ],
})
//export class EmployeeManagerList extends GenericTableList<EmployeeManager, number> {
export class EmployeeManagerList extends GenericPrimeNgDataTableList<any, number> {

    private listTemplate: string = 'EMPLOYEEMANAGERS';

    constructor(public exportService: ExportService) {
        super('id', 'asc', '', exportService);
        this.columns = AppData.ColumnDefinitions[this.listTemplate];
    }

    public refresh(filters: Array<Param>) {
      this.columns = AppData.ColumnDefinitions[this.listTemplate];
        super.refresh(filters);
    }

    public resetSort() {
        this.dt1.sortOrder = 0;
        this.dt1.sortField = undefined;
        this.dt1.reset();
    }   

    public resetRows(rows) {
        this.dt1.rows = rows;
    } 
}
