import { CodeNameEntity } from "../common/code-name-entity";
import { EmployeeResource } from "./employee-resource";

export class Matrix {
    id: number;
    appState: CodeNameEntity;
    area: CodeNameEntity;
    assetType: CodeNameEntity;
    company: CodeNameEntity;
    costCenter: CodeNameEntity;
    country: CodeNameEntity;
    project: CodeNameEntity;
    employeeL1: EmployeeResource;
    employeeL2: EmployeeResource;
    employeeL3: EmployeeResource;
    employeeL4: EmployeeResource;
    employeeS1: EmployeeResource;
    employeeS2: EmployeeResource;
    employeeS3: EmployeeResource;
    state: any; notSync: any; isLocked: any;

    constructor(id: number, appState: CodeNameEntity,
        area: CodeNameEntity,
        assetType: CodeNameEntity,
        company: CodeNameEntity,
        costCenter: CodeNameEntity,
        country: CodeNameEntity,
        project: CodeNameEntity,
        employeeL1: EmployeeResource,
        employeeL2: EmployeeResource,
        employeeL3: EmployeeResource,
        employeeL4: EmployeeResource,
        employeeS1: EmployeeResource,
        employeeS2: EmployeeResource,
        employeeS3: EmployeeResource) {
        this.id = id;
        this.appState = appState;
        this.area = area;
        this.assetType = assetType;
        this.company = company;
        this.costCenter = costCenter;
        this.country = country;
        this.project = project;
        this.employeeL1 = employeeL1;
        this.employeeL2 = employeeL2;
        this.employeeL3 = employeeL3;
        this.employeeL4 = employeeL4;
        this.employeeS1 = employeeS1;
        this.employeeS2 = employeeS2;
        this.employeeS3 = employeeS3;
    }
}
