import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GenericHttpService } from '../generic.http.service';
import { Inventory } from "../../../model/api/inventory/inventory";
import { AppConfig } from "../../../config";
import { Param } from "../../../model/common/param";
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
// import { AuthHttp } from 'angular2-jwt';

@Injectable()
export class InventoryHttpService extends GenericHttpService<Inventory, number> {
    constructor(public http: HttpClient) {
        super(http, "", "inventories");
    }

    public audit(params: Array<Param>) {
        let searchParams: HttpParams = null;
        let url = AppConfig.urlPrefix + this.url + '/audit';
    
        searchParams = this.getSearchParams(params);
        return this.http.get(url, { params: searchParams, observe: 'response', responseType: 'blob' }).pipe(
                        map(res => res));
    }

    // public disable(): Observable<void> {
    //     return this.http.put(AppConfig.urlPrefix + this.url + '/disable'
    //         , { headers: this.headers })
    //         .map(() => {});
    // }

    public disable(): Observable<{}> {
        return this.http.put(AppConfig.urlPrefix + this.url + '/disable' , { headers: this.headers}).pipe(
         map((data) => {
            return data;
        }));
    }
}