import { PagedResult } from './../../../model/common/paged-result';
import { Param } from './../../../model/common/param';
import { Component, EventEmitter, ViewChild } from '@angular/core';
import { GenericManage } from '../../generic/generic.manage';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { CapAmortizationHttpService } from '../../../services/http/sap/asset-dep-md-cap-sync';
import { AssetDepMDCapSync } from '../../../model/api/sap/asset-dep-md-cap-sync';
import { CapAmortizationListComponent } from './cap-amortization.list';

@Component({
    selector: 'app-cap-amortization-manage',
    templateUrl: 'cap-amortization.manage.html',
    providers: [ CapAmortizationHttpService ]
})
export class CapAmortizationManageComponent extends GenericManage<AssetDepMDCapSync, number> {

    @ViewChild('itemDetailModal') modal: ModalDirective;
    @ViewChild('amortizationList') amortizationList: CapAmortizationListComponent;

    isCollapsed: boolean = true;
    public filter: string = '';
    hideBtnSmdcSync = false;
    hideBtnSyncToOptima = false;

    constructor(public amortizationHttpService: CapAmortizationHttpService) {
        super();
    }

    public detailInitialize() {
        this.modal.show();
    }

    public detailTerminate() {
        this.modal.hide();
    }

    public syncToOptima() {
        this.hideBtnSyncToOptima = true;
        this.amortizationHttpService.updateOptima().subscribe((count) => {
            alert(JSON.stringify(count) + ' au fost importate in optima!' );
            this.hideBtnSyncToOptima = false;
            this.amortizationList.refresh(null);
       });

    }

    public exportToExcel(){

        let params: Array<Param> = null;

        if ((this.filter != null) && (this.filter.length > 0)) {
            params = new Array<Param>();
            params.push(new Param('filter', this.filter));
        }

        this.amortizationHttpService.get(1, 10000000, 'code', 'asc', params, null).subscribe(
            (data: PagedResult<AssetDepMDCapSync>) => {

                let options = {
                    sheetid: 'Tipuri',
                    headers: true,
                    column: { style: { Font: { Bold: '1' } } },
                    rows: { 1: { style: { Font: { Color: '#FF0077' } } } },
                    cells: { 1: { 1: { style: { Font: { Color: '#00FFFF' } } } } }
                };

                // alasql.promise(`SELECT id as [Id], 
                //                     code as [Cod], 
                //                     name as [Denumire],
                //                     INTO XLSX("Tipuri-Observatii.xlsx", ?) FROM ?`, [ options, data.items ]);

            });
    }


    collapsed(event: any): void {
        // console.log(event);
      }

      expanded(event: any): void {
        // console.log(event);
      }
}
