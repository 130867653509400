import { PagedResult } from './../../../model/common/paged-result';
import { Param } from './../../../model/common/param';
import { Component, ViewChild } from '@angular/core';
import { GenericManage } from '../../generic/generic.manage';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Badge } from '../../../model/api/common/badge';
import { BadgeHttpService } from '../../../services/http/common/badge.http.service';
import { BadgeList } from './badge.list';
import { BadgeDetail } from './badge.detail';
import { Res } from '../../../resources/resources';

@Component({
    selector: 'badge-manage',
    templateUrl: 'badge.manage.html',
    styleUrls: ['badge.manage.scss'],
    providers: [ ]
})
export class BadgeManage extends GenericManage<Badge, number> {

    @ViewChild('badgeDetailModal') badgeDetailModal: ModalDirective;
    @ViewChild('badgeList') badgeList: BadgeList;
    @ViewChild('badgeDetail') badgeDetail: BadgeDetail;

    public exportBtnInfo = Res.ExportBtnInfo;
    public filter: string = '';
    isCollapsed: boolean = true;

    constructor(public badgeHttpService: BadgeHttpService) {
        super();
    }

    public addNewItem() {
        super.addNewItem();
    }

    public editItem() {
        super.editItem();
    }

    public detailInitialize() {
        super.detailInitialize();
        this.badgeDetailModal.show();
    }

    public detailTerminate() {
        super.detailTerminate();
        this.badgeDetailModal.hide();
    }

    public refresh() {
        let params: Array<Param> = new Array<Param>();

        params.push(new Param('filter', this.filter));

        this.badgeList.refresh(params);
    }

    public exportToExcel(){

         let params: Array<Param> = null;

        if ((this.filter != null) && (this.filter.length > 0)) {
            params = new Array<Param>();
            params.push(new Param('filter', this.filter));
        }

        this.badgeHttpService.get(1, 10000000, 'code', 'asc', params, null).subscribe(
            (data: PagedResult<Badge>) => {

                let options = {
                    sheetid: 'badge',
                    headers: true,
                    column: { style: { Font: { Bold: '1' } } },
                    rows: { 1: { style: { Font: { Color: '#FF0077' } } } },
                    cells: { 1: { 1: { style: { Font: { Color: '#00FFFF' } } } } }
                };

                // alasql(`SELECT id as [Id],
                //     code as [Code],
                //     name as [Type]
                //     INTO XLSX("email-type.xlsx",?) FROM ?`, [ options, data.items ]);

            });

    }

    collapsed(event: any): void {
        // console.log(event);
      }
    
      expanded(event: any): void {
        // console.log(event);
      }
}
