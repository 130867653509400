
export class AssetFilter {
    includes: string;
    accMonthId: number;
    accSystemId: number;
    assetTypeIds: Array<number>;
    divisionIds: Array<number>;
    administrationIds: Array<number>;
    assetStateIds: Array<number>;
    invStateIds: Array<number>;
    assetCategoryIds: Array<number>;
    assetNatureIds: Array<number>;
    uomIds: Array<number>;
    assetClassIds: Array<number>;
    regionIds: Array<number>;
    locationIds: Array<number>;
    roomIds: Array<number>;
    dictionaryItemIds: Array<number>;
    expAccountIds: Array<number>;
    departmentIds: Array<number>;
    employeeIds: Array<number>;
    transferEmployeeIds: Array<number>;
    admCenterIds: Array<number>;
    companyIds: Array<number>;
    interCompanyIds: Array<number>;
    insuranceCategoryIds: Array<number>;
    dimensionIds: Array<number>;
    projectIds: Array<number>;
    brandIds: Array<number>;
    costCenterIds: Array<number>;
    documentTypeIds: Array<number>;
    partnerIds: Array<number>;
    filter: string;
    filterManager: string;
    filterAssetReplaced: string;
    filterDoc: string;
    filterName: string;
    filterInv: string;
    filterPurchaseDate: string;
    fromDate: Date;
    toDate: Date;
    fromReceptionDate: Date;
    toReceptionDate: Date;
    custody: string;
    showReco: boolean;
    erpCode: boolean;
    isPrinted: boolean;
    isDuplicate: boolean;
    hasCompanyDiff: boolean;
    transferNumber: string;
    showDeleted: boolean;
}
