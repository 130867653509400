import { PagedResult } from './../../../model/common/paged-result';
import { Param } from './../../../model/common/param';
import { Component, EventEmitter, ViewChild } from '@angular/core';
import { GenericManage, GenericManageViewMode } from '../../generic/generic.manage';
import { CostCenter } from '../../../model/api/administration/cost-center';
import { CostCenterHttpService } from '../../../services/http/administration/cost-center.http.service';
// import { saveAs as fileSaveAs } from 'file-saver-es';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AdmCenterHttpService } from '../../../services/http/administration/adm-center.http.service';
import { CostCenterDetailComponent } from './cost-center.detail';
import { AdmCenterListComponent } from '../adm-centers/adm-center.list';
import { CostCenterListComponent } from './cost-center.list';
import { AdmCenter } from '../../../model/api/administration/adm-center';
import { AppConfig } from '../../../config';
import { AppUtils } from '../../../common/app.utils';
import { RegionHttpService } from '../../../services/http/administration/region.http.service';
import { RegionListComponent } from '../regions/region.list';
import { Region } from '../../../model/api/administration/region';
// import { EmployeeListComponent } from '../employees/employee.list';
// import { Employee } from '../../../model/api/administration/employee';
// import { EmployeeHttpService } from '../../../services/http/administration/employee.http.service';
import { DivisionHttpService } from '../../../services/http/administration/division.http.service';
import { DivisionListComponent } from '../divisions/division.list';
import { Division } from '../../../model/api/administration/division';
import { AdministrationHttpService } from '../../../services/http/administration/administration.http.service';
// import { LocationHttpService } from '../../../services/http/administration/location.http.service';
import { AdministrationListComponent } from '../administrations/administration.list';
// import { LocationListComponent } from '../locations/location.list';
import { Administration } from '../../../model/api/administration/administration';
import { RoomHttpService } from '../../../services/http/administration/room.http.service';
import { RoomListComponent } from '../rooms/room.list';
import { Room } from '../../../model/api/administration/room';
// import { Location } from '../../../model/api/administration/location';

@Component({
    selector: 'app-cost-center-manage',
    templateUrl: 'cost-center.manage.html',
    styleUrls: ['cost-center.manage.scss'],
    providers: [ AdmCenterHttpService, RegionHttpService, DivisionHttpService, AdministrationHttpService, RoomHttpService ]
})
export class CostCenterManageComponent extends GenericManage<CostCenter, number> {

    @ViewChild('costCenterDetail') public costCenterDetail: CostCenterDetailComponent;
    @ViewChild('costCenterList') public costCenterList: CostCenterListComponent;
    @ViewChild('costCenterDetailModal') costCenterDetailModal: ModalDirective;
    @ViewChild('admCenterListModal') admCenterListModal: ModalDirective;
    @ViewChild('admCenterList') public admCenterList: AdmCenterListComponent;
    @ViewChild('regionListModal') regionListModal: ModalDirective;
    @ViewChild('regionList') public regionList: RegionListComponent;
    @ViewChild('divisionListModal') divisionListModal: ModalDirective;
    @ViewChild('divisionList') public divisionList: DivisionListComponent;
    // @ViewChild('employeeListModal') employeeListModal: ModalDirective;
    // @ViewChild('employeeList') employeeList: EmployeeListComponent;
    // @ViewChild('employee2ListModal') employee2ListModal: ModalDirective;
    // @ViewChild('employee2List') employee2List: EmployeeListComponent;
    // @ViewChild('employee3ListModal') employee3ListModal: ModalDirective;
    // @ViewChild('employee3List') employee3List: EmployeeListComponent;
    @ViewChild('administrationListModal') administrationListModal: ModalDirective;
    @ViewChild('administrationList') administrationList: AdministrationListComponent;
    @ViewChild('roomListModal') roomListModal: ModalDirective;
    @ViewChild('roomList') roomList: RoomListComponent;

    public filter: string = '';
    isCollapsed: boolean = true;
    public selectedAdmCenter: AdmCenter = null;
    public selectedRegion: Region = null;
    public selectedDivision: Division = null;

    // public selectedEmployee: Employee = null;
    // public selectedEmployee2: Employee = null;
    // public selectedEmployee3: Employee = null;
    public selectedAdministration: Administration = null;
    public selectedRoom: Room = null;

    public static get USE_REGION(): boolean { return false; }

    constructor(public costCenterHttpService: CostCenterHttpService,
                // public employeeHttpService: EmployeeHttpService,
                public admCenterHttpService: AdmCenterHttpService, public regionHttpService: RegionHttpService,
                public administrationHttpService: AdministrationHttpService, public roomHttpService: RoomHttpService,
                public divisionHttpService: DivisionHttpService) {
        super();
    }

    public addNewItem() {
        super.addNewItem();

        this.costCenterDetail.admCenter = null;
        this.costCenterDetail.region = null;
        this.costCenterDetail.division = null;

        // this.costCenterDetail.employee = null;
        // this.costCenterDetail.employee2 = null;
        // this.costCenterDetail.employee3 = null;
        this.costCenterDetail.administration = null;
        this.costCenterDetail.room = null;
    }

    public editItem() {
        super.editItem();

        const costCenter: CostCenter = this.selectedItem as CostCenter;

        this.costCenterDetail.admCenter = null;

        if (costCenter != null && costCenter.admCenterId > 0 && costCenter.admCenterId != null) {
            this.admCenterHttpService
                .getById(costCenter.admCenterId)
                .subscribe((admCenter: AdmCenter) => {
                    this.costCenterDetail.admCenter = admCenter;
                });
        }

        this.costCenterDetail.region = null;

        if (costCenter != null && costCenter.regionId > 0 && costCenter.regionId != null) {
            this.regionHttpService
                .getById(costCenter.regionId)
                .subscribe((region: Region) => {
                    this.costCenterDetail.region = region;
                });
        }

        this.costCenterDetail.division = null;

        if (costCenter != null && costCenter.divisionId > 0 && costCenter.divisionId != null) {
            this.divisionHttpService
                .getById(costCenter.divisionId)
                .subscribe((division: Division) => {
                    this.costCenterDetail.division = division;
                });
        }

        // this.costCenterDetail.employee = null;


        // if ((costCenter != null) && (costCenter.employeeId != null)) {
        //     this.employeeHttpService
        //         .getById(costCenter.employeeId)
        //         .subscribe((employee: Employee) => {
        //             this.costCenterDetail.employee = employee;
        //         });
        // }

        // this.costCenterDetail.employee2 = null;

        // if ((costCenter != null) && (costCenter.employeeId2 != null)) {
        //     this.employeeHttpService
        //         .getById(costCenter.employeeId2)
        //         .subscribe((employee: Employee) => {
        //             this.costCenterDetail.employee2 = employee;
        //         });
        // }

        // this.costCenterDetail.employee3 = null;

        // if ((costCenter != null) && (costCenter.employeeId3 != null)) {
        //     this.employeeHttpService
        //         .getById(costCenter.employeeId3)
        //         .subscribe((employee: Employee) => {
        //             this.costCenterDetail.employee3 = employee;
        //         });
        // }

        this.costCenterDetail.administration = null;

        if ((costCenter != null) && (costCenter.administrationId != null)) {
            this.administrationHttpService
                .getById(costCenter.administrationId)
                .subscribe((administration: Administration) => {
                    this.costCenterDetail.administration = administration;
                });
        }

        this.costCenterDetail.room = null;

        if ((costCenter != null) && (costCenter.roomId != null)) {
            this.roomHttpService
                .getById(costCenter.roomId)
                .subscribe((room: Room) => {
                    this.costCenterDetail.room = room;
                });
        }
    }

    public detailInitialize() {
        super.detailInitialize();
        this.costCenterDetailModal.show();
    }

    public detailTerminate() {
        super.detailTerminate();
        this.costCenterDetailModal.hide();
    }

    public selectAdmCenter() {
        this.admCenterListModal.show();
        this.admCenterList.refresh(null);
    }

    public selectRegion() {
        this.regionListModal.show();
        this.regionList.refresh(null);
    }

    public selectDivision() {
        this.divisionListModal.show();
        this.divisionList.refresh(null);
    }

    //   public onLocationDetailEmployeeNeeded() {
    //     this.costCenterDetailModal.hide();
    //     this.selectEmployee();
    // }

    // public onEmployeeListCancel() {
    //     this.employeeListModal.hide();
    //     if (this.viewMode === GenericManageViewMode.ItemDetail) {
    //         this.costCenterDetailModal.show();
    //     }
    // }

    // public onLocationDetailEmployee2Needed() {
    //     this.costCenterDetailModal.hide();
    //     this.selectEmployee2();
    // }

    // public onEmployee2ListCancel() {
    //     this.employee2ListModal.hide();
    //     if (this.viewMode === GenericManageViewMode.ItemDetail) {
    //         this.costCenterDetailModal.show();
    //     }
    // }

    // public onLocationDetailEmployee3Needed() {
    //     this.costCenterDetailModal.hide();
    //     this.selectEmployee3();
    // }

    // public onEmployee3ListCancel() {
    //     this.employee3ListModal.hide();
    //     if (this.viewMode === GenericManageViewMode.ItemDetail) {
    //         this.costCenterDetailModal.show();
    //     }
    // }

    public onCostCenterDetailAdministrationNeeded() {
        this.costCenterDetailModal.hide();
        this.selectAdministration();
    }

    public onAdministrationListCancel() {
        this.administrationListModal.hide();
        if (this.viewMode === GenericManageViewMode.ItemDetail) {
            this.costCenterDetailModal.show();
        }
    }

    public onCostCenterDetailRoomNeeded() {
        this.costCenterDetailModal.hide();
        this.selectRoom();
    }

    public onRoomListCancel() {
        this.roomListModal.hide();
        if (this.viewMode === GenericManageViewMode.ItemDetail) {
            this.costCenterDetailModal.show();
        }
    }

    public getFilters(): Array<Param> {
        const params: Array<Param> = new Array<Param>();
        params.push(new Param('filter', this.filter));
        params.push(new Param('admCenterIds', AppUtils.getIdsList<AdmCenter, number>([this.selectedAdmCenter])));
        params.push(new Param('regionIds', AppUtils.getIdsList<Region, number>([this.selectedRegion])));
        params.push(new Param('divisionIds', AppUtils.getIdsList<Division, number>([this.selectedDivision])));
        // params.push(new Param('employeeIds', AppUtils.getIdsList<Employee, number>([ this.selectedEmployee ])));
        // params.push(new Param('employee2Ids', AppUtils.getIdsList<Employee, number>([ this.selectedEmployee2 ])));
        // params.push(new Param('employee3Ids', AppUtils.getIdsList<Employee, number>([ this.selectedEmployee3 ])));
        params.push(new Param('administrationIds', AppUtils.getIdsList<Administration, number>([ this.selectedAdministration ])));
        params.push(new Param('roomIds', AppUtils.getIdsList<Room, number>([ this.selectedRoom ])));


        return params;
    }

    public refresh() {
        let params: Array<Param> = null;

        params = this.getFilters();
        this.costCenterList.refresh(params);
    }

    public onCostCenterDetailAdmCenterNeeded() {
        this.costCenterDetailModal.hide();
        this.selectAdmCenter();
    }

    public onAdmCenterListCancel() {
        this.admCenterListModal.hide();
        if (this.viewMode === GenericManageViewMode.ItemDetail) {
            this.costCenterDetailModal.show();
        }
    }

    public setSelectedAdmCenters() {
        switch (this.viewMode) {
            case GenericManageViewMode.ItemList:
                this.selectedAdmCenter = this.admCenterList.selectedItem;
                this.admCenterListModal.hide();
                this.refresh();
                break;
            case GenericManageViewMode.ItemDetail:
                this.costCenterDetail.admCenter = this.admCenterList.selectedItem;
                this.admCenterListModal.hide();
                this.costCenterDetailModal.show();
                break;
        }
    }

    public unselectAdmCenter() {
        this.selectedAdmCenter = null;
        this.refresh();
    }

    public onCostCenterDetailRegionNeeded() {
        this.costCenterDetailModal.hide();
        this.selectRegion();
    }

    public onRegionListCancel() {
        this.regionListModal.hide();
        if (this.viewMode === GenericManageViewMode.ItemDetail) {
            this.costCenterDetailModal.show();
        }
    }

    public setSelectedRegions() {
        switch (this.viewMode) {
            case GenericManageViewMode.ItemList:
                this.selectedRegion = this.regionList.selectedItem;
                this.regionListModal.hide();
                this.refresh();
                break;
            case GenericManageViewMode.ItemDetail:
                this.costCenterDetail.region = this.regionList.selectedItem;
                this.regionListModal.hide();
                this.costCenterDetailModal.show();
                break;
        }
    }

    public unselectRegion() {
        this.selectedRegion = null;
        this.refresh();
    }

    public onCostCenterDetailDivisionNeeded() {
        this.costCenterDetailModal.hide();
        this.selectDivision();
    }

    public onDivisionListCancel() {
        this.divisionListModal.hide();
        if (this.viewMode === GenericManageViewMode.ItemDetail) {
            this.costCenterDetailModal.show();
        }
    }

    public setSelectedDivisions() {
        switch (this.viewMode) {
            case GenericManageViewMode.ItemList:
                this.selectedDivision = this.divisionList.selectedItem;
                this.divisionListModal.hide();
                this.refresh();
                break;
            case GenericManageViewMode.ItemDetail:
                this.costCenterDetail.division = this.divisionList.selectedItem;
                this.divisionListModal.hide();
                this.costCenterDetailModal.show();
                break;
        }
    }

    public unselectDivision() {
        this.selectedDivision = null;
        this.refresh();
    }


    // public selectEmployee() {
    //     this.employeeListModal.show();
    //     this.employeeList.refresh(null);
    // }

    // public setSelectedEmployee() {
    //     switch (this.viewMode) {
    //         case GenericManageViewMode.ItemList:
    //             this.selectedEmployee = this.employeeList.selectedItem;
    //             this.employeeListModal.hide();
    //             this.refresh();
    //             break;
    //         case GenericManageViewMode.ItemDetail:
    //             this.costCenterDetail.employee = this.employeeList.selectedItem;
    //             this.employeeListModal.hide();
    //             this.costCenterDetailModal.show();
    //             break;
    //         default:
    //             break;
    //     }
    // }

    // public unselectEmployee() {
    //     this.selectedEmployee = null;
    //     this.refresh();
    // }

    // public selectEmployee2() {
    //     this.employee2ListModal.show();
    //     this.employee2List.refresh(null);
    // }

    // public setSelectedEmployee2() {
    //     switch (this.viewMode) {
    //         case GenericManageViewMode.ItemList:
    //             this.selectedEmployee2 = this.employee2List.selectedItem;
    //             this.employee2ListModal.hide();
    //             this.refresh();
    //             break;
    //         case GenericManageViewMode.ItemDetail:
    //             this.costCenterDetail.employee2 = this.employee2List.selectedItem;
    //             this.employee2ListModal.hide();
    //             this.costCenterDetailModal.show();
    //             break;
    //         default:
    //             break;
    //     }
    // }

    // public unselectEmployee2() {
    //     this.selectedEmployee2 = null;
    //     this.refresh();
    // }

    // public selectEmployee3() {
    //     this.employee3ListModal.show();
    //     this.employee3List.refresh(null);
    // }

    // public setSelectedEmployee3() {
    //     switch (this.viewMode) {
    //         case GenericManageViewMode.ItemList:
    //             this.selectedEmployee3 = this.employee3List.selectedItem;
    //             this.employee3ListModal.hide();
    //             this.refresh();
    //             break;
    //         case GenericManageViewMode.ItemDetail:
    //             this.costCenterDetail.employee3 = this.employee3List.selectedItem;
    //             this.employee3ListModal.hide();
    //             this.costCenterDetailModal.show();
    //             break;
    //         default:
    //             break;
    //     }
    // }

    // public unselectEmployee3() {
    //     this.selectedEmployee3 = null;
    //     this.refresh();
    // }

    public selectAdministration() {
        this.administrationListModal.show();
        this.administrationList.refresh(null);
    }

    public setSelectedAdministration() {
        switch (this.viewMode) {
            case GenericManageViewMode.ItemList:
                this.selectedAdministration = this.administrationList.selectedItem;
                this.administrationListModal.hide();
                this.refresh();
                break;
            case GenericManageViewMode.ItemDetail:
                this.costCenterDetail.administration = this.administrationList.selectedItem;
                this.administrationListModal.hide();
                this.costCenterDetailModal.show();
                break;
            default:
                break;
        }
    }

    public unselectAdministration() {
        this.selectedAdministration = null;
        this.refresh();
    }

    public selectRoom() {
        this.roomListModal.show();
        this.roomList.refresh(null);
    }

    public setSelectedRoom() {
        switch (this.viewMode) {
            case GenericManageViewMode.ItemList:
                this.selectedRoom = this.roomList.selectedItem;
                this.roomListModal.hide();
                this.refresh();
                break;
            case GenericManageViewMode.ItemDetail:
                this.costCenterDetail.room = this.roomList.selectedItem;
                this.roomListModal.hide();
                this.costCenterDetailModal.show();
                break;
            default:
                break;
        }
    }

    public unselectRoom() {
        this.selectedRoom = null;
        this.refresh();
    }

    public exportToExcel() {

        // let params: Array<Param> = null;

        // params = this.getFilters();
        // this.costCenterHttpService
        //     .export(params)
        //     .subscribe((blob) => {
        //         fileSaveAs(blob, 'cost_centers.xlsx');
        //     });
    }

    collapsed(event: any): void {
        // console.log(event);
      }
      expanded(event: any): void {
        // console.log(event);
      }
}
