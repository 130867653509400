import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { InvState } from '../../../model/api/inventory/inv-state';
import { GenericHttpService } from '../generic.http.service';
// import { AuthHttp } from 'angular2-jwt';

@Injectable()
export class InvStateHttpService extends GenericHttpService<InvState, number> {
    constructor(public http: HttpClient) {
        super(http, '', 'invstates');
    }
}