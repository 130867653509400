import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { GenericHttpService } from "../generic.http.service";
import { Account } from '../../../model/api/administration/account';

@Injectable()
export class AccountHttpService extends GenericHttpService<Account, number> {
    constructor(public http: HttpClient) {
        super(http, "", "accounts");
    }
}