export class CodePartnerEntity {
    id: number;
    name: string;
    registryNumber: string;
    contactInfo: string;
    bank: string;
    bankAccount: string;

    constructor (id: number, name: string, contactInfo: string,
        bank: string,
        bankAccount: string) {
        this.id = id;
        this.name = name;
        this.registryNumber = this.registryNumber;
        this.contactInfo = contactInfo;
        this.bank = bank;
        this.bankAccount = bankAccount;
    }
}