import { EmployeeResource } from './employee-resource';
export class Area {
    id: number;
    code: string;
    name: string;
    dateEvent: Date;
    employee: EmployeeResource;
    state: any; notSync: any; isLocked: any;

    constructor(id: number, code: string, name: string, dateEvent: Date, employee: EmployeeResource) {
        this.id = id;
        this.code = code;
        this.name = name;
        this.dateEvent = dateEvent;
        this.employee = employee;
    }
}
