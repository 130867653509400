import { Param } from './../../../model/common/param';
import { Component, EventEmitter, ViewChild } from '@angular/core';
import { GenericManage, GenericManageViewMode } from '../../generic/generic.manage';
import { CountryHttpService } from '../../../services/http/administration/contry.http.service';
import { CountyHttpService } from '../../../services/http/administration/county.http.service';
import { County } from '../../../model/api/administration/county';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { CountyListComponent } from './county.list';
import { CountyDetailComponent } from './county.detail';
import { Country } from '../../../model/api/administration/country';
import { AppUtils } from '../../../common/app.utils';
import { CountryListComponent } from '../countries/country.list';
// import { saveAs as fileSaveAs } from 'file-saver-es';

@Component({
    selector: 'app-county-manage',
    templateUrl: 'county.manage.html',
    styleUrls: ['county.manage.scss'],
    providers: [ CountyHttpService, CountryHttpService ]
})
export class CountyManageComponent extends GenericManage<County, number> {

    @ViewChild('countyDetailModal') countyDetailModal: ModalDirective;
    @ViewChild('countyList') countyList: CountyListComponent;
    @ViewChild('countyDetail') countyDetail: CountyDetailComponent;
    @ViewChild('countryListModal') countryListModal: ModalDirective;
    @ViewChild('countryList') countryList: CountryListComponent;

    public filter: string = '';
    public selectedCountry: Country = null;
    isCollapsed: boolean = true;

    constructor(
        public countyHttpService: CountyHttpService,
        public countryHttpService: CountryHttpService) {
        super();
    }

    public addNewItem() {
        super.addNewItem();

        this.countyDetail.country = null;
    }

    public editItem() {
        super.editItem();

        const county: County = this.selectedItem as County;

        this.countyDetail.country = null;
        if ((county != null) && (county.countryId != null)) {
            this.countryHttpService
                .getById(county.countryId)
                .subscribe((country: Country) => {
                    this.countyDetail.country = country;
                });
        }
    }

    public detailInitialize() {
        super.detailInitialize();
        this.countyDetailModal.show();
    }

    public detailTerminate() {
        super.detailTerminate();
        this.countyDetailModal.hide();
    }

    public onCountyDetailCountryNeeded() {
        this.countyDetailModal.hide();
        this.selectCountry();
    }

    public onCountryListCancel() {
        this.countryListModal.hide();
        if (this.viewMode === GenericManageViewMode.ItemDetail) {
            this.countyDetailModal.show();
        }
    }


    public refresh() {
        const params: Array<Param> = new Array<Param>();

        params.push(new Param('filter', this.filter));
        params.push(new Param('countryIds', AppUtils.getIdsList<Country, number>([ this.selectedCountry ])));
        this.countyList.refresh(params);
    }

    public selectCountry() {
        this.countryListModal.show();
        this.countryList.refresh(null);
    }

    public setSelectedCountry() {
        switch (this.viewMode) {
            case GenericManageViewMode.ItemList:
                this.selectedCountry = this.countryList.selectedItem;
                this.countryListModal.hide();
                this.refresh();
                break;
            case GenericManageViewMode.ItemDetail:
                this.countyDetail.country = this.countryList.selectedItem;
                this.countryListModal.hide();
                this.countyDetailModal.show();
                break;
            default:
                break;
        }
    }

    public unselectCountry() {
        this.selectedCountry = null;
        this.refresh();
    }


    public getFilters(): Array<Param> {
        const params: Array<Param> = new Array<Param>();
        params.push(new Param('filter', this.filter));
        params.push(new Param('countryIds', AppUtils.getIdsList<Country, number>([this.selectedCountry])));

        return params;
    }

    public exportToExcel() {

        // let params: Array<Param> = null;

        // params = this.getFilters();
        // this.countyHttpService
        //     .export(params)
        //     .subscribe((blob) => {
        //         fileSaveAs(blob, 'Countiese.xlsx');
        //     });
    }


    collapsed(event: any): void {
        // console.log(event);
      }
    
      expanded(event: any): void {
        // console.log(event);
      }
}
