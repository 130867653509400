import { PagedResult } from './../../../model/common/paged-result';
import { Param } from './../../../model/common/param';
import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { GenericManage, GenericManageViewMode } from '../../generic/generic.manage';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AppUtils } from '../../../common/app.utils';
import { ProjectTypeDivisionHttpService } from '../../../services/http/administration/project-type-division.http.service';
import { ProjectTypeHttpService } from '../../../services/http/assets/project-type.http.service';
import { ProjectTypeDivision } from '../../../model/api/administration/project-type-division';
import { ProjectTypeDivisionListComponent } from './project-type-division.list';
import { ProjectTypeListComponent } from '../../assets/project-types/project-type.list';
import { ProjectType } from '../../../model/api/assets/project-type';
import { DivisionListComponent } from '../divisions/division.list';
import { DivisionHttpService } from '../../../services/http/administration/division.http.service';
import { Division } from '../../../model/api/administration/division';
import { ProjectTypeDivisionDetailComponent } from './project-type-division.detail';


@Component({
    selector: 'app-project-type-division-manage',
    templateUrl: 'project-type-division.manage.html',
    styleUrls: ['project-type-division.manage.scss'],
    providers: [ ProjectTypeDivisionHttpService, ProjectTypeHttpService, DivisionHttpService ]
})
export class ProjectTypeDivisionManageComponent extends GenericManage<ProjectTypeDivision, number> implements AfterViewInit {

    @ViewChild('projectTypeDivisionList') projectTypeDivisionList: ProjectTypeDivisionListComponent;
    @ViewChild('projectTypeListModal') projectTypeListModal: ModalDirective;
    @ViewChild('projectTypeList') projectTypeList: ProjectTypeListComponent;
    @ViewChild('divisionListModal') divisionListModal: ModalDirective;
    @ViewChild('divisionList') divisionList: DivisionListComponent;

    @ViewChild('projectTypeDivisionDetailModal') projectTypeDivisionDetailModal: ModalDirective;
    @ViewChild('projectTypeDivisionDetail') public projectTypeDivisionDetail: ProjectTypeDivisionDetailComponent;

    public filter: string = '';
    public selectedProjectType: ProjectType = null;
    public selectedDivision: Division = null;
    isCollapsed: boolean = true;

    constructor(public projectTypeHttpService: ProjectTypeHttpService,
      public divisionHttpService: DivisionHttpService,
      public projectTypeDivisionHttpService: ProjectTypeDivisionHttpService) {

        super();
    }

    ngAfterViewInit () {
        this.refresh();
    }

    public addNewItem() {
        super.addNewItem();

        this.projectTypeDivisionDetail.projectType = null;
        this.projectTypeDivisionDetail.division = null;
    }

    public editItem() {
        super.editItem();

        const projectTypeDivision: ProjectTypeDivision = this.selectedItem as ProjectTypeDivision;

        this.projectTypeDivisionDetail.projectType = null;
        if ((projectTypeDivision != null) && (projectTypeDivision.projectType != null) && (projectTypeDivision.projectType.id)) {
            this.projectTypeHttpService
                .getById(projectTypeDivision.projectType.id)
                .subscribe((projectType: ProjectType) => {
                    this.projectTypeDivisionDetail.projectType = projectType;
                });
        }

        this.projectTypeDivisionDetail.division = null;
        if ((projectTypeDivision != null) && (projectTypeDivision.division != null) && (projectTypeDivision.division.id)) {
            this.divisionHttpService
                .getById(projectTypeDivision.division.id)
                .subscribe((division: Division) => {
                    this.projectTypeDivisionDetail.division = division;
                });
        }
    }

    public detailInitialize() {
        super.detailInitialize();
        this.projectTypeDivisionDetailModal.show();
    }

    public detailTerminate() {
        super.detailTerminate();
        this.projectTypeDivisionDetailModal.hide();
    }

    public onProjectTypeListCancel() {
        this.projectTypeListModal.hide();
    }

    public onDivisionListCancel() {
        this.divisionListModal.hide();
    }

    public refresh() {
        const params: Array<Param> = new Array<Param>();

        params.push(new Param('filter', this.filter));
        params.push(new Param('projectTypeIds', AppUtils.getIdsList<ProjectType, number>([ this.selectedProjectType ])));
        params.push(new Param('divisionIds', AppUtils.getIdsList<Division, number>([ this.selectedDivision ])));
        this.projectTypeDivisionList.refresh(params);
    }

    public selectProjectType() {

        this.projectTypeListModal.show();
        this.projectTypeList.refresh(null);
    }

    public selectDivision() {
        this.divisionListModal.show();
        this.divisionList.refresh(null);
    }

    public setSelectedProjectType() {
        switch (this.viewMode) {
            case GenericManageViewMode.ItemList:
                this.selectedProjectType = this.projectTypeList.selectedItem;
                this.projectTypeListModal.hide();
                this.refresh();
                break;
            case GenericManageViewMode.ItemDetail:
                this.projectTypeDivisionDetail.projectType = this.projectTypeList.selectedItem;
                this.projectTypeListModal.hide();
                this.projectTypeDivisionDetailModal.show();
                break;
            default:
                break;
        }
    }

    public setSelectedDivision() {
        switch (this.viewMode) {
            case GenericManageViewMode.ItemList:
                this.selectedDivision = this.divisionList.selectedItem;
                this.divisionListModal.hide();
                this.refresh();
                break;
            case GenericManageViewMode.ItemDetail:
                this.projectTypeDivisionDetail.division = this.divisionList.selectedItem;
                this.divisionListModal.hide();
                this.projectTypeDivisionDetailModal.show();
                break;
            default:
                break;
        }
    }

    public unselectProjectType() {
        this.selectedProjectType = null;
        this.refresh();
    }

    public unselectDivision() {
        this.selectedDivision = null;
        this.refresh();
    }

    public onProjectTypeDivisionDetailProjectTypeNeeded() {
        this.projectTypeDivisionDetailModal.hide();
        this.selectProjectType();
    }

    public onProjectTypeDivisionDetailDivisionNeeded() {
        this.projectTypeDivisionDetailModal.hide();
        this.selectDivision();
    }


    public exportToExcel() {

         let params: Array<Param> = null;

        if ((this.filter != null) && (this.filter.length > 0)) {
            params = new Array<Param>();
            params.push(new Param('filter', this.filter));
        }

        this.projectTypeDivisionHttpService.get(1, 1000000, 'code', 'asc', params, null).subscribe(
            (data: PagedResult<ProjectTypeDivision>) => {

                const options = {
                    sheetid: 'Administrare',
                    headers: true,
                    column: { style: { Font: { Bold: '1' } } },
                    rows: { 1: { style: { Font: { Color: '#FF0077' } } } },
                    cells: { 1: { 1: { style: { Font: { Color: '#00FFFF' } } } } }
                };

                // let res = alasql(`SELECT id as [Id],
                //                     code as [Cod],
                //                     name as [Denumire],
                //                     divisionName as [Localitate / Judet]
                //                     INTO XLSX('Centre de cost.xlsx',?) FROM ?`,[ options, data.items ]);

            });
    }

    collapsed(event: any): void {
        // console.log(event);
      }
      expanded(event: any): void {
        // console.log(event);
      }
}
