import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import {ConfirmationService, ConfirmEventType, MessageService} from 'primeng/api';
import { TransferResult } from '../../model/api/result/transfer-result';
import { EmployeeManagerHttpService } from '../../services/http/administration/employee-manager.http.service';
import { EmployeeManagerResult } from '../../model/api/result/employee-manager-result';

@Component({
    selector: 'app-email-status-dst-employee-allocate-approve-page',
    templateUrl: 'email-status-dst-employee-allocate-approve-page.html'
})
export class EmailStatusDstEmployeeAllocateApprovePageComponent implements OnInit {
  position: string;
  reason: string = '';
  guid: string = '';
  success: boolean = false;

    constructor(
      private confirmationService: ConfirmationService,
      private messageService: MessageService,
      private employeeManagerHttpService: EmployeeManagerHttpService,
      private route: ActivatedRoute) {
      this.route.params.subscribe((params: Params) => {
        if (params['guid']) {
            this.guid = params['guid'];
        }
    });
    }
  ngOnInit(): void {
    this.confirmPosition('bottom');
  }

  confirmPosition(position: string) {
      this.position = position;

      this.confirmationService.confirm({
          message: 'Doriti sa aprobati aceasta comanda?',
          header: 'Confirma',
          icon: 'pi pi-info-circle',
          acceptLabel: 'Da',
          rejectLabel: 'Nu',
          accept: () => {
            this.employeeManagerHttpService.allocateApprove(this.guid).subscribe( (res: EmployeeManagerResult)=> {
              if(res.success){
                this.messageService.add({severity:'info', summary:'Aprobare', detail:'Dreptul a fost acceptat!'});
              } else {
                this.messageService.add({severity:'error', summary:'Aprobare', detail:res.message});
              }
            })

          },
          reject: () => {
            this.messageService.add({severity:'warn', summary:'Anulare', detail:'Aprobarea nu a fost efectuata!'});             
          },
          key: "positionDialog"
      });
  }
}
