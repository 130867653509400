import { Component, EventEmitter, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TransferAssetSAP } from '../../../model/api/sap/transfer-asset-sap';
import { GenericDetail } from '../../generic/generic.detail';

@Component({
    selector: 'app-transfer-asset-sap-detail',
    templateUrl: 'transfer-asset-sap.detail.html'
})
export class TransferAssetSAPDetailComponent extends GenericDetail<TransferAssetSAP, number> {

    @ViewChild('detailForm') detailForm: FormGroup;

    setItemDefaultValues() {
        this.item = new TransferAssetSAP();
    }

    public resetForm() {
        this.detailForm.reset();
    }
}
