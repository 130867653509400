import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DeviceDetail } from '../../../model/api/common/device-detail';
import { GenericHttpService } from '../generic.http.service';

@Injectable()
export class DeviceDetailHttpService extends GenericHttpService<DeviceDetail, number> {
    constructor(public http: HttpClient) {
        super(http, '', 'devices');
    }
}