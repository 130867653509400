import { EmployeeImport } from './../../../model/common/import/employee-import';
import { Param } from './../../../model/common/param';
import { Component, ViewChild, ElementRef, OnInit, AfterViewInit } from '@angular/core';
import { GenericManage, GenericManageViewMode } from '../../generic/generic.manage';
import { Department } from '../../../model/api/administration/department';
import { Employee } from '../../../model/api/administration/employee';
import { EmployeeDetail } from '../../../model/api/administration/employee-detail';
import { EmployeeHttpService } from '../../../services/http/administration/employee.http.service';
import { EmployeeDetailHttpService } from '../../../services/http/administration/employee-detail.http.service';
import { EmployeeDetailComponent as EmployeeDetailUI } from './employee.detail';
import { NavigationEnd, Router } from '@angular/router';
import { saveAs as fileSaveAs } from 'file-saver-es';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { EmployeeListComponent } from './employee.list';
import { DepartmentListComponent } from '../departments/department.list';
import { AppData } from '../../../app-data';
import { DepartmentHttpService } from '../../../services/http/administration/department.http.service';
import { AppUtils } from '../../../common/app.utils';
import { CostCenterListComponent } from '../cost-centers/cost-center.list';
import { CostCenter } from '../../../model/api/administration/cost-center';
import { CostCenterHttpService } from '../../../services/http/administration/cost-center.http.service';
import alasql from 'alasql';
import { EmployeeManagerAdd } from '../../../model/api/assets/employee-manager-add';
import { EmployeeManagerHttpService } from '../../../services/http/administration/employee-manager.http.service';
import { NotificationService } from '../../../services/notification.service';
import { EmployeeManagerList } from '../employee-managers/employee-manager.list';
import { RoleList } from '../../auth/role.list';
import { RoleEntity } from '../../../model/api/common/role-entity';
import { RoleService } from '../../../services/http/identity/role.service';
import { CompanyListComponent } from '../../assets/companies/company.list';
import { Company } from '../../../model/api/assets/company';
import { CompanyHttpService } from '../../../services/http/assets/company.http.service';
import { ManagerListComponent } from '../managers/manager.list';
import { Manager } from '../../../model/api/administration/manager';
import { ManagerHttpService } from '../../../services/http/administration/manager.http.service';
import { AssetImportIT } from '../../../model/common/import/asset-import-it';
import { AssetHttpService } from '../../../services/http/assets/asset.http.service';
import { AssetImportV2 } from '../../../model/common/import/asset-import-v2';
import { EmployeeImportSuperUser } from '../../../model/common/import/employee-import-superuser';
import { EmployeeImportCorrectionSuperUser } from '../../../model/common/import/employee-import-correction-superuser';
import { Res } from '../../../resources/resources';

@Component({
    selector: 'app-employee-manage',
    templateUrl: 'employee.manage.html',
})
export class EmployeeManageComponent extends GenericManage<Employee, number> implements OnInit, AfterViewInit {

    @ViewChild('employeeDetailModal') employeeDetailModal: ModalDirective;
    @ViewChild('employeeList') employeeList: EmployeeListComponent;
    @ViewChild('employeeDetail') employeeDetail: EmployeeDetailUI;
    @ViewChild('departmentListModal') departmentListModal: ModalDirective;
    @ViewChild('departmentList') departmentList: DepartmentListComponent;
    @ViewChild('companyListModal') companyListModal: ModalDirective;
    @ViewChild('companyList') companyList: CompanyListComponent;
    @ViewChild('managerListModal') managerListModal: ModalDirective;
    @ViewChild('managerList') managerList: ManagerListComponent;
    @ViewChild('employeeManagerListModal') employeeManagerListModal: ModalDirective;
    @ViewChild('employeeManagerList') employeeManagerList: EmployeeListComponent;
    @ViewChild('costCenterListModal') costCenterListModal: ModalDirective;
    @ViewChild('costCenterList') costCenterList: CostCenterListComponent;
    @ViewChild('roleListModal') roleListModal: ModalDirective;
    @ViewChild('roleList') roleList: RoleList;
    @ViewChild('importDataModal') public importDataModal: ModalDirective;
    @ViewChild('fileInput') fileInput: ElementRef;
    @ViewChild('confirmationModal') public confirmationModal: ModalDirective;

    @ViewChild('uploadModal') public uploadModal: ModalDirective;

    //@ViewChild('employeeManagerList') employeeManagerList: EmployeeManagerList;
    public exportBtnInfo = Res.ExportBtnInfo;
    
    public filter: string = '';
    public selectedDepartment: Department = null;
    public selectedCompany: Company = null;
    public selectedManager: Manager = null;
    public selectedEmployeeManager: Employee = null;
    public selectedCostCenters: CostCenter[] = null;
    public selectedDetailCostCenter: CostCenter = null;
    public selectedRole: RoleEntity = null;
    public employee: Employee = null;;
    isCollapsed: boolean = true;
    pageSize = 50;
    public data;
    public importLines: Array<EmployeeImport> = new Array<EmployeeImport>();
    public importIndex: number = 0;
    public fileEvent: any = null;
    public deletedEmployees: boolean = false;
    public get isAdmin(): boolean { return AppData.UserIsAdmin; };

    exportCompleted = true;
    public fileITEvent: any = null;
    public fileCorrectionEvent: any = null;
    public noOfItems: number = 0;
    public importLinesIT: Array<EmployeeImportSuperUser> = new Array<EmployeeImportSuperUser>();
    public importLinesCorrectionIT: Array<EmployeeImportCorrectionSuperUser> = new Array<EmployeeImportCorrectionSuperUser>();
    public importLinesV2: Array<AssetImportV2> = new Array<AssetImportV2>();

    public confirmationMessage: string = '';
    public operationType: OperationType = OperationType.NotSet;
    public initialSortColumn = '';
    public initialSortDirection = 'asc';
    public initialPageSize = 50;
    public refreshBtnInfo = Res.RefreshBtnInfo;

    constructor(public departmentHttpService: DepartmentHttpService,
                public companyHttpService: CompanyHttpService,
                public managerHttpService: ManagerHttpService,
                public costCenterHttpService: CostCenterHttpService,
                public employeeHttpService: EmployeeHttpService,
                public employeeMHttpService: EmployeeHttpService,
                public employeeManagerHttpService: EmployeeManagerHttpService,
                public notificationService: NotificationService,
                public roleHttpService: RoleService,
                public assetHttpService: AssetHttpService,
                public router: Router,
                public employeeDetailHttpService: EmployeeDetailHttpService) {

        super();
        this.router.events.subscribe((evt) => {
            if (evt instanceof NavigationEnd) {
                if (evt.urlAfterRedirects === '/employee') {

                    setTimeout(() => {
                        this.refresh();
                      }, 100);
                }
            }
        });
    }
  ngOnInit(): void {
    // setTimeout(() => {
    //   if(this.employeeManagerList != undefined){
    //     this.employeeManagerList.refresh(null);
    //   }
    // }, 3000);

  }

    ngAfterViewInit(): void {
        this.initialSortColumn = this.employeeList.sortColumn;
        this.initialSortDirection = this.employeeList.sortDirection;
        this.initialPageSize = this.employeeList.pageSize;
    }

    public resetAllFilters(){
        //set first page + refetch data
        this.employeeList.currentPage = 1;
        this.employeeList.pageSize = this.initialPageSize;
        this.employeeList.resetRows(this.initialPageSize);
        
        //reset sorting
        this.employeeList.sortColumn = this.initialSortColumn;
        this.employeeList.sortDirection = this.initialSortDirection;
        this.employeeList.resetSort();
        
        //reset filters
        this.clearFilters();

        this.refresh();
    }

    public clearFilters() {
        this.filter = '';
        this.employeeList.selectedItems = [];
    }

    public export() {
        this.employeeList.export$('Utilizatori').subscribe(
            () => {},
            error => {}
        );
    }

    get canExport() {
        return this.employeeList?.canExport;
}

    public syncEmp() {

        this.employeeHttpService.updateAllEmp().subscribe((count) => {
             alert('Au fost actualizati ' + JSON.stringify(count) + ' angajati!');
            // this.toastr.success('Au fost actualizati ' + JSON.stringify(count) + ' angajati!');
       });

}

    public showDeletedEmployees(){

        let params = new Array<Param>();
        params.push(new Param('deleted', JSON.stringify(this.deletedEmployees)));

        this.employeeList.refresh(params);

    }

    public doSimpleSearch(filter: string) {
        if (this.filter !== '' && this.filter !== undefined){
          this.employeeList.currentPage = 1;
          this.employeeList.first = 1;
          this.employeeList.resetDt();
        }
        super.doSimpleSearch(filter);
    }

    public addNewItem() {
        super.addNewItem();

        this.employeeDetail.department = null;
        this.employeeDetail.costCenterDetail = null;
    }

    public editItem() {
        super.editItem();

        let employee: EmployeeDetail = this.selectedItem as EmployeeDetail;

        this.employeeDetail.department = null;
        if (employee != null && employee.departmentId != null) {
            this.departmentHttpService
                .getById(employee.departmentId)
                .subscribe((department: Department) => {
                    this.employeeDetail.department = department;
                });
        }

        this.employeeDetail.company = null;
        if (employee != null && employee.company != null) {
            this.companyHttpService
                .getById(employee.company.id)
                .subscribe((company: Company) => {
                    this.employeeDetail.company = company;
                });
        }

        this.employeeDetail.manager = null;
        if (employee != null && employee.manager != null) {
            this.managerHttpService
                .getById(employee.manager.id)
                .subscribe((manager: Manager) => {
                    this.employeeDetail.manager = manager;
                });
        }

        this.employeeDetail.employeeManager = null;
        if (employee != null && employee.employeeManager != null) {
            this.employeeMHttpService
                .getById(employee.employeeManager.id)
                .subscribe((employeeManager: Employee) => {
                    this.employeeDetail.employeeManager = employeeManager;
                });
        }

        this.employeeDetail.costCenterDetail = null;
        if (employee != null && employee.costCenter != null) {
            this.costCenterHttpService
                .getById(employee.costCenter.id)
                .subscribe((costCenter: CostCenter) => {
                    this.employeeDetail.costCenterDetail = costCenter;
                });
        }

        this.employeeDetail.role = null;
        if (employee != null && employee.roleId != null) {
            this.roleHttpService
                .getDetailById(employee.roleId)
                .subscribe((role: RoleEntity) => {
                    this.employeeDetail.role = role;
                });
        }
    }

    public detailInitialize() {
        super.detailInitialize();
        this.employeeDetailModal.show();
    }

    public detailTerminate() {
        super.detailTerminate();
        this.employeeDetailModal.hide();
        this.resetAllFilters();
    }

    public onEmployeeDetailDepartmentNeeded() {
        this.employeeDetailModal.hide();
        this.selectDepartment();
    }

    public onDepartmentListCancel() {
        this.departmentListModal.hide();
        this.employeeDetailModal.show();
    }

    public onEmployeeDetailCostCenterNeeded() {
      this.employeeDetailModal.hide();
      this.selectCostCenter();
  }

  public onCostCenterListCancel() {
      this.costCenterListModal.hide();
      this.employeeDetailModal.show();
  }

    public onEmployeeDetailCompanyNeeded() {
      this.employeeDetailModal.hide();
      this.selectCompany();
  }

  public onCompanyListCancel() {
      this.companyListModal.hide();
      this.employeeDetailModal.show();
  }

  public onEmployeeDetailManagerNeeded() {
    this.employeeDetailModal.hide();
    this.selectManager();
}

public onEmployeeDetailEmployeeManagerNeeded() {
  this.employeeDetailModal.hide();
  this.selectEmployeeManager();
}

public onManagerListCancel() {
    this.managerListModal.hide();
    this.employeeDetailModal.show();
}

public onEmployeeManagerListCancel() {
  this.employeeManagerListModal.hide();
  this.employeeDetailModal.show();
}

    public onEmployeeDetailRoleNeeded() {
      this.employeeDetailModal.hide();
      this.selectRole();
  }

  public onRoleListCancel() {
      this.roleListModal.hide();
      if (this.viewMode === GenericManageViewMode.ItemDetail) {
          this.employeeDetailModal.show();
      }
  }

    public refresh() {
        let params: Array<Param> = new Array<Param>();

        params.push(new Param('filter', this.filter));
        //params.push(new Param('pageSize', this.pageSize.toString()));
        // params.push(new Param('departmentId', this.selectedDepartment != null ? this.selectedDepartment.id.toString() : null));
        params.push(new Param("departmentIds", AppUtils.getIdsList<Department, number>([ this.selectedDepartment ])));
        params.push(new Param('deleted', JSON.stringify(this.deletedEmployees)));

        this.employeeList.refresh(params);
    }

    public selectDepartment() {
        this.departmentListModal.show();
        this.departmentList.refresh(null);
    }

    public setSelectedDepartment() {
        switch(this.viewMode) {
            case GenericManageViewMode.ItemList:
                this.selectedDepartment = this.departmentList.selectedItem;
                this.departmentListModal.hide();
                this.refresh();
                break;
            case GenericManageViewMode.ItemDetail:
                this.employeeDetail.department = this.departmentList.selectedItem;
                this.departmentListModal.hide();
                this.employeeDetailModal.show();
                break;
        }
    }

    public unselectDepartment() {
        this.selectedDepartment = null;
        this.refresh();
    }

    public selectCostCenter() {
      this.costCenterListModal.show();
      this.costCenterList.refresh(null);
  }

  public setSelectedCostCenter() {
      switch(this.viewMode) {
          case GenericManageViewMode.ItemList:
              this.selectedDetailCostCenter = this.costCenterList.selectedItem;
              this.costCenterListModal.hide();
              this.refresh();
              break;
          case GenericManageViewMode.ItemDetail:
              this.employeeDetail.costCenterDetail = this.costCenterList.selectedItem;
              this.costCenterListModal.hide();
              this.employeeDetailModal.show();
              break;
      }
  }

  public unselectCostCenter() {
      this.selectedDetailCostCenter = null;
      this.refresh();
  }

    public selectCompany() {
      this.companyListModal.show();
      this.companyList.refresh(null);
  }

  public setSelectedCompany() {
      switch(this.viewMode) {
          case GenericManageViewMode.ItemList:
              this.selectedCompany = this.companyList.selectedItem;
              this.companyListModal.hide();
              this.refresh();
              break;
          case GenericManageViewMode.ItemDetail:
              this.employeeDetail.company = this.companyList.selectedItem;
              this.companyListModal.hide();
              this.employeeDetailModal.show();
              break;
      }
  }

  public unselectCompany() {
      this.selectedCompany = null;
      this.refresh();
  }

  public selectManager() {
    this.managerListModal.show();
    this.managerList.refresh(null);
}

public setSelectedManager() {
    switch(this.viewMode) {
        case GenericManageViewMode.ItemList:
            this.selectedManager = this.managerList.selectedItem;
            this.managerListModal.hide();
            this.refresh();
            break;
        case GenericManageViewMode.ItemDetail:
            this.employeeDetail.manager = this.managerList.selectedItem;
            this.managerListModal.hide();
            this.employeeDetailModal.show();
            break;
    }
}
public unselectManager() {
    this.selectedManager = null;
    this.refresh();
}

public selectEmployeeManager() {
  this.employeeManagerListModal.show();
  this.employeeManagerList.refresh(null);
}

public setSelectedEmployeeManager() {
  switch(this.viewMode) {
      case GenericManageViewMode.ItemList:
          this.selectedEmployeeManager = this.employeeManagerList.selectedItem;
          this.employeeManagerListModal.hide();
          this.refresh();
          break;
      case GenericManageViewMode.ItemDetail:
          this.employeeDetail.employeeManager = this.employeeManagerList.selectedItem;
          this.employeeDetail.manager = null;
          this.employeeManagerListModal.hide();
          this.employeeDetailModal.show();
          break;
  }
}
public unselectEmployeeManager() {
  this.selectedEmployeeManager = null;
  this.refresh();
}

    public selectRole() {
      this.roleListModal.show();
      this.roleList.refresh(null);
  }

  public setSelectedRole() {
      switch(this.viewMode) {
          case GenericManageViewMode.ItemList:
              this.selectedRole = this.roleList.selectedItem;
              this.roleListModal.hide();
              this.refresh();
              break;
          case GenericManageViewMode.ItemDetail:
              this.employeeDetail.role = this.roleList.selectedItem;
              this.roleListModal.hide();
              this.employeeDetailModal.show();
              break;
      }
  }

  public unselectRole() {
      this.selectedRole = null;
      this.refresh();
  }

    // public loadFile(ev) {
    //     this.fileEvent = ev;
    // }

    // public doImportEmployees() {
    //     if (this.importIndex < this.importLines.length) {
    //         this.employeeDetailHttpService.upload(this.importLines[this.importIndex]).subscribe((data) => {
    //             this.importIndex = this.importIndex + 1;
    //             this.doImportEmployees();
    //         });
    //     }
    //     else {
    //         this.fileEvent = null;
    //         this.importDataModal.hide();
    //         this.importIndex = 0;
    //         this.importLines = new Array<EmployeeImport>();
    //     }
    // }

    // public importEmployees() {

    //     let fi = this.fileInput.nativeElement;
    //     if (fi.files && fi.files[0]) {
    //         let fileToUpload = fi.files[0];
    //         this.employeeHttpService
    //             .import(fileToUpload)
    //             .subscribe(res => {
    //                 if (res.status === 200){
    //                      alert('Employees have been successfully updated!');
    //                     // alert('Datele au fost actualizate cu success!');
    //                 }else{
    //                     alert('Error importing employees!');
    //                         // alert('Eroare import!');
    //                 }
    //                 this.refresh();
    //             }, (error) => {
    //                 alert('Error importing employees!');
    //             });
    //     }

    // }

    // public importEmployees() {

    //     if (this.fileEvent === null) return;

    //     alasql.promise(`select
    //                         [ID] as [InternalCode],
    //                         [First Name] as [FirstName],
    //                         [Last Name] as [LastName],
    //                         [Email] as [Email],
    //                         [Supv ID] as [InternalCodeTl],
    //                         [Supervisor] as [FullNameTl],
    //                         [Dept] as [Department]
    //                         from FILE(?, {headers: true})`, [this.fileEvent])

    //         .then((importLines: Array<EmployeeImport>) => {

    //             this.importDataModal.show();

    //             this.importIndex = 0;
    //             this.importLines = importLines;

    //             this.doImportEmployees();
    //     });

    // }

    public getFilters(): Array<Param> {
        let params: Array<Param> = new Array<Param>();
        params.push(new Param('filter', this.filter));
        params.push(new Param("departmentIds", AppUtils.getIdsList<Department, number>([this.selectedDepartment])));
        params.push(new Param('deleted', JSON.stringify(this.deletedEmployees)));
        return params;
    }

    public exportToExcel() {

        let params: Array<Param> = null;

        params = this.getFilters();
        this.employeeHttpService
            .export(params)
            .subscribe((blob) => {
                fileSaveAs(blob.body, 'Export.xlsx');
            });
    }

    public editEmployee() {
        let selectedEmployeeId = this.employeeList.selectedItems.length > 0 ? this.employeeList.selectedItems[0].id : 0;
        if (selectedEmployeeId > 0) {
            this.router.navigate(['/employee', selectedEmployeeId]);
        }
    }



    collapsed(event: any): void {
        // console.log(event);
      }

      expanded(event: any): void {
        // console.log(event);
      }

      public onPageUpdate(number: number) {
        this.pageSize = number;
        this.refresh();
    }

    public loadFile(ev) {
      this.fileEvent = ev;
  }
  public doImportEmployees() {
      if (this.importIndex < this.importLines.length) {
          this.employeeHttpService.import(this.importLines[this.importIndex]).subscribe((data) => {
              this.importIndex = this.importIndex + 1;
              this.doImportEmployees();
          });
      }
      else {
          this.fileEvent = null;
          this.importDataModal.hide();
          this.importIndex = 0;
          this.importLines = new Array<EmployeeImport>();
      }
  }

  public importEmployees() {

      if (this.fileEvent === null) return;

      alasql.promise(`select
                         CAST([Cod Optima] as STRING) as [UniqueCode],
                         CAST([Stare] as STRING) as [EmployeeState],
                         CAST([Angajat/Partener] as STRING) as [Partner],
                         CAST([Tip User] as STRING) as [EmployeeType],
                         CAST([Companie] as STRING) as [Company],
                         CAST([Marca HR] as STRING) as [InternalCode],
                         CAST([Prenume] as STRING) as [LastName],
                         CAST([Nume] as STRING) as [FirstName],
                         CAST([Centru de cost] as STRING) as [CostCenter],
                         CAST([Departament] as STRING) as [Department],
                         CAST([Email] as STRING) as [Email],
                         CAST([Marca sef direct] as STRING) as [Manager],
                         CAST([Person ID Charisma] as STRING) as [EmployeeCharisma]
                         from FILE(?, {headers: true})`, [this.fileEvent])

          .then((importLines: Array<EmployeeImport>) => {
              // importLines = this.removeUndefinedFromArray(importLines);
              this.importDataModal.show();

              this.importIndex = 0;
              this.importLines = importLines;

              this.doImportEmployees();
      });

  }

  public onAllocate() {
    this.operationType = OperationType.MakeManager;
    this.confirmationMessage = 'Alocati dreptul de manager angajatului selectat?';
    this.confirmationModal.show();
}

public onCancelAllocate() {
  this.operationType = OperationType.CancelManager;
  this.confirmationMessage = 'Revocati dreptul de manager angajatului selectat?';
  this.confirmationModal.show();
}

  public onConfirmationCanceled() {
    this.operationType = OperationType.NotSet;
    this.confirmationModal.hide();
}

// public onConfirmationApproved() {

//     switch (this.operationType) {
//         case OperationType.MakeManager:
//              this.makeManager();
//             break;
//             case OperationType.CancelManager:
//               this.cancelManager();
//              break;
//         default:
//             break;
//     }

//     this.operationType = OperationType.NotSet;
//     this.confirmationModal.hide();
// }

// public makeManager() {

//   let items: Array<any> = this.employeeList.selectedItems;
//   this.employee = ((items != null) && (items.length === 1)) ? items[0] : null;
//   let makeManager: EmployeeManagerAdd = new EmployeeManagerAdd();
//   makeManager.employeeId = this.employee != null ? this.employee.id : null;

//   this.employeeManagerHttpService.addManagerByEmployee(makeManager).subscribe( (res) => {
//           if (res.statusCode === 200) {
//               this.notificationService.showSuccess('Datele au fost salvate cu success!', 'Alocare drepturi manager');
//               this.employeeManagerList.refresh(null);
//               // this.employeeCostCenterList.refresh(null);
//               // this.costCenterList.selectedItems = new Array<CostCenter>();
//           } else if (res.statusCode === 404) {
//               this.notificationService.showError('Nu exista', 'Alocare drepturi manager');
//               // this.costCenterList.selectedItems = new Array<CostCenter>();
//           }
//   }, (error) => {
//       this.notificationService.showError('Eroare salvare!', 'Alocare drepturi manager');
//       // this.costCenterList.selectedItems = new Array<CostCenter>();
//   });
// }

// public cancelManager() {

//   let items: Array<any> = this.employeeManagerList.selectedItems;
//   // this.employee = ((items != null) && (items.length === 1)) ? items[0] : null;
//   console.log(JSON.stringify(this.employeeManagerList.selectedItem));
//   let employeeId = this.employeeManagerList.selectedItem != null ? this.employeeManagerList.selectedItem.id : null;

//   this.employeeManagerHttpService.deleteManagerCostCenter(employeeId).subscribe( (res) => {
//           if (res.statusCode === 200) {
//               this.notificationService.showSuccess('Datele au fost salvate cu success!', 'Alocare drepturi manager');
//               this.employeeManagerList.refresh(null);
//               // this.employeeCostCenterList.refresh(null);
//               // this.costCenterList.selectedItems = new Array<CostCenter>();
//           } else if (res.statusCode === 404) {
//               this.notificationService.showError('Nu exista', 'Alocare drepturi manager');
//               // this.costCenterList.selectedItems = new Array<CostCenter>();
//           }
//   }, (error) => {
//       this.notificationService.showError('Eroare salvare!', 'Alocare drepturi manager');
//       // this.costCenterList.selectedItems = new Array<CostCenter>();
//   });
// }

public upload() {
  this.uploadModal.show();
}

public loadITFile(ev) {
  this.fileITEvent = ev;
}

public importITData() {
  this.importDataIT();
}

private importDataIT() {

  if (this.fileITEvent === null) return;

  alasql.promise(`select
                      CAST([Stare] as STRING) as [EmployeeState],
                      CAST([Angajat/Partener] as STRING) as [Partner],
                      CAST([Tip User] as STRING) as [EmployeeType],
                      CAST([Companie] as STRING) as [Company],
                      CAST([Marca HR] as STRING) as [InternalCode],
                      CAST([PRENUME] as STRING) as [LastName],
                      CAST([NUME] as STRING) as [FirstName],
                      CAST([CENTRU COST] as STRING) as [CostCenter],
                      CAST([DEPARTAMENT] as STRING) as [Department],
                      CAST([Email] as STRING) as [Email],
                      CAST([Marca sef direct] as STRING) as [Manager],
                      CAST([Person ID Charisma] as STRING) as [EmployeeCharisma]
                      from FILE(?, {headers: true})`, [this.fileITEvent])

      .then((importLinesIT: Array<EmployeeImportSuperUser>) => {

          //console.log(JSON.stringify(importLinesIT));

          this.importDataModal.show();
          this.uploadModal.hide();
          this.importIndex = 0;
          this.importLinesIT = importLinesIT;
          this.noOfItems = importLinesIT.length;

          this.doImportIT();

  }).catch(function(err){
      console.log('There was an error reading the source file.:', err);
    });
}

public doImportIT() {
  if (this.importIndex < this.importLinesIT.length) {
      this.importLinesIT[this.importIndex].index = this.importIndex;
      this.employeeHttpService.uploadV1(this.importLinesIT[this.importIndex]).subscribe((data) => {
          this.importIndex = this.importIndex + 1;
          this.doImportIT();
      });
  } else {
      this.fileITEvent = null;
      this.importDataModal.hide();
      this.fileInput.nativeElement.value = '';
      this.importIndex = 0;
      this.importLinesIT = new Array<EmployeeImportSuperUser>();
  }
}

public exportTemplateIT() {
  this.exportCompleted = false;
  this.employeeHttpService
      .exportTemplateSuperUser()
      .subscribe((blob) => {
        fileSaveAs(blob.body, 'model-import-superuser.xlsx');
          this.exportCompleted = true;
      });
}

public exportTemplateSuperUser() {
this.exportCompleted = false;
this.employeeHttpService
  .exportTemplateCorrectionSuperUser()
  .subscribe((blob) => {
      fileSaveAs(blob.body, 'model-import-superuser.xlsx');
      this.exportCompleted = true;
  });
}

public importData() {
  //  console.log('import type: ' + AppConfig.IMPORT_TYPE);
    // switch (AppConfig.IMPORT_TYPE) {
    //     case 'V1':
    //         this.importDataV1();
    //         break;
    //     case 'V2':
    //         this.importDataV2();
    //         break;
    //     default:
    //         break;
    // }

    this.importDataV2();
}

private importDataV2() {

  if (this.fileEvent === null) return;

  alasql.promise(`select
                      CAST([Numar de inventar Charisma] as STRING) as [ERPCode],
                      CAST([Numar inventar Optima] as STRING) as [InvNo],
                      CAST([Descriere] as STRING) as [Description],
                      CAST([Serial number] as STRING) as [SerialNumber],
                      CAST([Companie] as STRING) as [Company],
                      CAST([Data intrare] as STRING) as [ReceptionDate],
                      CAST([Nume Angajat] as STRING) as [EmployeeName],
                      CAST([Marca Angajat] as STRING) as [EmployeeCode],
                      CAST([Person ID Charisma] as STRING) as [EmployeeCharisma],
                      CAST([Stare asset] as STRING) as [AssetState],
                      CAST([Imperechere cu Charisma DA/NU] as STRING) as [AssetCharisma],
                      CAST([Alte Detalii] as STRING) as [Info],
                      CAST([Notite angajat] as STRING) as [EmployeeNotes],
                      CAST([Inventariabil] as STRING) as [Inventoryable],
                      CAST([OBI-MF] as STRING) as [OBIMF]
                      from FILE(?, {headers: true})`, [this.fileEvent])

      .then((importLinesV2: Array<AssetImportV2>) => {

          // console.log(JSON.stringify(importLines));

          this.importDataModal.show();
          this.uploadModal.hide();
          this.importIndex = 0;
          this.importLinesV2 = importLinesV2;
          this.noOfItems = importLinesV2.length;

          this.doImportV2();

  }).catch(function(err){
      console.log('There was an error reading the source file.:', err);
    });;
}

public doImportV2() {
  if (this.importIndex < this.importLinesV2.length) {
      this.assetHttpService.uploadV2(this.importLinesV2[this.importIndex]).subscribe((data) => {
          this.importIndex = this.importIndex + 1;
          this.doImportV2();
      });
  } else {
      this.fileEvent = null;
      this.importDataModal.hide();
      this.importIndex = 0;
      this.importLinesV2 = new Array<AssetImportV2>();
  }
}


public loadCorrectionFile(ev) {
  this.fileCorrectionEvent = ev;
}

public importCorrectionITData() {
  this.importCorrectionDataIT();
}

private importCorrectionDataIT() {

  if (this.fileCorrectionEvent === null) return;

  alasql.promise(`select
                      CAST([Stare] as STRING) as [EmployeeState],
                      CAST([Angajat/Partener] as STRING) as [Partner],
                      CAST([Tip User] as STRING) as [EmployeeType],
                      CAST([Companie] as STRING) as [Company],
                      CAST([Marca HR] as STRING) as [InternalCode],
                      CAST([Prenume] as STRING) as [LastName],
                      CAST([Nume] as STRING) as [FirstName],
                      CAST([Centru de cost] as STRING) as [CostCenter],
                      CAST([Departament] as STRING) as [Department],
                      CAST([Email] as STRING) as [Email],
                      CAST([Marca sef direct] as STRING) as [Manager],
                      CAST([Person ID Charisma] as STRING) as [EmployeeCharisma]
                      from FILE(?, {headers: true})`, [this.fileCorrectionEvent])

      .then((importLinesCorrectionIT: Array<EmployeeImportCorrectionSuperUser>) => {

          //console.log(JSON.stringify(importLinesIT));

          this.importDataModal.show();
          this.uploadModal.hide();
          this.importIndex = 0;
          this.importLinesCorrectionIT = importLinesCorrectionIT;
          this.noOfItems = importLinesCorrectionIT.length;

          this.doImportCorrectionIT();

  }).catch(function(err){
      console.log('There was an error reading the source file.:', err);
    });;
}

public doImportCorrectionIT() {
  if (this.importIndex < this.importLinesCorrectionIT.length) {
      this.employeeHttpService.importCorrection(this.importLinesCorrectionIT[this.importIndex]).subscribe((data) => {
          this.importIndex = this.importIndex + 1;
          this.doImportCorrectionIT();
      });
  } else {
      this.fileITEvent = null;
      this.importDataModal.hide();
      this.importIndex = 0;
      this.importLinesCorrectionIT = new Array<EmployeeImportCorrectionSuperUser>();
  }
}

}

enum OperationType {
  NotSet = 1,
  MakeManager = 2,
  CancelManager = 3,
  Delete = 4,
  ProcessAssetOp = 5
}

