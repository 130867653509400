import { Component, OnInit, ViewChild } from '@angular/core';
import { EChartsOption } from 'echarts';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { AdmCenterListComponent } from '../../../forms/administrations/adm-centers/adm-center.list';
import { CountryListComponent } from '../../../forms/administrations/countries/country.list';
import { RegionListComponent } from '../../../forms/administrations/regions/region.list';
import { ActivityList } from '../../../forms/assets/activities/activity.list';
import { AssetTypeListComponent } from '../../../forms/assets/asset-types/asset-type.list';
import { CompanyListComponent } from '../../../forms/assets/companies/company.list';
import { ProjectList } from '../../../forms/assets/projects/project.list';
import { AdmCenter } from '../../../model/api/administration/adm-center';
import { Country } from '../../../model/api/administration/country';
import { Region } from '../../../model/api/administration/region';
import { Activity } from '../../../model/api/assets/activity';
import { AssetType } from '../../../model/api/assets/asset-type';
import { Company } from '../../../model/api/assets/company';
import { Project } from '../../../model/api/assets/project';
import { LocationStatus } from '../../../model/api/common/location-status';
import { AdmCenterHttpService } from '../../../services/http/administration/adm-center.http.service';
import { CountryHttpService } from '../../../services/http/administration/contry.http.service';
import { RegionHttpService } from '../../../services/http/administration/region.http.service';
import { ActivityHttpService } from '../../../services/http/assets/activity.http.service';
import { AssetTypeHttpService } from '../../../services/http/assets/asset-type.http.service';
import { CompanyHttpService } from '../../../services/http/assets/company.http.service';
import { ProjectHttpService } from '../../../services/http/assets/project.http.service';
import { DashboardHttpService } from '../../../services/http/common/dashboard.http.service';


@Component({
  templateUrl: 'dashboard-budget-asset.component.html',
  styleUrls: ['./dashboard-budget-asset.component.scss']
})
export class DashboardBudgetAssetComponent implements OnInit {

   @ViewChild('projectList') public projectList: ProjectList;
   @ViewChild('projectListModal') public projectListModal: ModalDirective;
   @ViewChild('countryList') public countryList: CountryListComponent;
   @ViewChild('countryListModal') public countryListModal: ModalDirective;
   @ViewChild('admCenterList') public admCenterList: AdmCenterListComponent;
   @ViewChild('admCenterListModal') public admCenterListModal: ModalDirective;
   @ViewChild('regionList') public regionList: RegionListComponent;
   @ViewChild('regionListModal') public regionListModal: ModalDirective;
   @ViewChild('assetTypeList') public assetTypeList: AssetTypeListComponent;
   @ViewChild('assetTypeListModal') public assetTypeListModal: ModalDirective;
   @ViewChild('companyList') public companyList: CompanyListComponent;
   @ViewChild('companyListModal') public companyListModal: ModalDirective;
   @ViewChild('activityList') public activityList: ActivityList;
   @ViewChild('activityListModal') public activityListModal: ModalDirective;

   public selectedProjects: Array<Project> = new Array<Project>();
   public projectId = 0;
   public selectedCountries: Array<Country> = new Array<Country>();
   public countryId = 0;
   public selectedAdmCenters: Array<AdmCenter> = new Array<AdmCenter>();
   public admCenterId = 0;
   public selectedRegions: Array<Region> = new Array<Region>();
   public regionId = 0;
   public selectedAssetTypes: Array<AssetType> = new Array<AssetType>();
   public assetTypeId = 0;
   public selectedCompanies: Array<Company> = new Array<Company>();
   public companyId = 0;
   public selectedActivities: Array<Activity> = new Array<Company>();
   public activityId = 0;

  subsription: Subscription;
  _chartOption: EChartsOption;
  isDarkMode: boolean = false;
  _theme: string;

  constructor(
      private dashbBoardService: DashboardHttpService,
      public countryHttpService: CountryHttpService,
      public admCenterHttpService: AdmCenterHttpService,
      public regionHttpService: RegionHttpService,
      public assetTypeHttpService: AssetTypeHttpService,
      public companyHttpService: CompanyHttpService,
      public activityHttpService: ActivityHttpService,
      public projectHttpService: ProjectHttpService) {
  }


  ngOnInit(): void {
    this.subsription = this.dashbBoardService.companyDynamicGroup(0, this.companyId, this.countryId, this.projectId, this.admCenterId, this.regionId,
      this.assetTypeId, this.activityId).subscribe( data => {
      // console.log(JSON.stringify(data));
      this._initBasicLineChart(data);
    });

    // this.subsription = this.dashbBoardService.companyGroup(0, this.companyId).subscribe( data => {
    //   // console.log(JSON.stringify(data));
    //   this._initBasicLineChart(data);
    // });
  }


  private _initBasicLineChart (chartData: any) {
    this._theme = this.isDarkMode ? 'dark' : '';

    // console.log(JSON.stringify(chartData.map(row => row['valueIni'])));

    let maxValue = chartData.map(row => row['value']).reduce((max, val) => max > val ? max : val, chartData.map(row => row['value'])[0]);
    maxValue = (maxValue * 1.1);
    maxValue = parseFloat(maxValue).toFixed(2);

    let maxValueDep = chartData.map(row => row['valueDep']).reduce((max, val) => max > val ? max : val, chartData.map(row => row['valueDep'])[0]);
    maxValueDep = (maxValueDep * 1.1);
    maxValueDep = parseFloat(maxValueDep).toFixed(2);


    let maxValueRem = chartData.map(row => row['valueRem']).reduce((max, val) => max > val ? max : val, chartData.map(row => row['valueRem'])[0]);
    maxValueRem = (maxValueRem * 1.1);
    maxValueRem = parseFloat(maxValueRem).toFixed(2);

    const sumValue = chartData.map(row => row['value']).reduce((partial_sum, a) => partial_sum + a, 0);
    const sumValueDep = chartData.map(row => row['valueDep']).reduce((partial_sum, a) => partial_sum + a, 0);
    const sumValueRem = chartData.map(row => row['valueRem']).reduce((partial_sum, a) => partial_sum + a, 0);

    const downloadJson = {
      'Valoare': sumValue,
      'Actuala': sumValueDep,
      'Ramasa': sumValueRem,
    };

    const waterMarkText = '';

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    canvas.width = canvas.height = 100;
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    ctx.globalAlpha = 0.08;
    ctx.font = '20px Microsoft Yahei';
    ctx.translate(50, 50);
    ctx.rotate(-Math.PI / 4);
    ctx.fillText(waterMarkText, 0, 0);

    this._chartOption = {
        backgroundColor: {
            type: 'pattern',
            image: canvas,
            repeat: 'repeat'
        },
        tooltip: {},
        title: [{
            text: '',
            // subtext: sum,
            left: '25%',
            textAlign: 'center'
        }, {
            text: 'TOTAL ',
            // subtext: '总计 ' + Object.keys(downloadJson).reduce(function (all, key) {
            //     return all + downloadJson[key];
            // }, 0),
            left: '75%',
            textAlign: 'center'
        }],
        legend: {
          data: ['Valoare', 'Actuala', 'Ramasa']
      },
        grid: [{
            top: 50,
            width: '50%',
            bottom: '45%',
            left: 10,
            containLabel: true
        }],
        xAxis: [{
            type: 'value',
            max: maxValue,
            splitLine: {
                show: false
            }
        }],
        yAxis: [{
            type: 'category',
            data: chartData.map(row => row['code']),
            axisLabel: {
                interval: 0,
                rotate: 0
            },
            splitLine: {
                show: false
            }
        }],
        series: [
          {
            name: 'Valoare',
            type: 'bar',
            label: {
                show: true,
                position: 'right'
            },
            emphasis: {
                focus: 'series'
            },
            data: chartData.map(row => row['value'])
        },
        {
          name: 'Actuala',
          type: 'bar',
          stack: '总量',
          label: {
              show: true,
              position: 'right'
          },
          emphasis: {
              focus: 'series'
          },
          data: chartData.map(row => row['valueDep'])
      },
        {
            name: 'Ramasa',
            type: 'bar',
            stack: 'chart',
            z: 3,
            label: {
                position: 'right',
                show: true
            },
            data: Object.keys(chartData.map(row => row['valueRem'])).map(function (key) {
                return chartData.map(row => row['valueRem'])[key];
            })
        }, {
            type: 'bar',
            stack: 'chart',
            silent: true,
            itemStyle: {
                color: '#eee'
            },
            data: Object.keys(chartData.map(row => row['value'])).map(function (key) {
                return maxValue - chartData.map(row => row['value'])[key];
            })
        }, {
            type: 'pie',
            selectedMode: 'single',
            radius: [0, '30%'],
            center: ['75%', '25%'],
            label: {
              position: 'inner',
              fontSize: 14,
          },
          labelLine: {
              show: false
          },
            data: [
            {value: sumValue, name: sumValue, selected: true},
            {value: sumValueDep, name: sumValueDep},
            {value: sumValueRem, name: sumValueRem}]
        }]
    };
}



  public selectProjects() {

    this.projectListModal.show();
    this.projectList.selectedItems = this.selectedProjects;
    this.projectList.refresh(null);
}

public setSelectedProjects() {
  this.selectedProjects = this.projectList.selectedItems;
  this.projectListModal.hide();
  this.companyId = this.companyList != null && this.companyList.selectedItems[0] != null ? this.companyList.selectedItems[0].id : 0;
  this.countryId = this.countryList != null && this.countryList.selectedItems[0] != null ? this.countryList.selectedItems[0].id : 0;
  this.projectId = this.projectList != null && this.projectList.selectedItems[0] != null ? this.projectList.selectedItems[0].id : 0;
  this.admCenterId = this.admCenterList != null && this.admCenterList.selectedItems[0] != null ? this.admCenterList.selectedItems[0].id : 0;
  this.regionId = this.regionList != null && this.regionList.selectedItems[0] != null ? this.regionList.selectedItems[0].id : 0;
  this.assetTypeId = this.assetTypeList != null && this.assetTypeList.selectedItems[0] != null ? this.assetTypeList.selectedItems[0].id : 0;
  this.activityId = this.activityList != null && this.activityList.selectedItems[0] != null ? this.activityList.selectedItems[0].id : 0;
  this.subsription = this.dashbBoardService.companyDynamicGroup(0, this.companyId, this.countryId, this.projectId, this.admCenterId, this.regionId,
    this.assetTypeId, this.activityId).subscribe( data => {
    this._initBasicLineChart(data);
  });
  }

  public clearProjectFilters() {
    this.companyId = 0;
    this.countryId = 0;
    this.projectId = 0;
    this.admCenterId = 0;
    this.regionId = 0;
    this.assetTypeId = 0;
    this.activityId = 0;
    this.selectedCountries = new Array<Country>();
    this.selectedCompanies = new Array<Company>();
    this.selectedProjects = new Array<Project>();
    this.selectedAdmCenters = new Array<AdmCenter>();
    this.selectedRegions = new Array<Region>();
    this.selectedAssetTypes = new Array<AssetType>();
    this.selectedActivities = new Array<Activity>();
    this.subsription = this.dashbBoardService.companyDynamicGroup(0, this.companyId, this.countryId, this.projectId, this.admCenterId, this.regionId, 
      this.assetTypeId, this.activityId).subscribe( data => {
      this._initBasicLineChart(data);
    });
}

  public selectCountries() {

    this.countryListModal.show();
    this.countryList.selectedItems = this.selectedCountries;
    this.countryList.refresh(null);
}

public setSelectedCountries() {
  this.selectedCountries = this.countryList.selectedItems;
  this.countryListModal.hide();
  this.companyId = this.companyList != null && this.companyList.selectedItems[0] != null ? this.companyList.selectedItems[0].id : 0;
  this.countryId = this.countryList != null && this.countryList.selectedItems[0] != null ? this.countryList.selectedItems[0].id : 0;
  this.projectId = this.projectList != null && this.projectList.selectedItems[0] != null ? this.projectList.selectedItems[0].id : 0;
  this.admCenterId = this.admCenterList != null && this.admCenterList.selectedItems[0] != null ? this.admCenterList.selectedItems[0].id : 0;
  this.regionId = this.regionList != null && this.regionList.selectedItems[0] != null ? this.regionList.selectedItems[0].id : 0;
  this.assetTypeId = this.assetTypeList != null && this.assetTypeList.selectedItems[0] != null ? this.assetTypeList.selectedItems[0].id : 0;
  this.activityId = this.activityList != null && this.activityList.selectedItems[0] != null ? this.activityList.selectedItems[0].id : 0;
  this.subsription = this.dashbBoardService.companyDynamicGroup(0, this.companyId, this.countryId, this.projectId, this.admCenterId, this.regionId,
    this.assetTypeId, this.activityId).subscribe( data => {
    this._initBasicLineChart(data);
  });
  }

  public clearCountryFilters() {
    this.companyId = 0;
    this.countryId = 0;
    this.projectId = 0;
    this.admCenterId = 0;
    this.regionId = 0;
    this.assetTypeId = 0;
    this.activityId = 0;
    this.selectedCountries = new Array<Country>();
    this.selectedCompanies = new Array<Company>();
    this.selectedProjects = new Array<Project>();
    this.selectedAdmCenters = new Array<AdmCenter>();
    this.selectedRegions = new Array<Region>();
    this.selectedAssetTypes = new Array<AssetType>();
    this.selectedActivities = new Array<Activity>();
    this.subsription = this.dashbBoardService.companyDynamicGroup(0, this.companyId, this.countryId, this.projectId, this.admCenterId, this.regionId, 
      this.assetTypeId, this.activityId).subscribe( data => {
      this._initBasicLineChart(data);
    });
}

public selectAdmCenters() {

    this.admCenterListModal.show();
    this.admCenterList.selectedItems = this.selectedAdmCenters;
    this.admCenterList.refresh(null);
}

public setSelectedAdmCenters() {
  this.selectedAdmCenters = this.admCenterList.selectedItems;
  this.admCenterListModal.hide();
  this.companyId = this.companyList != null && this.companyList.selectedItems[0] != null ? this.companyList.selectedItems[0].id : 0;
  this.countryId = this.countryList != null && this.countryList.selectedItems[0] != null ? this.countryList.selectedItems[0].id : 0;
  this.projectId = this.projectList != null && this.projectList.selectedItems[0] != null ? this.projectList.selectedItems[0].id : 0;
  this.admCenterId = this.admCenterList != null && this.admCenterList.selectedItems[0] != null ? this.admCenterList.selectedItems[0].id : 0;
  this.regionId = this.regionList != null && this.regionList.selectedItems[0] != null ? this.regionList.selectedItems[0].id : 0;
  this.assetTypeId = this.assetTypeList != null && this.assetTypeList.selectedItems[0] != null ? this.assetTypeList.selectedItems[0].id : 0;
  this.activityId = this.activityList != null && this.activityList.selectedItems[0] != null ? this.activityList.selectedItems[0].id : 0;
  this.subsription = this.dashbBoardService.companyDynamicGroup(0, this.companyId, this.countryId, this.projectId, this.admCenterId, this.regionId,
    this.assetTypeId, this.activityId).subscribe( data => {
    this._initBasicLineChart(data);
  });
  }

  public clearAdmCenterFilters() {
    this.companyId = 0;
    this.countryId = 0;
    this.projectId = 0;
    this.admCenterId = 0;
    this.regionId = 0;
    this.assetTypeId = 0;
    this.activityId = 0;
    this.selectedCountries = new Array<Country>();
    this.selectedCompanies = new Array<Company>();
    this.selectedProjects = new Array<Project>();
    this.selectedAdmCenters = new Array<AdmCenter>();
    this.selectedRegions = new Array<Region>();
    this.selectedAssetTypes = new Array<AssetType>();
    this.selectedActivities = new Array<Activity>();
    this.subsription = this.dashbBoardService.companyDynamicGroup(0, this.companyId, this.countryId, this.projectId, this.admCenterId, this.regionId, 
      this.assetTypeId, this.activityId).subscribe( data => {
      this._initBasicLineChart(data);
    });
}

public selectRegions() {

    this.regionListModal.show();
    this.regionList.selectedItems = this.selectedRegions;
    this.regionList.refresh(null);
}

public setSelectedRegions() {
  this.selectedRegions = this.regionList.selectedItems;
  this.regionListModal.hide();
  this.companyId = this.companyList != null && this.companyList.selectedItems[0] != null ? this.companyList.selectedItems[0].id : 0;
  this.countryId = this.countryList != null && this.countryList.selectedItems[0] != null ? this.countryList.selectedItems[0].id : 0;
  this.projectId = this.projectList != null && this.projectList.selectedItems[0] != null ? this.projectList.selectedItems[0].id : 0;
  this.admCenterId = this.admCenterList != null && this.admCenterList.selectedItems[0] != null ? this.admCenterList.selectedItems[0].id : 0;
  this.regionId = this.regionList != null && this.regionList.selectedItems[0] != null ? this.regionList.selectedItems[0].id : 0;
  this.assetTypeId = this.assetTypeList != null && this.assetTypeList.selectedItems[0] != null ? this.assetTypeList.selectedItems[0].id : 0;
  this.activityId = this.activityList != null && this.activityList.selectedItems[0] != null ? this.activityList.selectedItems[0].id : 0;
  this.subsription = this.dashbBoardService.companyDynamicGroup(0, this.companyId, this.countryId, this.projectId, this.admCenterId, this.regionId,
    this.assetTypeId, this.activityId).subscribe( data => {
    this._initBasicLineChart(data);
  });
  }

  public clearRegionFilters() {
    this.companyId = 0;
    this.countryId = 0;
    this.projectId = 0;
    this.admCenterId = 0;
    this.regionId = 0;
    this.assetTypeId = 0;
    this.activityId = 0;
    this.selectedCountries = new Array<Country>();
    this.selectedCompanies = new Array<Company>();
    this.selectedProjects = new Array<Project>();
    this.selectedAdmCenters = new Array<AdmCenter>();
    this.selectedRegions = new Array<Region>();
    this.selectedAssetTypes = new Array<AssetType>();
    this.selectedActivities = new Array<Activity>();
    this.subsription = this.dashbBoardService.companyDynamicGroup(0, this.companyId, this.countryId, this.projectId, this.admCenterId, this.regionId, 
      this.assetTypeId, this.activityId).subscribe( data => {
      this._initBasicLineChart(data);
    });
}

public selectAssetTypes() {

    this.assetTypeListModal.show();
    this.assetTypeList.selectedItems = this.selectedAssetTypes;
    this.assetTypeList.refresh(null);
}

public setSelectedAssetTypes() {
  this.selectedAssetTypes = this.assetTypeList.selectedItems;
  this.assetTypeListModal.hide();
  this.companyId = this.companyList != null && this.companyList.selectedItems[0] != null ? this.companyList.selectedItems[0].id : 0;
  this.countryId = this.countryList != null && this.countryList.selectedItems[0] != null ? this.countryList.selectedItems[0].id : 0;
  this.projectId = this.projectList != null && this.projectList.selectedItems[0] != null ? this.projectList.selectedItems[0].id : 0;
  this.admCenterId = this.admCenterList != null && this.admCenterList.selectedItems[0] != null ? this.admCenterList.selectedItems[0].id : 0;
  this.regionId = this.regionList != null && this.regionList.selectedItems[0] != null ? this.regionList.selectedItems[0].id : 0;
  this.assetTypeId = this.assetTypeList != null && this.assetTypeList.selectedItems[0] != null ? this.assetTypeList.selectedItems[0].id : 0;
  this.activityId = this.activityList != null && this.activityList.selectedItems[0] != null ? this.activityList.selectedItems[0].id : 0;
  this.subsription = this.dashbBoardService.companyDynamicGroup(0, this.companyId, this.countryId, this.projectId, this.admCenterId, this.regionId,
    this.assetTypeId, this.activityId).subscribe( data => {
    this._initBasicLineChart(data);
  });
  }

  public clearAssetTypeFilters() {
    this.companyId = 0;
    this.countryId = 0;
    this.projectId = 0;
    this.admCenterId = 0;
    this.regionId = 0;
    this.assetTypeId = 0;
    this.activityId = 0;
    this.selectedCountries = new Array<Country>();
    this.selectedCompanies = new Array<Company>();
    this.selectedProjects = new Array<Project>();
    this.selectedAdmCenters = new Array<AdmCenter>();
    this.selectedRegions = new Array<Region>();
    this.selectedAssetTypes = new Array<AssetType>();
    this.selectedActivities = new Array<Activity>();
    this.subsription = this.dashbBoardService.companyDynamicGroup(0, this.companyId, this.countryId, this.projectId, this.admCenterId, this.regionId, 
      this.assetTypeId, this.activityId).subscribe( data => {
      this._initBasicLineChart(data);
    });
}

public selectCompanies() {

    this.companyListModal.show();
    this.companyList.selectedItems = this.selectedCompanies;
    this.companyList.refresh(null);
}

public setSelectedCompanies() {
  this.selectedCompanies = this.companyList.selectedItems;
  this.companyListModal.hide();
  this.companyId = this.companyList != null && this.companyList.selectedItems[0] != null ? this.companyList.selectedItems[0].id : 0;
  this.countryId = this.countryList != null && this.countryList.selectedItems[0] != null ? this.countryList.selectedItems[0].id : 0;
  this.projectId = this.projectList != null && this.projectList.selectedItems[0] != null ? this.projectList.selectedItems[0].id : 0;
  this.admCenterId = this.admCenterList != null && this.admCenterList.selectedItems[0] != null ? this.admCenterList.selectedItems[0].id : 0;
  this.regionId = this.regionList != null && this.regionList.selectedItems[0] != null ? this.regionList.selectedItems[0].id : 0;
  this.assetTypeId = this.assetTypeList != null && this.assetTypeList.selectedItems[0] != null ? this.assetTypeList.selectedItems[0].id : 0;
  this.activityId = this.activityList != null && this.activityList.selectedItems[0] != null ? this.activityList.selectedItems[0].id : 0;
  this.subsription = this.dashbBoardService.companyDynamicGroup(0, this.companyId, this.countryId, this.projectId, this.admCenterId, this.regionId,
    this.assetTypeId, this.activityId).subscribe( data => {
    this._initBasicLineChart(data);
  });
  }

  public clearCompanyFilters() {
    this.companyId = 0;
    this.countryId = 0;
    this.projectId = 0;
    this.admCenterId = 0;
    this.regionId = 0;
    this.assetTypeId = 0;
    this.activityId = 0;
    this.selectedCountries = new Array<Country>();
    this.selectedCompanies = new Array<Company>();
    this.selectedProjects = new Array<Project>();
    this.selectedAdmCenters = new Array<AdmCenter>();
    this.selectedRegions = new Array<Region>();
    this.selectedAssetTypes = new Array<AssetType>();
    this.selectedActivities = new Array<Activity>();
    this.subsription = this.dashbBoardService.companyDynamicGroup(0, this.companyId, this.countryId, this.projectId, this.admCenterId, this.regionId, 
      this.assetTypeId, this.activityId).subscribe( data => {
      this._initBasicLineChart(data);
    });
}


public selectActivities() {

  this.activityListModal.show();
  this.activityList.selectedItems = this.selectedActivities;
  this.activityList.refresh(null);
}

public setSelectedActivities() {
this.selectedActivities = this.activityList.selectedItems;
this.activityListModal.hide();
this.companyId = this.companyList != null && this.companyList.selectedItems[0] != null ? this.companyList.selectedItems[0].id : 0;
this.countryId = this.countryList != null && this.countryList.selectedItems[0] != null ? this.countryList.selectedItems[0].id : 0;
this.projectId = this.projectList != null && this.projectList.selectedItems[0] != null ? this.projectList.selectedItems[0].id : 0;
this.admCenterId = this.admCenterList != null && this.admCenterList.selectedItems[0] != null ? this.admCenterList.selectedItems[0].id : 0;
this.regionId = this.regionList != null && this.regionList.selectedItems[0] != null ? this.regionList.selectedItems[0].id : 0;
this.assetTypeId = this.assetTypeList != null && this.assetTypeList.selectedItems[0] != null ? this.assetTypeList.selectedItems[0].id : 0;
this.activityId = this.activityList != null && this.activityList.selectedItems[0] != null ? this.activityList.selectedItems[0].id : 0;
this.subsription = this.dashbBoardService.companyDynamicGroup(0, this.companyId, this.countryId, this.projectId, this.admCenterId, this.regionId,
  this.assetTypeId, this.activityId).subscribe( data => {
  this._initBasicLineChart(data);
});
}

public clearActivityFilters() {
  this.companyId = 0;
  this.countryId = 0;
  this.projectId = 0;
  this.admCenterId = 0;
  this.regionId = 0;
  this.assetTypeId = 0;
  this.activityId = 0;
  this.selectedCountries = new Array<Country>();
  this.selectedCompanies = new Array<Company>();
  this.selectedProjects = new Array<Project>();
  this.selectedAdmCenters = new Array<AdmCenter>();
  this.selectedRegions = new Array<Region>();
  this.selectedAssetTypes = new Array<AssetType>();
  this.selectedActivities = new Array<Activity>();
  this.subsription = this.dashbBoardService.companyDynamicGroup(0, this.companyId, this.countryId, this.projectId, this.admCenterId, this.regionId, 
    this.assetTypeId, this.activityId).subscribe( data => {
    this._initBasicLineChart(data);
  });
}

}
