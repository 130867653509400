import { Component } from '@angular/core';

@Component({
    selector: 'error-confirm-email-page',
    templateUrl: 'error-confirm-email-page.html'
})
export class ErrorConfirmEmailPage {
    constructor() {

    }
}
