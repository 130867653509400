import { Component, EventEmitter, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ExpAccount } from '../../../model/api/administration/exp-account';
import { PagedResult } from '../../../model/common/paged-result';
import { Param } from '../../../model/common/param';
import { AdmCenterHttpService } from '../../../services/http/administration/adm-center.http.service';
import { ExpAccountHttpService } from '../../../services/http/administration/exp-account.http.service';
import { GenericManage } from '../../generic/generic.manage'; 


@Component({
    selector: 'exp-account-manage',
    templateUrl: 'exp-account.manage.html',
    providers: [ AdmCenterHttpService ]
})
export class ExpAccountManage extends GenericManage<ExpAccount, number> {

     @ViewChild('itemDetailModal') modal: ModalDirective;

    public filter: string = '';
    isCollapsed: boolean = true;

    constructor(public expAccountHttpService: ExpAccountHttpService) {
        super();
    }

     public detailInitialize() {
        this.modal.show();
    }

    public detailTerminate() {
        this.modal.hide();
    }

    public exportToExcel() {

        let params: Array<Param> = null;

        if ((this.filter != null) && (this.filter.length > 0)) {
            params = new Array<Param>();
            params.push(new Param('filter', this.filter));
        }

        this.expAccountHttpService.get(1, 1000000, 'name', 'asc', params, null).subscribe(
            (data: PagedResult<ExpAccount>) => {

                let options = {
                    sheetid: 'centre_logistice',
                    headers: true,
                    column: { style: { Font: { Bold: '1' } } },
                    rows: { 1: { style: { Font: { Color: '#FF0077' } } } },
                    cells: { 1: { 1: { style: { Font: { Color: '#00FFFF' } } } } }
                };

                // alasql(`SELECT id as [Id], 
                //     name as [Denumire] 
                //     INTO XLSX("centre_logistice.xlsx",?) FROM ?`, [ options, data.items ]);

            });
    }

    collapsed(event: any): void {
        // console.log(event);
      }
    
      expanded(event: any): void {
        // console.log(event);
      }
}
