import { Component, EventEmitter, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AssetDepMDCapSync } from '../../../model/api/sap/asset-dep-md-cap-sync';
import { GenericDetail } from '../../generic/generic.detail';

@Component({
    selector: 'app-cap-amortization-detail',
    templateUrl: 'cap-amortization.detail.html'
})
export class CapAmortizationDetailComponent extends GenericDetail<AssetDepMDCapSync, number> {

    @ViewChild('detailForm') detailForm: FormGroup;

    setItemDefaultValues() {
        this.item = new AssetDepMDCapSync();
    }

    public resetForm() {
        this.detailForm.reset();
    }
}