import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { GenericHttpService } from "../generic.http.service";
import { Article } from "../../../model/api/assets/article";

@Injectable()
export class ArticleHttpService extends GenericHttpService<Article, number> {
    constructor(public http: HttpClient) {
        super(http, "", "articles");
    }
}