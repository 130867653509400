import { Component, OnInit, ViewChild } from '@angular/core';
import { EChartsOption } from 'echarts';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { AdmCenterListComponent } from '../../../forms/administrations/adm-centers/adm-center.list';
import { CountryListComponent } from '../../../forms/administrations/countries/country.list';
import { RegionListComponent } from '../../../forms/administrations/regions/region.list';
import { AssetTypeListComponent } from '../../../forms/assets/asset-types/asset-type.list';
import { CompanyListComponent } from '../../../forms/assets/companies/company.list';
import { ProjectList } from '../../../forms/assets/projects/project.list';
import { AdmCenter } from '../../../model/api/administration/adm-center';
import { Country } from '../../../model/api/administration/country';
import { Region } from '../../../model/api/administration/region';
import { AssetType } from '../../../model/api/assets/asset-type';
import { Company } from '../../../model/api/assets/company';
import { Project } from '../../../model/api/assets/project';
import { LocationStatus } from '../../../model/api/common/location-status';
import { AdmCenterHttpService } from '../../../services/http/administration/adm-center.http.service';
import { CountryHttpService } from '../../../services/http/administration/contry.http.service';
import { RegionHttpService } from '../../../services/http/administration/region.http.service';
import { AssetTypeHttpService } from '../../../services/http/assets/asset-type.http.service';
import { CompanyHttpService } from '../../../services/http/assets/company.http.service';
import { ProjectHttpService } from '../../../services/http/assets/project.http.service';
import { DashboardHttpService } from '../../../services/http/common/dashboard.http.service';


@Component({
  templateUrl: 'dashboard-budget.component.html',
  styleUrls: ['./dashboard-budget.component.scss']
})
export class DashboardBudgetComponent implements OnInit {

   @ViewChild('projectList') public projectList: ProjectList;
   @ViewChild('projectListModal') public projectListModal: ModalDirective;
   @ViewChild('countryList') public countryList: CountryListComponent;
   @ViewChild('countryListModal') public countryListModal: ModalDirective;
   @ViewChild('admCenterList') public admCenterList: AdmCenterListComponent;
   @ViewChild('admCenterListModal') public admCenterListModal: ModalDirective;
   @ViewChild('regionList') public regionList: RegionListComponent;
   @ViewChild('regionListModal') public regionListModal: ModalDirective;
   @ViewChild('assetTypeList') public assetTypeList: AssetTypeListComponent;
   @ViewChild('assetTypeListModal') public assetTypeListModal: ModalDirective;
   @ViewChild('companyList') public companyList: CompanyListComponent;
   @ViewChild('companyListModal') public companyListModal: ModalDirective;

   public selectedProjects: Array<Project> = new Array<Project>();
   public projectId = 0;
   public selectedCountries: Array<Country> = new Array<Country>();
   public countryId = 0;
   public selectedAdmCenters: Array<AdmCenter> = new Array<AdmCenter>();
   public admCenterId = 0;
   public selectedRegions: Array<Region> = new Array<Region>();
   public regionId = 0;
   public selectedAssetTypes: Array<AssetType> = new Array<AssetType>();
   public assetTypeId = 0;
   public selectedCompanies: Array<Company> = new Array<Company>();
   public companyId = 0;

  subsription: Subscription;
  _chartOption: EChartsOption;
  isDarkMode: boolean = false;
  _theme: string;

  constructor(
      private dashbBoardService: DashboardHttpService,
      public countryHttpService: CountryHttpService,
      public admCenterHttpService: AdmCenterHttpService,
      public regionHttpService: RegionHttpService,
      public assetTypeHttpService: AssetTypeHttpService,
      public companyHttpService: CompanyHttpService,
      public projectHttpService: ProjectHttpService) {
  }


  ngOnInit(): void {
    this.subsription = this.dashbBoardService.projectGroup(0, this.projectId).subscribe( data => {
      // console.log(JSON.stringify(data));
      this._initBasicLineChart(data);
    });
  }


  private _initBasicLineChart (chartData: any) {
    this._theme = this.isDarkMode ? 'dark' : '';

    // console.log(JSON.stringify(chartData.map(row => row['valueIni'])));


//   this._chartOption = {
//     tooltip: {
//         trigger: 'axis',
//         axisPointer: {
//             type: 'shadow',
//             label: {
//                 show: true
//             }
//         }
//     },
//     toolbox: {
//         show: true,
//         feature: {
//             mark: {show: true},
//             dataView: {show: true, readOnly: false},
//             magicType: {show: true, type: ['line', 'bar']},
//             restore: {show: true},
//             saveAsImage: {show: true}
//         }
//     },
//     calculable: true,
//     legend: {
//         data: ['Growth', 'Budget 2011', 'Budget 2012'],
//         itemGap: 5
//     },
//     grid: {
//         top: '12%',
//         left: '1%',
//         right: '10%',
//         containLabel: true
//     },
//     xAxis: [
//         {
//             type: 'category',
//             data: chartData.map(row => row['name'])
//         }
//     ],
//     yAxis: [
//         {
//             type: 'value',
//             name: 'Budget (million USD)',
//             axisLabel: {
//                 // formatter: function (a) {
//                 //     a = +a;
//                 //     return isFinite(a)
//                 //         ? .format.addCommas(+a / 1000)
//                 //         : '';
//                 // }
//             }
//         }
//     ],
//     dataZoom: [
//         {
//             show: true,
//             start: 94,
//             end: 100
//         },
//         {
//             type: 'inside',
//             start: 94,
//             end: 100
//         },
//         {
//             show: true,
//             yAxisIndex: 0,
//             filterMode: 'empty',
//             width: 30,
//             height: '80%',
//             showDataShadow: false,
//             left: '93%'
//         }
//     ],
//     series: [
//         {
//             name: 'Budget 2011',
//             type: 'bar',
//             data: chartData.map(row => row['valueIni'])
//         },
//         {
//             name: 'Budget 2012',
//             type: 'bar',
//             data: chartData.map(row => row['valueFin'])
//         }
//     ]
// };


// this._chartOption = {
//     tooltip: {
//         trigger: 'axis',
//         axisPointer: {            // 坐标轴指示器，坐标轴触发有效
//             type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
//         }
//     },
//     legend: {
//         data: ['Ianuarie', 'Februarie', 'Martie', 'Aprilie', 'Mai', 'Iunie', 'Iulie', 'August', 'Septembrie', 'Octombrie', 'Noiembrie', 'Decembrie']
//     },
//     grid: {
//         left: '3%',
//         right: '4%',
//         bottom: '3%',
//         containLabel: true
//     },
//     xAxis: [
//         {
//             type: 'category',
//             data: ['Ianuarie', 'Februarie', 'Martie', 'Aprilie', 'Mai', 'Iunie', 'Iulie', 'August', 'Septembrie', 'Octombrie', 'Noiembrie', 'Decembrie']
//         }
//     ],
//     yAxis: [
//         {
//             type: 'value'
//         }
//     ],
//     series: [
//         {
//             name: 'Ianuarie',
//             type: 'bar',
//             emphasis: {
//                 focus: 'series'
//             },
//             data: [320, 332, 301, 334, 390, 330, 320]
//         },
//         {
//             name: 'Februarie',
//             type: 'bar',
//             stack: '广告',
//             emphasis: {
//                 focus: 'series'
//             },
//             data: [120, 132, 101, 134, 90, 230, 210]
//         },
//         {
//             name: 'Martie',
//             type: 'bar',
//             stack: '广告',
//             emphasis: {
//                 focus: 'series'
//             },
//             data: [220, 182, 191, 234, 290, 330, 310]
//         },
//         {
//             name: 'Aprilie',
//             type: 'bar',
//             stack: '广告',
//             emphasis: {
//                 focus: 'series'
//             },
//             data: [150, 232, 201, 154, 190, 330, 410]
//         },
//         {
//             name: 'Mai',
//             type: 'bar',
//             data: [862, 1018, 964, 1026, 1679, 1600, 1570],
//             emphasis: {
//                 focus: 'series'
//             },
//             // markLine: {
//             //     lineStyle: {
//             //         type: 'dashed'
//             //     },
//             //     data: [
//             //         [{type: 'min'}, {type: 'max'}]
//             //     ]
//             // }
//         },
//         {
//             name: 'Iunie',
//             type: 'bar',
//             barWidth: 5,
//             stack: '搜索引擎',
//             emphasis: {
//                 focus: 'series'
//             },
//             data: [620, 732, 701, 734, 1090, 1130, 1120]
//         },
//         {
//             name: 'Iulie',
//             type: 'bar',
//             stack: '搜索引擎',
//             emphasis: {
//                 focus: 'series'
//             },
//             data: [120, 132, 101, 134, 290, 230, 220]
//         },
//         {
//             name: 'August',
//             type: 'bar',
//             stack: '搜索引擎',
//             emphasis: {
//                 focus: 'series'
//             },
//             data: [60, 72, 71, 74, 190, 130, 110]
//         },
//         {
//             name: 'Septembrie',
//             type: 'bar',
//             stack: '搜索引擎',
//             emphasis: {
//                 focus: 'series'
//             },
//             data: [62, 82, 91, 84, 109, 110, 120]
//         },
//         {
//             name: 'Octombrie',
//             type: 'bar',
//             stack: '搜索引擎',
//             emphasis: {
//                 focus: 'series'
//             },
//             data: [62, 82, 91, 84, 109, 110, 120]
//         },
//         {
//             name: 'Noiembrie',
//             type: 'bar',
//             stack: '搜索引擎',
//             emphasis: {
//                 focus: 'series'
//             },
//             data: [62, 82, 91, 84, 109, 110, 120]
//         },
//         {
//             name: 'Decembrie',
//             type: 'bar',
//             stack: '搜索引擎',
//             emphasis: {
//                 focus: 'series'
//             },
//             data: [62, 82, 91, 84, 109, 110, 120]
//         }
//     ]
// };

// const builderJson = {
//     'all': 10887,
//     'charts': {
//       '01/12': 7355,
//       '01/11': 2405,
//       '01/10': 1842,
//       '01/09': 2090,
//       '01/08': 1762,
//       '01/07': 1593,
//       '01/06': 2060,
//       '01/05': 1537,
//       '01/04': 1908,
//       '01/03': 2107,
//       '01/02': 1692,
//       '01/01': 1568
//     },
//     'components': {
//       '01/12': 2788,
//       '01/11': 9575,
//       'l01/10': 9400,
//       '01/09': 9466,
//       '01/08': 9266,
//       '01/07': 3419,
//       '01/06': 2984,
//       '01/05': 2739,
//       '01/04': 2744,
//       '01/03': 2466,
//       '01/02': 3034,
//       '01/01': 1945
//     },
//     'ie': 9743
//   };

      let maxValue = chartData.values.map(row => row['value']).reduce((max, val) => max > val ? max : val, chartData.values.map(row => row['value'])[0]);
      maxValue = (maxValue * 1.1);
      maxValue = parseFloat(maxValue).toFixed(2);
      let maxValueDep = chartData.valueDeps.map(row => row['value']).reduce((max, val) => max > val ? max : val, chartData.valueDeps.map(row => row['value'])[0]);
      maxValueDep = (maxValueDep * 1.1);
      maxValueDep = parseFloat(maxValueDep).toFixed(2);

  const builderJson = {
    'maxValue': maxValue,
    'maxValueDep': maxValueDep,
    'charts': {
        '04/01/2021': chartData.values.map(row => row['value'])[0],
        '05/01/2021': chartData.values.map(row => row['value'])[1],
        '06/01/2021': chartData.values.map(row => row['value'])[2],
        '07/01/2021': chartData.values.map(row => row['value'])[3],
        '08/01/2021': chartData.values.map(row => row['value'])[4],
        '09/01/2021': chartData.values.map(row => row['value'])[5],
        '10/01/2021': chartData.values.map(row => row['value'])[6],
        '11/01/2021': chartData.values.map(row => row['value'])[7],
        '12/01/2021': chartData.values.map(row => row['value'])[8],
        '01/01/2021': chartData.values.map(row => row['value'])[9],
        '02/01/2021': chartData.values.map(row => row['value'])[10],
        '03/01/2021': chartData.values.map(row => row['value'])[11],
    },
    'components': {
        '04/01/2021': chartData.valueDeps.map(row => row['value'])[0],
        '05/01/2021': chartData.valueDeps.map(row => row['value'])[1],
        '06/01/2021': chartData.valueDeps.map(row => row['value'])[2],
        '07/01/2021': chartData.valueDeps.map(row => row['value'])[3],
        '08/01/2021': chartData.valueDeps.map(row => row['value'])[4],
        '09/01/2021': chartData.valueDeps.map(row => row['value'])[5],
        '10/01/2021': chartData.valueDeps.map(row => row['value'])[6],
        '11/01/2021': chartData.valueDeps.map(row => row['value'])[7],
        '12/01/2021': chartData.valueDeps.map(row => row['value'])[8],
        '01/01/2021': chartData.valueDeps.map(row => row['value'])[9],
        '02/01/2021': chartData.valueDeps.map(row => row['value'])[10],
        '03/01/2021': chartData.valueDeps.map(row => row['value'])[11],
    },
    'ie': 0
  };

//   const downloadJson = {
//     'echarts.min.js': 17365,
//     'echarts.simple.min.js': 4079,
//     'echarts.common.min.js': 6929,
//     'echarts.js': 14890
//   };

  const downloadJson: any = {
    '04/01/2021': chartData.values.map(row => row['value'])[0],
    '05/01/2021': chartData.values.map(row => row['value'])[1],
    '06/01/2021': chartData.values.map(row => row['value'])[2],
    '07/01/2021': chartData.values.map(row => row['value'])[3],
    '08/01/2021': chartData.values.map(row => row['value'])[4],
    '09/01/2021': chartData.values.map(row => row['value'])[5],
    '10/01/2021': chartData.values.map(row => row['value'])[6],
    '11/01/2021': chartData.values.map(row => row['value'])[7],
    '12/01/2021': chartData.values.map(row => row['value'])[8],
    '01/01/2021': chartData.values.map(row => row['value'])[9],
    '02/01/2021': chartData.values.map(row => row['value'])[10],
    '03/01/2021': chartData.values.map(row => row['value'])[11],
  };

  const themeJson = {
    '04/01/2021': chartData.valueDeps.map(row => row['value'])[0],
    '05/01/2021': chartData.valueDeps.map(row => row['value'])[1],
    '06/01/2021': chartData.valueDeps.map(row => row['value'])[2],
    '07/01/2021': chartData.valueDeps.map(row => row['value'])[3],
    '08/01/2021': chartData.valueDeps.map(row => row['value'])[4],
    '09/01/2021': chartData.valueDeps.map(row => row['value'])[5],
    '10/01/2021': chartData.valueDeps.map(row => row['value'])[6],
    '11/01/2021': chartData.valueDeps.map(row => row['value'])[7],
    '12/01/2021': chartData.valueDeps.map(row => row['value'])[8],
    '01/01/2021': chartData.valueDeps.map(row => row['value'])[9],
    '02/01/2021': chartData.valueDeps.map(row => row['value'])[10],
    '03/01/2021': chartData.valueDeps.map(row => row['value'])[11],
  };

  const waterMarkText = 'BUGETE';

  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  canvas.width = canvas.height = 100;
  ctx.textAlign = 'center';
  ctx.textBaseline = 'middle';
  ctx.globalAlpha = 0.08;
  ctx.font = '20px Microsoft Yahei';
  ctx.translate(50, 50);
  ctx.rotate(-Math.PI / 4);
  ctx.fillText(waterMarkText, 0, 0);


this._chartOption = {
    backgroundColor: {
        type: 'pattern',
        image: canvas,
        repeat: 'repeat'
    },
    tooltip: {},
    title: [{
        text: 'Valori',
        // subtext: 'Total ' + builderJson.all,
        left: '25%',
        textAlign: 'center'
    }, {
        text: 'Buget',
        // subtext: 'Total ' + Object.keys(downloadJson).reduce(function (all, key) {
        //     return all + downloadJson[key];
        // }, 0),
        left: '75%',
        textAlign: 'center'
    }, {
        text: 'Amortizare',
        // subtext: 'Total ' + Object.keys(themeJson).reduce(function (all, key) {
        //     return all + themeJson[key];
        // }, 0),
        left: '75%',
        top: '50%',
        textAlign: 'center'
    }],
    grid: [{
        top: 50,
        width: '50%',
        bottom: '45%',
        left: 10,
        containLabel: true
    }, {
        top: '55%',
        width: '50%',
        bottom: 0,
        left: 10,
        containLabel: true
    }],
    xAxis: [{
        type: 'value',
        max: builderJson.maxValue,
        splitLine: {
            show: false
        }
    }, {
        type: 'value',
        max: builderJson.maxValueDep,
        gridIndex: 1,
        splitLine: {
            show: false
        }
    }],
    yAxis: [{
        type: 'category',
        data: Object.keys(builderJson.charts),
        axisLabel: {
            interval: 0,
            rotate: 0
        },
        splitLine: {
            show: false
        }
    }, {
        gridIndex: 1,
        type: 'category',
        data: Object.keys(builderJson.components),
        axisLabel: {
            interval: 0,
            rotate: 0
        },
        splitLine: {
            show: false
        }
    }],
    series: [{
        type: 'bar',
        stack: 'chart',
        z: 3,
        label: {
            position: 'right',
            show: true
        },
        data: Object.keys(builderJson.charts).map(function (key) {
            return builderJson.charts[key];
        })
    }, {
        type: 'bar',
        stack: 'chart',
        silent: true,
        itemStyle: {
            color: '#eee'
        },
        data: Object.keys(builderJson.charts).map(function (key) {
            return builderJson.maxValue - builderJson.charts[key];
        })
    }, {
        type: 'bar',
        stack: 'component',
        xAxisIndex: 1,
        yAxisIndex: 1,
        z: 3,
        label: {
            position: 'right',
            show: true
        },
        data: Object.keys(builderJson.components).map(function (key) {
            return builderJson.components[key];
        })
    }, {
        type: 'bar',
        stack: 'component',
        silent: true,
        xAxisIndex: 1,
        yAxisIndex: 1,
        itemStyle: {
            color: '#eee'
        },
        data: Object.keys(builderJson.components).map(function (key) {
            return builderJson.maxValueDep - builderJson.components[key];
        })
    }, {
        type: 'pie',
        radius: [0, '30%'],
        center: ['75%', '25%'],
        data: Object.keys(downloadJson).map(function (key) {
            return {
                name: key.replace('.js', ''),
                value: downloadJson[key]
            };
        })
    }, {
        type: 'pie',
        radius: [0, '30%'],
        center: ['75%', '75%'],
        data: Object.keys(themeJson).map(function (key) {
            return {
                name: key.replace('.js', ''),
                value: themeJson[key]
            };
        })
    }]
};
}

  public selectProjects() {

    this.projectListModal.show();
    this.projectList.selectedItems = this.selectedProjects;
    this.projectList.refresh(null);
}

public setSelectedProjects() {
  this.selectedProjects = this.projectList.selectedItems;
  this.projectListModal.hide();
  this.projectId = this.projectList != null && this.projectList.selectedItems[0] != null ? this.projectList.selectedItems[0].id : 0;
  this.subsription = this.dashbBoardService.projectGroup(0, this.projectId).subscribe( data => {
    // console.log(JSON.stringify(data));
    this._initBasicLineChart(data);
  });
  }

  public clearProjectFilters() {
    this.projectId = 0;
    this.selectedProjects = new Array<Project>();
    this.subsription = this.dashbBoardService.projectGroup(0, this.projectId).subscribe( data => {
        this._initBasicLineChart(data);
      });
}

  public selectCountries() {

    this.countryListModal.show();
    this.countryList.selectedItems = this.selectedCountries;
    this.countryList.refresh(null);
}

public setSelectedCountries() {
  this.selectedCountries = this.countryList.selectedItems;
  this.countryListModal.hide();
  this.countryId = this.countryList != null && this.countryList.selectedItems[0] != null ? this.countryList.selectedItems[0].id : 0;
  this.subsription = this.dashbBoardService.countryGroup(0, this.countryId).subscribe( data => {
    this._initBasicLineChart(data);
  });
  }

  public clearCountryFilters() {
    this.countryId = 0;
    this.selectedCountries = new Array<Country>();
    this.subsription = this.dashbBoardService.countryGroup(0, this.countryId).subscribe( data => {
        this._initBasicLineChart(data);
      });
}

public selectAdmCenters() {

    this.admCenterListModal.show();
    this.admCenterList.selectedItems = this.selectedAdmCenters;
    this.admCenterList.refresh(null);
}

public setSelectedAdmCenters() {
  this.selectedAdmCenters = this.admCenterList.selectedItems;
  this.admCenterListModal.hide();
  this.admCenterId = this.admCenterList != null && this.admCenterList.selectedItems[0] != null ? this.admCenterList.selectedItems[0].id : 0;
  this.subsription = this.dashbBoardService.admCenterGroup(0, this.admCenterId).subscribe( data => {
    this._initBasicLineChart(data);
  });
  }

  public clearAdmCenterFilters() {
    this.admCenterId = 0;
    this.selectedAdmCenters = new Array<AdmCenter>();
    this.subsription = this.dashbBoardService.admCenterGroup(0, this.admCenterId).subscribe( data => {
        this._initBasicLineChart(data);
      });
}

public selectRegions() {

    this.regionListModal.show();
    this.regionList.selectedItems = this.selectedRegions;
    this.regionList.refresh(null);
}

public setSelectedRegions() {
  this.selectedRegions = this.regionList.selectedItems;
  this.regionListModal.hide();
  this.regionId = this.regionList != null && this.regionList.selectedItems[0] != null ? this.regionList.selectedItems[0].id : 0;
  this.subsription = this.dashbBoardService.regionGroup(0, this.regionId).subscribe( data => {
    this._initBasicLineChart(data);
  });
  }

  public clearRegionFilters() {
    this.regionId = 0;
    this.selectedRegions = new Array<Region>();
    this.subsription = this.dashbBoardService.regionGroup(0, this.regionId).subscribe( data => {
        this._initBasicLineChart(data);
      });
}

public selectAssetTypes() {

    this.assetTypeListModal.show();
    this.assetTypeList.selectedItems = this.selectedAssetTypes;
    this.assetTypeList.refresh(null);
}

public setSelectedAssetTypes() {
  this.selectedAssetTypes = this.assetTypeList.selectedItems;
  this.assetTypeListModal.hide();
  this.assetTypeId = this.assetTypeList != null && this.assetTypeList.selectedItems[0] != null ? this.assetTypeList.selectedItems[0].id : 0;
  this.subsription = this.dashbBoardService.assetTypeGroup(0, this.assetTypeId).subscribe( data => {
    this._initBasicLineChart(data);
  });
  }

  public clearAssetTypeFilters() {
    this.assetTypeId = 0;
    this.selectedAssetTypes = new Array<AssetType>();
    this.subsription = this.dashbBoardService.assetTypeGroup(0, this.assetTypeId).subscribe( data => {
        this._initBasicLineChart(data);
      });
}

public selectCompanies() {

    this.companyListModal.show();
    this.companyList.selectedItems = this.selectedCompanies;
    this.companyList.refresh(null);
}

public setSelectedCompanies() {
  this.selectedCompanies = this.companyList.selectedItems;
  this.companyListModal.hide();
  this.companyId = this.companyList != null && this.companyList.selectedItems[0] != null ? this.companyList.selectedItems[0].id : 0;
  this.subsription = this.dashbBoardService.companyGroup(0, this.companyId).subscribe( data => {
    this._initBasicLineChart(data);
  });
  }

  public clearCompanyFilters() {
    this.companyId = 0;
    this.selectedCompanies = new Array<Company>();
    this.subsription = this.dashbBoardService.companyGroup(0, this.companyId).subscribe( data => {
        this._initBasicLineChart(data);
      });
}

}
