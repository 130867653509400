import { PagedResult } from './../../../model/common/paged-result';
import { Param } from './../../../model/common/param';
import { Component, ViewChild } from '@angular/core';
import { GenericManage } from '../../generic/generic.manage';
import { DictionaryType } from '../../../model/api/administration/dictionary-type';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { OrderTypeHttpService } from '../../../services/http/orders/order-type.http.service';
import { OrderType } from '../../../model/api/order/order-type';
import { OrderTypeListComponent } from './order-type.list';
import { OrderTypeDetailComponent } from './order-type.detail';
@Component({
    selector: 'app-order-type-manage',
    templateUrl: 'order-type.manage.html',
    providers: [ OrderTypeHttpService ]
})
export class OrderTypeManageComponent extends GenericManage<OrderType, number> {

    @ViewChild('orderTypeDetailModal') orderTypeDetailModal: ModalDirective;
    @ViewChild('orderTypeList') orderTypeList: OrderTypeListComponent;
    @ViewChild('orderTypeDetail') orderTypeDetail: OrderTypeDetailComponent;
    isCollapsed: boolean = true;
    public filter: string = '';

    constructor(public orderTypeHttpService: OrderTypeHttpService) {
        super();
    }

    public addNewItem() {
        super.addNewItem();
    }

    public editItem() {
        super.editItem();
    }

    public detailInitialize() {
        super.detailInitialize();
        this.orderTypeDetailModal.show();
    }

    public detailTerminate() {
        super.detailTerminate();
        this.orderTypeDetailModal.hide();
    }

    public refresh() {
        const params: Array<Param> = new Array<Param>();

        params.push(new Param('filter', this.filter));

        this.orderTypeList.refresh(params);
    }

    public exportToExcel(){

         let params: Array<Param> = null;

        if ((this.filter != null) && (this.filter.length > 0)) {
            params = new Array<Param>();
            params.push(new Param('filter', this.filter));
        }

        this.orderTypeHttpService.get(1, 10000000, 'code', 'asc', params, null).subscribe(
            (data: PagedResult<DictionaryType>) => {

                const options = {
                    sheetid: 'Orase',
                    headers: true,
                    column: { style: { Font: { Bold: '1' } } },
                    rows: { 1: { style: { Font: { Color: '#FF0077' } } } },
                    cells: { 1: { 1: { style: { Font: { Color: '#00FFFF' } } } } }
                };

                // alasql(`SELECT id as [Id],
                //     code as [Cod],
                //     name as [Denumire]
                //     INTO XLSX("Orase.xlsx",?) FROM ?`, [ options, data.items ]);

            });

    }

    collapsed(event: any): void {
        // console.log(event);
      }
      expanded(event: any): void {
        // console.log(event);
      }
}
