import { Component } from '@angular/core';
import { AppData } from '../../../app-data';
import { EmailManager } from '../../../model/api/administration/email-manager';
import { Param } from '../../../model/common/param';
import { GenericTableList } from '../../generic/generic.table.list';
@Component({
    selector: 'email-manager-list',
    // templateUrl: '../../generic/generic.table.list.html'
    templateUrl: '../../assets/assets/offer-email-validate.list.html',
})
export class EmailManagerList extends GenericTableList<EmailManager, number> {

    private listName: string = 'EMAILMANAGERS';

    constructor() {
        super('id', 'asc', '');
        this.columns = AppData.ColumnDefinitions[this.listName];
    }

    public refresh(filters: Array<Param>) {
        this.columns = AppData.ColumnDefinitions[this.listName];
        super.refresh(filters);
    }
}

