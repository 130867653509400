import { PagedResult } from './../../../model/common/paged-result';
import { Param } from './../../../model/common/param';
import { Component, EventEmitter, ViewChild } from '@angular/core';
import { GenericManage, GenericManageViewMode } from '../../generic/generic.manage';
import { DeviceList } from './device.list';
import { DeviceDetail as DeviceDetailUI } from './device.detail';
import { DeviceTypeList } from '../device-types/device-type.list';
import { DeviceTypeHttpService } from '../../../services/http/common/device-type.http.service';
import { DeviceHttpService } from '../../../services/http/common/device.http.service';
import { DeviceDetailHttpService } from '../../../services/http/common/device-detail.http.service';
import { Pocket } from '../../../model/api/common/device';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { DeviceType } from '../../../model/api/common/device-type';
import { DeviceDetail } from '../../../model/api/common/device-detail';
import { AppUtils } from '../../../common/app.utils';

@Component({
    selector: 'device-manage',
    templateUrl: 'device.manage.html',
    providers: [ DeviceTypeHttpService, DeviceHttpService, DeviceDetailHttpService ]
})
export class DeviceManage extends GenericManage<Pocket, number> {

    @ViewChild('deviceDetailModal') deviceDetailModal: ModalDirective;
    @ViewChild('deviceList') deviceList: DeviceList;
    @ViewChild('deviceDetail') deviceDetail: DeviceDetailUI;
    @ViewChild('deviceTypeListModal') deviceTypeListModal: ModalDirective;
    @ViewChild('deviceTypeList') deviceTypeList: DeviceTypeList;

    public filter: string = '';
    public selectedDeviceType: DeviceType = null;
    isCollapsed: boolean = true;

    constructor(
        public deviceTypeHttpService: DeviceTypeHttpService,
        public deviceHttpService: DeviceHttpService,
        public deviceDetailHttpService: DeviceDetailHttpService,
        ) {

        super();
    }

    public addNewItem() {
        super.addNewItem();

        this.deviceDetail.deviceType = null;
    }

    public editItem() {
        super.editItem();

        let device: DeviceDetail = this.selectedItem as DeviceDetail;

        this.deviceDetail.deviceType = null;

        if (device != null && device.deviceType != null) {
            this.deviceTypeHttpService
                .getById(device.deviceType.id)
                .subscribe((deviceType: DeviceType) => {
                    this.deviceDetail.deviceType = deviceType;
                });
        }
    }

    public detailInitialize() {
        super.detailInitialize();
        this.deviceDetailModal.show();
    }

    public detailTerminate() {
        super.detailTerminate();
        this.deviceDetailModal.hide();
    }

    public onDeviceDetailDeviceTypeNeeded() {
        this.deviceDetailModal.hide();
        this.selectDeviceType();
    }

    public onDeviceTypeListCancel() {
        this.deviceTypeListModal.hide();
        if (this.viewMode === GenericManageViewMode.ItemDetail) {
            this.deviceDetailModal.show();
        }
    }

    public refresh() {
        let params: Array<Param> = new Array<Param>();

        params.push(new Param('filter', this.filter));
        params.push(new Param('deviceTypeIds', AppUtils.getIdsList<DeviceType, number>([ this.selectedDeviceType ])));

        this.deviceList.refresh(params);
    }

    public selectDeviceType() {
        this.deviceTypeListModal.show();
        this.deviceTypeList.refresh(null);
    }

    public setSelectedDeviceType() {
        switch(this.viewMode) {
            case GenericManageViewMode.ItemList:
                this.selectedDeviceType = this.deviceTypeList.selectedItem;
                this.deviceTypeListModal.hide();
                this.refresh();
                break;
            case GenericManageViewMode.ItemDetail:
                this.deviceDetail.deviceType = this.deviceTypeList.selectedItem;
                this.deviceTypeListModal.hide();
                this.deviceDetailModal.show();
                break;
        }
    }


    public unselectDeviceType() {
        this.selectedDeviceType = null;
        this.refresh();
    }

    public exportToExcel() {

         let params: Array<Param> = null;

        if ((this.filter != null) && (this.filter.length > 0)) {
            params = new Array<Param>();
            params.push(new Param('filter', this.filter));
        }

        this.deviceDetailHttpService.get(1, 1000000, 'code', 'asc', params, null).subscribe(
            (data: PagedResult<Pocket>) => {

                let options = {
                    sheetid: 'Dispozitive',
                    headers: true,
                    column: { style: { Font: { Bold: '1' } } },
                    rows: { 1: { style: { Font: { Color: '#FF0077' } } } },
                    cells: { 1: { 1: { style: { Font: { Color: '#00FFFF' } } } } }
                };

                // let res = alasql(`SELECT id as [Id],
                //                     code as [Cod],
                //                     name as [Denumire]
                //                     INTO XLSX('Dispozitive.xlsx',?) FROM ?`,[ options, data.items ]);

            });
    }

    collapsed(event: any): void {
        // console.log(event);
      }
    
      expanded(event: any): void {
        // console.log(event);
      }
}
