import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AssetClass } from '../../../model/api/assets/asset-class';
import { GenericHttpService } from '../generic.http.service';
// import { AuthHttp } from 'angular2-jwt';

@Injectable()
export class AssetClassHttpService extends GenericHttpService<AssetClass, number> {
    constructor(public http: HttpClient) {
        super(http, "", "assetclasses");
    }
}