import { Component, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Level } from '../../../model/api/administration/level';
import { PagedResult } from '../../../model/common/paged-result';
import { Param } from '../../../model/common/param';
import { LevelHttpService } from '../../../services/http/administration/level.http.service';
import { GenericManage } from '../../generic/generic.manage';
import { LevelDetailComponent } from './level.detail';
import { LevelListComponent } from './level.list';
@Component({
    selector: 'app-level-manage',
    templateUrl: 'level.manage.html',
    providers: [ LevelHttpService]
})
export class LevelManageComponent extends GenericManage<Level, number> {

     @ViewChild('levelDetailModal') levelDetailModal: ModalDirective;
     @ViewChild('levelList') levelList: LevelListComponent;
     @ViewChild('levelDetail') levelDetail: LevelDetailComponent;

     isCollapsed: boolean = true;
    public filter: string = '';

    constructor(
        public levelHttpService: LevelHttpService) {
        super();
    }


    public addNewItem() {
        super.addNewItem();
    }

    public editItem() {
        super.editItem();
    }

     public detailInitialize() {
        this.levelDetailModal.show();
    }

    public detailTerminate() {
        this.levelDetailModal.hide();
    }

    public refresh() {
        const params: Array<Param> = new Array<Param>();

        params.push(new Param('filter', this.filter));

        this.levelList.refresh(params);
    }


    public exportToExcel() {

        let params: Array<Param> = null;

        if ((this.filter != null) && (this.filter.length > 0)) {
            params = new Array<Param>();
            params.push(new Param('filter', this.filter));
        }

        this.levelHttpService.get(1, 1000000, 'name', 'asc', params, null).subscribe(
            (data: PagedResult<Level>) => {

                const options = {
                    sheetid: 'levels',
                    headers: true,
                    column: { style: { Font: { Bold: '1' } } },
                    rows: { 1: { style: { Font: { Color: '#FF0077' } } } },
                    cells: { 1: { 1: { style: { Font: { Color: '#00FFFF' } } } } }
                };

                // alasql(`SELECT id as [Id],
                //     name as [Denumire],
                //     employee->firstName as [Gestionar Nume],
                //     employee->lastName as [Gestionar Prenume],
                //     employee->email as [Gestionar Email]
                //     INTO XLSX("AssetTypes.xlsx",?) FROM ?`, [ options, data.items ]);

            });
    }

    collapsed(event: any): void {
        // console.log(event);
      }
      expanded(event: any): void {
        // console.log(event);
      }
}
