import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { GenericHttpService } from "../generic.http.service";
import { Material } from "../../../model/api/administration/material";

@Injectable()
export class MaterialHttpService extends GenericHttpService<Material, number> {
    constructor(public http: HttpClient) {
        super(http, "", "materials");
    }
}