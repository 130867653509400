import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DeviceType } from '../../../model/api/common/device-type';
import { GenericHttpService } from '../generic.http.service';

@Injectable()
export class DeviceTypeHttpService extends GenericHttpService<DeviceType, number> {
    constructor(public http: HttpClient) {
        super(http, '', 'devicetypes');
    }
}
