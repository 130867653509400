import { Company } from "../assets/company";
import { CodeNameEntity } from "../common/code-name-entity";

export class CostCenter {
    id: number;
    code: string;
    name: string;
    admCenterId: number;
    admCenter: CodeNameEntity;
    regionId: number;
    region: CodeNameEntity;
    divisionId: number;
    division: CodeNameEntity;
    administrationId: number;
    administration: CodeNameEntity;
    companyId: number;
    company: Company;
    roomId: number;
    room: CodeNameEntity;
    state: any; notSync: any; isLocked: any;
    // employeeId: number;
    // employeeId2: number;
    // employeeId3: number;
    // employeeId4: number;
    // employeeId5: number;

    constructor(id: number, code: string, name: string) {
        this.id = id;
        this.code = code;
        this.name = name;
    }
}