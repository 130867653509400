import { Param } from './../../../model/common/param';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GenericHttpService } from '../generic.http.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AppConfig } from '../../../config';
import { map } from 'rxjs/operators';
import { AssetTempRecoSave } from '../../../model/api/assets/asset-temp-reco-save';
import { AssetImportV2 } from '../../../model/common/import/asset-import-v2';
import { AssetImportV1 } from '../../../model/common/import/asset-import-v1';
import { AssetSave } from '../../../model/api/assets/asset-save';
import { AssetTransferAdd } from '../../../model/api/assets/asset-transfer-add';
import { EmployeeValidate } from '../../../model/common/import/employee-validate';
import { ImportThales } from '../../../model/common/import/import-thales';
import { ImportITThales } from '../../../model/common/import/import-IT-thales';
import { PrintLabel } from '../../../model/common/print-label';
import { AddAsset } from '../../../model/api/assets/add-asset';
import { AddStockAsset } from '../../../model/api/assets/add-stock-asset';
import { AssetChange } from '../../../model/api/assets/asset-change';
import { AssetAcquizition } from '../../../model/api/assets/asset-aquizition';
import { SaveAssetCloneTransfer, SaveAssetTransfer } from '../../../model/api/assets/asset-transfer';
import { SaveRetireAsset, SaveStornoAsset } from '../../../model/api/assets/asset-out';
import { AssetEmployeeSave } from '../../../model/api/assets/asset-employee-save';
import { CreateAssetSAPResult } from '../../../model/api/result/create-asset-SAP-result';
import { NotifyService } from '../../notify.service';
import { SaveAssetInvMinus } from '../../../model/api/assets/asset-minus-inventory-sap';
import { SaveAssetInvPlus } from '../../../model/api/assets/asset-plus-inventory-sap';
import { UpdateAssetInvPlus } from '../../../model/api/assets/update-asset-inv-plus';
import { AddAssetInvPlus } from '../../../model/api/assets/add-asset-inv-plus';
import { AssetResult } from '../../../model/api/result/asset-result';
import { SaveAssetAcquisitionStorno } from '../../../model/api/assets/asset-acquisition-storno';
import { AssetEditChange } from '../../../model/api/assets/asset-edit-change';
import { AssetClone } from '../../../model/api/assets/asset-clone';
import { AssetBuy } from '../../../model/api/assets/asset-buy';
// import { AuthHttp } from 'angular2-jwt';
import { BuyResult } from '../../../model/api/result/buy-result';
import { ValidateAssetBuy } from '../../../model/api/assets/validate-asset-buy';
import { AssetImportIT } from '../../../model/common/import/asset-import-it';
import { InfoEmployeeSave } from '../../../model/api/assets/info-employee-save';
import { ValidateAssetITBuy } from '../../../model/api/assets/validate-asset-IT-buy';
import { RejectAssetBuy } from '../../../model/api/assets/reject-asset-buy';
import { UpdateServiceDate } from '../../../model/common/update-service-date';
import { UpdateServiceDamage } from '../../../model/common/update-service-damage';
import { UpdateServiceRepairedEmployee } from '../../../model/common/update-service-repaired-employee';
import { UpdateServiceRepairedIT } from '../../../model/common/update-service-repaired-it';
import { MarketPlace } from '../../../model/api/assets/market-place';
import { AssetBuyMarket } from '../../../model/api/assets/asset-buy-market';
import { RejectMarketPlace } from '../../../model/api/assets/reject-asset-market-place';
import { UpdateTempRepairedIT } from '../../../model/common/update-temp-repaired-it';
import { UpdateTempEmployee } from '../../../model/common/update-temp-employee';
@Injectable()
export class AssetHttpService extends GenericHttpService<any, number> {
    constructor(public http: HttpClient, public notify: NotifyService) {
        super(http, '', 'assets');
    }

    getDetailById(id: number): Observable<any> {
        return this.http.get(AppConfig.urlPrefix + this.url + `/detail/${id}`).pipe(
            map((data: Response) => {
                // console.log(JSON.stringify(data));
                return data;
            }));
    }

    addNewAsset(item: AssetSave): Observable<any> {
        const url: string = AppConfig.urlPrefix + this.url + '/detail';

        return this.http.post(url, item, { headers: this.headers }).pipe(
            map((data) => {
                return data;
            }));
    }

    buyAsset(item: AssetBuy): Observable<BuyResult> {
      const url: string = AppConfig.urlPrefix + this.url + '/buy';
      return this.http.post(url, item, { headers: this.headers }).pipe(
          map((data: BuyResult) => {
              return data;
          }));
  }

  buyMarketPlace(item: AssetBuyMarket): Observable<BuyResult> {
    const url: string = AppConfig.urlPrefix + this.url + '/buyMarket';
    return this.http.post(url, item, { headers: this.headers }).pipe(
        map((data: BuyResult) => {
            return data;
        }));
}

  marketPlace(item: MarketPlace): Observable<BuyResult> {
    const url: string = AppConfig.urlPrefix + this.url + '/marketPlace';
    return this.http.post(url, item, { headers: this.headers }).pipe(
        map((data: BuyResult) => {
            return data;
        }));
}

  validateBuyAsset(item: ValidateAssetITBuy): Observable<BuyResult> {
    const url: string = AppConfig.urlPrefix + this.url + '/validatebuy';
    return this.http.post(url, item, { headers: this.headers }).pipe(
        map((data: BuyResult) => {
            return data;
        }));
}

validateFinalBuyAsset(item: ValidateAssetBuy): Observable<BuyResult> {
  const url: string = AppConfig.urlPrefix + this.url + '/validateFinalbuy';
  return this.http.post(url, item, { headers: this.headers }).pipe(
      map((data: BuyResult) => {
          return data;
      }));
}

validateBuyGNFRAsset(item: ValidateAssetBuy): Observable<BuyResult> {
  const url: string = AppConfig.urlPrefix + this.url + '/validatebuygnfr';
  return this.http.post(url, item, { headers: this.headers }).pipe(
      map((data: BuyResult) => {
          return data;
      }));
}

validateBuyCFOAsset(item: ValidateAssetBuy): Observable<BuyResult> {
  const url: string = AppConfig.urlPrefix + this.url + '/validatebuycfo';
  return this.http.post(url, item, { headers: this.headers }).pipe(
      map((data: BuyResult) => {
          return data;
      }));
}

changeBuyCFOAsset(item: ValidateAssetBuy): Observable<BuyResult> {
  const url: string = AppConfig.urlPrefix + this.url + '/changegbuycfo';
  return this.http.post(url, item, { headers: this.headers }).pipe(
      map((data: BuyResult) => {
          return data;
      }));
}

validateBuyExtraseAsset(item: ValidateAssetBuy): Observable<BuyResult> {
  const url: string = AppConfig.urlPrefix + this.url + '/validateextrasebuy';
  return this.http.post(url, item, { headers: this.headers }).pipe(
      map((data: BuyResult) => {
          return data;
      }));
}

validateBuyITDeleteDataAsset(item: ValidateAssetBuy): Observable<BuyResult> {
  const url: string = AppConfig.urlPrefix + this.url + '/validateitdeletedatabuy';
  return this.http.post(url, item, { headers: this.headers }).pipe(
      map((data: BuyResult) => {
          return data;
      }));
}

rejectBuyAsset(item: RejectAssetBuy): Observable<BuyResult> {
  const url: string = AppConfig.urlPrefix + this.url + '/rejectbuy';
  return this.http.post(url, item, { headers: this.headers }).pipe(
      map((data: BuyResult) => {
          return data;
      }));
}

rejectMarketPlace(item: RejectMarketPlace): Observable<BuyResult> {
  const url: string = AppConfig.urlPrefix + this.url + '/rejectmarketplace';
  return this.http.post(url, item, { headers: this.headers }).pipe(
      map((data: BuyResult) => {
          return data;
      }));
}

imfoEmployeeAsset(item: InfoEmployeeSave): Observable<BuyResult> {
  const url: string = AppConfig.urlPrefix + this.url + '/infoEmployee';
  return this.http.post(url, item, { headers: this.headers }).pipe(
      map((data: BuyResult) => {
          return data;
      }));
}

rejectBuyGNFRAsset(item: ValidateAssetBuy): Observable<BuyResult> {
  const url: string = AppConfig.urlPrefix + this.url + '/rejectbuygnfr';
  return this.http.post(url, item, { headers: this.headers }).pipe(
      map((data: BuyResult) => {
          return data;
      }));
}

rejectBuyCFOAsset(item: ValidateAssetBuy): Observable<BuyResult> {
  const url: string = AppConfig.urlPrefix + this.url + '/rejectbuycfo';
  return this.http.post(url, item, { headers: this.headers }).pipe(
      map((data: BuyResult) => {
          return data;
      }));
}

rejectExtraseBuyAsset(item: ValidateAssetBuy): Observable<BuyResult> {
  const url: string = AppConfig.urlPrefix + this.url + '/rejectbuyextrase';
  return this.http.post(url, item, { headers: this.headers }).pipe(
      map((data: BuyResult) => {
          return data;
      }));
}

rejectBuyITDeleteDataAsset(item: ValidateAssetBuy): Observable<BuyResult> {
  const url: string = AppConfig.urlPrefix + this.url + '/rejectbuyitdeletedata';
  return this.http.post(url, item, { headers: this.headers }).pipe(
      map((data: BuyResult) => {
          return data;
      }));
}


sendToGNFRBuyAsset(item: ValidateAssetBuy): Observable<BuyResult> {
  const url: string = AppConfig.urlPrefix + this.url + '/sendToGNFRbuy';
  return this.http.post(url, item, { headers: this.headers }).pipe(
      map((data: BuyResult) => {
          return data;
      }));
}

validateAccountingBuyAsset(item: ValidateAssetBuy): Observable<BuyResult> {
  const url: string = AppConfig.urlPrefix + this.url + '/validateaccountingbuy';
  return this.http.post(url, item, { headers: this.headers }).pipe(
      map((data: BuyResult) => {
          return data;
      }));
}

rejectAccountingBuyAsset(item: ValidateAssetBuy): Observable<BuyResult> {
  const url: string = AppConfig.urlPrefix + this.url + '/rejectaccountingbuy';
  return this.http.post(url, item, { headers: this.headers }).pipe(
      map((data: BuyResult) => {
          return data;
      }));
}

    cloneAsset(item: AssetClone): Observable<any> {
        const url: string = AppConfig.urlPrefix + this.url + '/clone';

        return this.http.post(url, item, { headers: this.headers }).pipe(
            map((data) => {
                return data;
            }));
    }

    addAsset(item: AddAsset): Observable<CreateAssetSAPResult> {

        const url: string = AppConfig.urlPrefix + this.url + '/add';

        return this.http.post(url, item, { headers: this.headers }).pipe(
            map((data: CreateAssetSAPResult) => {
                return data;
            }));
    }

    addAssetInvPlus(item: AddAssetInvPlus): Observable<CreateAssetSAPResult> {

        const url: string = AppConfig.urlPrefix + this.url + '/addAssetInvPlus';

        return this.http.post(url, item, { headers: this.headers }).pipe(
            map((data: CreateAssetSAPResult) => {
                return data;
            }));
    }

    addAssetStock(item: AddStockAsset): Observable<CreateAssetSAPResult> {
        const url: string = AppConfig.urlPrefix + this.url + '/addStock';

        return this.http.post(url, item, { headers: this.headers }).pipe(
            map((data: CreateAssetSAPResult) => {
                return data;
            }));
    }

    updateAsset(item: AssetSave): Observable<CreateAssetSAPResult> {
      const url: string = AppConfig.urlPrefix + this.url + '/detail';
      return this.http.post(url, item, { headers: this.headers }).pipe(
          map((data: CreateAssetSAPResult) => {
              return data;
          }));
  }

    updateAcquisition(item: AssetAcquizition): Observable<any> {
        const url: string = AppConfig.urlPrefix + this.url + '/updateAcquisition';
        return this.http.put(url, item, { headers: this.headers });
    }


    updateAssetChange(item: AssetEditChange): Observable<CreateAssetSAPResult> {
        const url: string = AppConfig.urlPrefix + this.url + '/updateAssetChange';
        return this.http.post(url, item, { headers: this.headers }).pipe(
            map((data: CreateAssetSAPResult) => {
                return data;
            }));
    }


    validateAsset(item: AssetChange): Observable<any> {
        const url: string = AppConfig.urlPrefix + this.url + '/validate';
        return this.http.post(url, item, { headers: this.headers });
    }

    acquizitionAsset(item: AssetAcquizition): Observable<CreateAssetSAPResult> {
        const url: string = AppConfig.urlPrefix + this.url + '/acquisition';
        return this.http.post(url, item, { headers: this.headers }).pipe(
            map((data: CreateAssetSAPResult) => {
                return data;
            }));
    }

    updateAssetInvPlus(item: UpdateAssetInvPlus): Observable<CreateAssetSAPResult> {
        const url: string = AppConfig.urlPrefix + this.url + '/updateassetInvPlus';
        return this.http.post(url, item, { headers: this.headers }).pipe(
            map((data: CreateAssetSAPResult) => {
                return data;
            }));
    }

    newTransferAsset(item: SaveAssetTransfer): Observable<CreateAssetSAPResult> {
        const url: string = AppConfig.urlPrefix + this.url + '/newTransferAsset';
        return this.http.post(url, item, { headers: this.headers }).pipe(
            map((data: CreateAssetSAPResult) => {
                return data;
            }));
    }

    newTransferCloneAsset(item: SaveAssetCloneTransfer): Observable<CreateAssetSAPResult> {
        const url: string = AppConfig.urlPrefix + this.url + '/newTransferCloneAsset';
        return this.http.post(url, item, { headers: this.headers }).pipe(
            map((data: CreateAssetSAPResult) => {
                return data;
            }));
    }

    newRetireAsset(item: SaveRetireAsset): Observable<CreateAssetSAPResult> {
        const url: string = AppConfig.urlPrefix + this.url + '/newRetireAsset';
        return this.http.post(url, item, { headers: this.headers }).pipe(
            map((data: CreateAssetSAPResult) => {
                return data;
            }));
    }

    newStornoAsset(item: SaveStornoAsset): Observable<CreateAssetSAPResult> {
        const url: string = AppConfig.urlPrefix + this.url + '/newStornoAsset';
        return this.http.post(url, item, { headers: this.headers }).pipe(
            map((data: CreateAssetSAPResult) => {
                return data;
            }));
    }

    newAssetAcquisitionStorno(item: SaveAssetAcquisitionStorno): Observable<CreateAssetSAPResult> {
        const url: string = AppConfig.urlPrefix + this.url + '/newAssetAcquisitionStorno';
        return this.http.post(url, item, { headers: this.headers }).pipe(
            map((data: CreateAssetSAPResult) => {
                return data;
            }));
    }

    newAssetInvMinus(item: SaveAssetInvMinus): Observable<any> {
        const url: string = AppConfig.urlPrefix + this.url + '/newAssetInvMinus';
        return this.http.post(url, item, { headers: this.headers }).pipe(
            map((data) => {
                return data;
            }));
    }

    newAssetInvPlus(item: SaveAssetInvPlus): Observable<any> {
        const url: string = AppConfig.urlPrefix + this.url + '/newAssetInvPlus';
        return this.http.post(url, item, { headers: this.headers }).pipe(
            map((data) => {
                return data;
            }));
    }

    public uploadV1(item: AssetImportV1): Observable<CreateAssetSAPResult> {
        //console.log(JSON.stringify(item));
        return this.http.post(AppConfig.urlPrefix + this.url + '/importv1',
            item, { headers: this.headers }).pipe(
            map((data: CreateAssetSAPResult) => {
                return data;
            }));
    }
    public uploadV2(item: AssetImportV2): Observable<any> {
        return this.http.post(AppConfig.urlPrefix + this.url + '/importv2',
            item, { headers: this.headers }).pipe(
            map((data) => {
                return data;
            }));
    }

    public uploadIT(item: AssetImportIT): Observable<any> {
      return this.http.post(AppConfig.urlPrefix + this.url + '/importit',
          item, { headers: this.headers }).pipe(
          map((data) => {
              return data;
          }));
  }

    public uploadThales(item: ImportThales): Observable<any> {
        return this.http.post(AppConfig.urlPrefix + this.url + '/importthales',
            JSON.stringify(item)).pipe(
            map((data) => {
                return data;
            }));
    }

    public uploadITThales(item: ImportITThales): Observable<any> {
        return this.http.post(AppConfig.urlPrefix + this.url + '/importitthales',
            JSON.stringify(item)).pipe(
            map((data) => {
                return data;
            }));
    }

  public deleteAssetOp(assetId: number, inventoryId: number ): Observable<any> {
    console.log(AppConfig.urlPrefix + this.url + `/${assetId}`, inventoryId);
    return this.http.delete(AppConfig.urlPrefix + this.url + '/deleteAssetOp' + `/${assetId}, ${inventoryId}`);
}

public exportIn(params: Array<Param>) {
    let searchParams: HttpParams = null;
    let url = AppConfig.urlPrefix + this.url + '/exportIn';

    searchParams = this.getSearchParams(params);
    return this.http.get(url, { params: searchParams, observe: 'response', responseType: 'blob' }).pipe(
                    map(res => res));
}

public exportOtp(params: Array<Param>) {
    let searchParams: HttpParams = null;
    let url = AppConfig.urlPrefix + this.url + '/exportOtp';

    searchParams = this.getSearchParams(params);
    return this.http.get(url, { params: searchParams, observe: 'response', responseType: 'blob' }).pipe(
                    map(res => res));
}

public exportOut(params: Array<Param>) {
    let searchParams: HttpParams = null;
    let url = AppConfig.urlPrefix + this.url + '/exportOut';

    searchParams = this.getSearchParams(params);
    return this.http.get(url, { params: searchParams, observe: 'response', responseType: 'blob' }).pipe(
                    map(res => res));
}

public import(file: any) {
    const formData = new FormData();
    formData.append('file', file);

    return this.http.post<any>(AppConfig.urlPrefix + this.url + '/upload', formData);
}

public importCassation(file: any) {
    // let input = new FormData();
    // input.append('file', file);
    // this.progressBarService.startTracking().subscribe(progress => console.log(progress));
    // return this.http
    //     .post(AppConfig.urlPrefix + this.url + '/uploadCassation', input);
}

public importInventory(file: any) {
    // let input = new FormData();
    // input.append('file', file);
    // this.progressBarService.startTracking().subscribe(progress => console.log(progress));
    // return this.http
    //     .post(AppConfig.urlPrefix + this.url + '/uploadInv', input);
}

public exportApaNova(inventoryId: number, locationId: number) {
    let searchParams: HttpParams = null;
 //   let url = AppConfig.urlPrefix + 'reporting/exportMegaImage&inventoryId=${inventoryId}&locationId=${locationId}';
    let url = `${AppConfig.urlPrefix}reporting/locationdownload/${locationId}`;

    searchParams = this.getSearchParams(null);
    return this.http.get(url, { params: searchParams, observe: 'response', responseType: 'blob' }).pipe(
                    map(res => res));
}

public deleteAsset(assetId: number): Observable<any> {
    return this.http.post(AppConfig.urlPrefix + this.url + '/deleteAsset', assetId, { headers: this.headers }).pipe(
    map((data: any) => {
        return data;
    }));
}


public blockAsset(assetId: number): Observable<any> {
    return this.http.post(AppConfig.urlPrefix + this.url + '/blockAsset', assetId, { headers: this.headers }).pipe(
    map((data: any) => {
        return data;
    }));
}


public checkUniqueAsset(invNo: string) {
    return this.http.get(AppConfig.urlPrefix + this.url + '/checkUnique' + `/${invNo}`, { headers: this.headers }).pipe(
    map((data: Response) => {
        return data;
    }));
}

public checkUniqueSerialNumber(serialNumber: string) {
    return this.http.get(AppConfig.urlPrefix + this.url + '/checkUniqueSerialNumber' + `/${serialNumber}`, { headers: this.headers }).pipe(
    map((data: number) => {
        return data;
    }));
}

public checkEditUniqueSerialNumber(assetId: number, serialNumber: string) {
  return this.http.get(AppConfig.urlPrefix + this.url + '/checkUniqueEditSerialNumber'+ `/${assetId}` + `/${serialNumber}`, { headers: this.headers }).pipe(
  map((data: number) => {
      return data;
  }));
}


public checkUniqueDocument(document: string) {
    return this.http.get(AppConfig.urlPrefix + this.url + '/checkUniqueDocument' + `/${document}`, { headers: this.headers }).pipe(
    map((data: number) => {
        return data;
    }));
}

public checkUniqueDocumentAndPartner(document: string, partnerId: number, budgetManagerId: number) {
    return this.http.get(AppConfig.urlPrefix + this.url + '/checkUniqueDocumentByPartnerAndYear' + `/${document}` + `/${partnerId}` + `/${budgetManagerId}`, { headers: this.headers }).pipe(
    map((data: number) => {
        return data;
    }));
}


// public export(params: Array<Param>) {
//     let searchParams: HttpParams = null;
//     let url = AppConfig.urlPrefix + this.url + '/export';

//     searchParams = this.getSearchParams(params);
//     return this.http.get(url, { params: searchParams, observe: 'response', responseType: 'blob' }).pipe(
//                     map(res => res))
// }

public exportThales(params: Array<Param>) {
    let searchParams: HttpParams = null;
    let url = AppConfig.urlPrefix + this.url + '/exportThales';

    searchParams = this.getSearchParams(params);
    return this.http.get(url, { params: searchParams, observe: 'response', responseType: 'blob' }).pipe(
                    map(res => res))
}



public saveReco(reco: AssetTempRecoSave): Observable<any> {
    // return super.create(reco, 'reco')
    return this.http.post(AppConfig.urlPrefix + this.url + '/reco', reco, { headers: this.headers }).pipe(
    map((data: Response) => {
        return data;
    }));
}

public recoverAssetTemp(assetId: number, inventoryId: number): Observable<any> {
    return this.http.put(AppConfig.urlPrefix + this.url + '/recoverAssetTemp' +
     `/${assetId}/${inventoryId}`, {}).pipe(
     map((data: Response) => {
        return data;
    }));
}

public updatePrintDateLabel(assets: Array<any>) {
    return this.http
        .post(AppConfig.urlPrefix + this.url + '/uploadPrintDate', assets).pipe(
        map((data: any) => {
            return data;
        }));
}

public updateServiceDate(assets: Array<UpdateServiceDate>): Observable<CreateAssetSAPResult> {
    return this.http
        .post(AppConfig.urlPrefix + this.url + '/uploadServiceDate', assets).pipe(
        map((data: any) => {
            return data;
        }));
}

public updateServiceDamage(assets: Array<UpdateServiceDamage>): Observable<CreateAssetSAPResult> {
    return this.http
        .post(AppConfig.urlPrefix + this.url + '/uploadServiceDamage', assets).pipe(
        map((data: any) => {
            return data;
        }));
}

public updateDamage(assets: Array<UpdateServiceDamage>): Observable<CreateAssetSAPResult> {
  return this.http
      .post(AppConfig.urlPrefix + this.url + '/uploadDamage', assets).pipe(
      map((data: any) => {
          return data;
      }));
}

public updateServiceRepairedIT(assets: Array<UpdateServiceRepairedIT>): Observable<CreateAssetSAPResult> {
  return this.http
      .post(AppConfig.urlPrefix + this.url + '/uploadServiceRepairedIT', assets).pipe(
      map((data: any) => {
          return data;
      }));
}

public updateTempRepairedIT(assets: Array<UpdateTempRepairedIT>): Observable<CreateAssetSAPResult> {
  return this.http
      .post(AppConfig.urlPrefix + this.url + '/uploadTempRepairedIT', assets).pipe(
      map((data: any) => {
          return data;
      }));
}

public updateServiceRepairedEmployee(assets: Array<UpdateServiceRepairedEmployee>): Observable<CreateAssetSAPResult> {
  return this.http
      .post(AppConfig.urlPrefix + this.url + '/uploadServiceRepairedEmployee', assets).pipe(
      map((data: any) => {
          return data;
      }));
}

public updateTempEmployee(assets: Array<UpdateTempEmployee>): Observable<CreateAssetSAPResult> {
  return this.http
      .post(AppConfig.urlPrefix + this.url + '/uploadTempEmployee', assets).pipe(
      map((data: any) => {
          return data;
      }));
}

public updateAssetName(assets: Array<any>) {
    return this.http
        .post(AppConfig.urlPrefix + this.url + '/updateAssetName', assets).pipe(
    map(res => res));
}

public employeeValidate(reco: Array<EmployeeValidate>): Observable<any> {
    // return super.create(reco, 'reco')
    return this.http.post(AppConfig.urlPrefix + this.url + '/employeevalidate', JSON.stringify(reco), { headers: this.headers }).pipe(
    map((data: Response) => {
        return data;
    }));
}

public employeeValidateAll(guid: string): Observable<any> {
    // return super.create(reco, 'reco')
    return this.http.post(AppConfig.urlPrefix + this.url + '/employeevalidateall/' + guid, { headers: this.headers }).pipe(
    map((data: Response) => {
        return data;
    }));
}

public exportAll(params: Array<Param>) {
    let searchParams: HttpParams = null;
    let url = AppConfig.urlPrefix + this.url + '/exportAll';
    searchParams = this.getSearchParams(params);
    return this.http.get(url, { params: searchParams, observe: 'response', responseType: 'blob' }).pipe(
                    map(res => res))
}


public addAssetByEmployee(reco: AssetTransferAdd): Observable<any> {
    // return super.create(reco, 'reco')
    return this.http.post(AppConfig.urlPrefix + this.url + '/addTransfer', JSON.stringify(reco), { headers: this.headers }).pipe(
    map((data: Response) => {
        return data;
    }));
}


public exportSocGenEmail(params: Array<Param>) {
    let searchParams: HttpParams = null;
    let url = AppConfig.urlPrefix + this.url + '/exportSocGenEmail';

    searchParams = this.getSearchParams(params);
    return this.http.get(url, { params: searchParams, observe: 'response', responseType: 'blob' }).pipe(
                    map(res => res));
}

public exportInventoryEmag(params: Array<Param>) {
    let searchParams: HttpParams = null;
    let url = AppConfig.urlPrefix + this.url + '/exportInventoryEmag';

    searchParams = this.getSearchParams(params);
    return this.http.get(url, { params: searchParams, observe: 'response', responseType: 'blob' }).pipe(
                    map(res => res));
}

// public printLocalLabel(item: Array<PrintLabel>): Observable<any> {
//     return this.http.post(AppConfig.urlPrefixPrint + 'printing',
//         JSON.stringify(item), { headers: this.headers }).pipe(
//         map((data) => {
//             return data;
//         }));
// }

public total(inventoryId: number): Observable<any> {
    return this.http.get(AppConfig.urlPrefix + this.url + '/total' + `/${inventoryId}`, { headers: this.headers }).pipe(
    map((data: Response) => {
        return data;
    }));
}

public auditLocationChart(locationId: number, inventoryId: number): Observable<any> {
    return this.http.get(AppConfig.urlPrefix + this.url + '/auditLocationChart' + `/${locationId}/${inventoryId}`, { headers: this.headers }).pipe(
    map((data: Response) => {
        return data;
    }));
}

public auditRegionChart(regionId: number, inventoryId: number): Observable<any> {
    return this.http.get(AppConfig.urlPrefix + this.url + '/auditRegionChart' + `/${regionId}/${inventoryId}`, { headers: this.headers }).pipe(
    map((data: Response) => {
        return data;
    }));
}

public inventoryPieChartByDay(inventoryId: number): Observable<any> {
    return this.http.get(AppConfig.urlPrefix + this.url + '/inventoryPieChartByDay' + `/${inventoryId}`, { headers: this.headers }).pipe(
    map((data: Response) => {
        return data;
    }));
}

public inventoryPieChartLocationFinishedByDay(inventoryId: number): Observable<any> {
    return this.http.get(AppConfig.urlPrefix + this.url + '/inventoryPieChartLocationFinishedByDay' + `/${inventoryId}`, { headers: this.headers }).pipe(
    map((data: Response) => {
        return data;
    }));
}

public inventoryPieChartUserScans(inventoryId: number): Observable<any> {
    return this.http.get(AppConfig.urlPrefix + this.url + '/inventoryPieChartUserScans' + `/${inventoryId}`, { headers: this.headers }).pipe(
    map((data: Response) => {
        return data;
    }));
}

public region(inventoryId: number): Observable<any> {
    return this.http.get(AppConfig.urlPrefix + this.url + '/region' + `/${inventoryId}`, { headers: this.headers }).pipe(
    map((data: Response) => {
        return data;
    }));
}

public location(regionId: number, inventoryId: number): Observable<any> {
    return this.http.get(AppConfig.urlPrefix + this.url + '/location'  + `/${regionId}/${inventoryId}`, { headers: this.headers }).pipe(
    map((data: Response) => {
        return data;
    }));
}

public mapAssetTemp(model: any): Observable<any> {

    let body: string = JSON.stringify(model);

    return this.http.post(AppConfig.urlPrefix + this.url + '/mapAssetTemp', body, { headers: this.headers }).pipe(
        map((res: Response) => {
            return res;
        }));
}

public printLocalLabel(item: Array<PrintLabel>): Observable<boolean> {
    return this.http.post(AppConfig.urlPrefixPrint + 'printing',
        item, { headers: this.headers }).pipe(
        map((data: boolean) => {
            return data;
        }));
}

public export(params: Array<Param>) {
    let searchParams: HttpParams = null;
    let url = AppConfig.urlPrefix + this.url + '/exportAsset';

    searchParams = this.getSearchParams(params);
    return this.http.get(url, { params: searchParams, observe: 'response', responseType: 'blob' }).pipe(
                    map(res => res));
}

public exportAsset(params: Array<Param>) {
  let searchParams: HttpParams = null;
  let url = AppConfig.urlPrefix + this.url + '/exportAsset';

  searchParams = this.getSearchParams(params);
  return this.http.get(url, { params: searchParams, observe: 'response', responseType: 'blob' }).pipe(
                  map(res => res));
}


public exportAssetAll(params: Array<Param>) {
  let searchParams: HttpParams = null;
  let url = AppConfig.urlPrefix + this.url + '/exportAssetAll';

  searchParams = this.getSearchParams(params);
  return this.http.get(url, { params: searchParams, observe: 'response', responseType: 'blob' }).pipe(
                  map(res => res));
}

public exportAssetManager(params: Array<Param>) {
  let searchParams: HttpParams = null;
  let url = AppConfig.urlPrefix + this.url + '/exportAssetManager';

  searchParams = this.getSearchParams(params);
  return this.http.get(url, { params: searchParams, observe: 'response', responseType: 'blob' }).pipe(
                  map(res => res));
}


public exportAssetNonCharisma(params: Array<Param>) {
  let searchParams: HttpParams = null;
  let url = AppConfig.urlPrefix + this.url + '/exportAssetNonCharisma';

  searchParams = this.getSearchParams(params);
  return this.http.get(url, { params: searchParams, observe: 'response', responseType: 'blob' }).pipe(
                  map(res => res));
}


public exportAssetEmployee(params: Array<Param>) {
  let searchParams: HttpParams = null;
  let url = AppConfig.urlPrefix + this.url + '/exportAssetEmployee';

  searchParams = this.getSearchParams(params);
  return this.http.get(url, { params: searchParams, observe: 'response', responseType: 'blob' }).pipe(
                  map(res => res));
}

public exportAccounting(params: Array<Param>) {
    let searchParams: HttpParams = null;
    let url = AppConfig.urlPrefix + this.url + '/exportAccounting';

    searchParams = this.getSearchParams(params);
    return this.http.get(url, { params: searchParams, observe: 'response', responseType: 'blob' }).pipe(
                    map(res => res));
}

public exportStockIT(params: Array<Param>) {
    let searchParams: HttpParams = null;
    const url = AppConfig.urlPrefix + this.url + '/exportStockIT';

    searchParams = this.getSearchParams(params);
    return this.http.get(url, { params: searchParams, observe: 'response', responseType: 'blob' }).pipe(
                    map(res => res));
}

public exportWFH(params: Array<Param>) {
    let searchParams: HttpParams = null;
    let url = AppConfig.urlPrefix + this.url + '/exportWFH';

    searchParams = this.getSearchParams(params);
    return this.http.get(url, { params: searchParams, observe: 'response', responseType: 'blob' }).pipe(
                    map(res => res));
}

public createAsset(): Observable<any> {
    return this.http.post(AppConfig.urlPrefix + this.url + '/createAsset', { headers: this.headers }).pipe(
        map((data) => {
            return data;
        }));
}

public getStockByCategoryID(categoryID: string): Observable<any> {
    return this.http.get(AppConfig.urlPrefix + this.url + '/getStockByCategoryID' + `/${categoryID}`, { headers: this.headers }).pipe(
    map((data: Response) => {
        return data;
    }));
  }



public rejectAsset(assetId: number, reason: string): Observable<any> {
    return this.http.post(AppConfig.urlPrefix + this.url + '/rejectAsset' + `/${assetId}/${reason}`, { headers: this.headers }).pipe(
        map((data) => {
            return data;
        }));
}

addNewAssetEmployee(item: AssetEmployeeSave): Observable<number> {
    const url: string = AppConfig.urlPrefix + this.url + '/employeedetail';

    return this.http.post(url, item, { headers: this.headers }).pipe(
        map((data: number) => {
            return data;
        }));
}

updateAssetEmployee(item: AssetEmployeeSave): Observable<number> {
    const url: string = AppConfig.urlPrefix + this.url + '/employeedetail';
    return this.http.put(url,item, { headers: this.headers }).pipe(
    map((data: number) => {
        return data;
    }));
}

public deleteAssetValidation(assetId: number): Observable<any> {
    return this.http.post(AppConfig.urlPrefix + this.url + '/deleteValidationAsset' + `/${assetId}`, { headers: this.headers });
}

deleteOrderItem(assetId: number): Observable<AssetResult> {
    const url: string = AppConfig.urlPrefix + this.url + '/deleteOrderItem';
    return this.http.post(url, assetId, { headers: this.headers }).pipe(
        map((data: AssetResult) => {
            return data;
        }));
}

public exportTemplateIT() {
  let searchParams: HttpParams = null;
  let url = AppConfig.urlPrefix + this.url + '/exportTemplateIT';
  return this.http.get(url, { params: searchParams, observe: 'response', responseType: 'blob' }).pipe(
                  map(res => res));
}

public exportTemplateSuperUser() {
  let searchParams: HttpParams = null;
  let url = AppConfig.urlPrefix + this.url + '/exportTemplateSuperUser';
  return this.http.get(url, { params: searchParams, observe: 'response', responseType: 'blob' }).pipe(
                  map(res => res));
}

public exportTemplateCorrectionSuperUser() {
  let searchParams: HttpParams = null;
  let url = AppConfig.urlPrefix + this.url + '/exportTemplateCorrectionSuperUser';
  return this.http.get(url, { params: searchParams, observe: 'response', responseType: 'blob' }).pipe(
                  map(res => res));
}

deleteErrorAssets(item: number[]): Observable<BuyResult> {
  const url: string = AppConfig.urlPrefix + this.url + '/deleteErrorAssets';
  return this.http.post(url, item, { headers: this.headers }).pipe(
      map((data: BuyResult) => {
          return data;
      }));
}

}
