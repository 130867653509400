
import { Component } from '@angular/core';
import { AppData } from '../../../app-data';
import { Accountancy } from '../../../model/api/administration/accountancy';
import { Param } from '../../../model/common/param';
import { GenericTableList } from '../../generic/generic.table.list';
@Component({
    selector: 'app-accountancy-list',
    templateUrl: '../../generic/generic.table.list.html'
})
export class AccountancyListComponent extends GenericTableList<Accountancy, number> {
    private listName: string = 'ACCOUNTANCIES';

    constructor() {
        super('name', 'asc', '');
        this.columns = AppData.ColumnDefinitions[this.listName];
    }

    public refresh(filters: Array<Param>) {
        this.columns = AppData.ColumnDefinitions[this.listName];
        super.refresh(filters);
    }
}