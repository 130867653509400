import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppConfig } from '../../../config';
import { EntityFile } from '../../../model/api/common/entity-file';
import { Param } from '../../../model/common/param';

import { GenericHttpService } from '../generic.http.service';
// import { AuthHttp } from 'angular2-jwt';
import { EntityFileResult } from '../../../model/api/result/entity-file-result';

@Injectable()
export class EntityFileHttpService extends GenericHttpService<EntityFile, number> {
    constructor(public http: HttpClient) {
        super(http, '', 'entityfiles');
    }

    public download(entityFileId: number) {
        let url = AppConfig.urlPrefix + this.url + '/download' + `/${entityFileId}`;

        // return this.http.get(url, { responseType: ResponseContentType.ArrayBuffer })
        //                 .map(res => res.arrayBuffer());
        return this.http.get(url, { responseType: 'blob' }).pipe(
                        map(res => res));
    }

    public downloadValidateAsset(entityFileId: number) {
        const url = AppConfig.urlPrefix + this.url + '/downloadValidateAsset' + `/${entityFileId}`;
        return this.http.get(url, { responseType: 'blob' }).pipe(
                        map(res => res));
    }

    public upload(fileToUpload: any, entityId: number, entityTypeCode: string, info: string) {
        const input = new FormData();
        input.append('file', fileToUpload.data);
        input.append('entityId', entityId.toString());
        input.append('entityTypeCode', entityTypeCode);
        input.append('info', info);

        return this.http
            .post(AppConfig.urlPrefix + this.url + '/upload', input);
    }

    public uploadReception(fileToUpload: any, entityId: number, entityTypeId: number, info: string) {
        const input = new FormData();
        input.append('file', fileToUpload.data);
        input.append('entityId', entityId.toString());
        input.append('entityTypeId', entityTypeId.toString());
        input.append('info', info);

        return this.http
            .post(AppConfig.urlPrefix + this.url + '/uploadReception', input);
    }

    public uploadRequest(fileToUpload: any, entityId: number, entityTypeId: number, info: string) {
        const input = new FormData();
        input.append('file', fileToUpload.data);
        input.append('entityId', entityId.toString());
        input.append('entityTypeId', entityTypeId.toString());
        input.append('info', info);

        return this.http
            .post(AppConfig.urlPrefix + this.url + '/uploadRequest', input);
    }

    public uploadMarketPlacePhoto(fileToUpload: any, entityId: number, entityTypeId: number, info: string) {
      const input = new FormData();
      input.append('file', fileToUpload.data);
      input.append('entityId', entityId.toString());
      input.append('entityTypeId', entityTypeId.toString());
      input.append('info', info);

      return this.http
          .post(AppConfig.urlPrefix + this.url + '/uploadMarketPlacePhoto', input);
  }

    public uploadBuy(fileToUpload: any, entityId: number, entityTypeId: number, info: string, count: number): Observable<EntityFileResult> {
      const input = new FormData();
      input.append('file', fileToUpload.data);
      input.append('entityId', entityId.toString());
      input.append('entityTypeId', entityTypeId.toString());
      input.append('info', info);
      input.append('count', count.toString());

      return this.http
          .post(AppConfig.urlPrefix + this.url + '/uploadbuy', input).pipe(
            map((data: EntityFileResult) => {
                return data;
            }));
  }

  public uploadMarketBuy(fileToUpload: any, entityId: number, entityTypeId: number, info: string, count: number) {
    const input = new FormData();
    input.append('file', fileToUpload.data);
    input.append('entityId', entityId.toString());
    input.append('entityTypeId', entityTypeId.toString());
    input.append('info', info);
    input.append('count', count.toString());

    return this.http
        .post(AppConfig.urlPrefix + this.url + '/uploadMarketBuy', input);
}

    public uploadOrder(fileToUpload: any, entityId: number, entityTypeId: number, info: string) {
        const input = new FormData();
        input.append('file', fileToUpload.data);
        input.append('entityId', entityId.toString());
        input.append('entityTypeId', entityTypeId.toString());
        input.append('info', info);

        return this.http
            .post(AppConfig.urlPrefix + this.url + '/uploadOrder', input);
    }

    public addAssetFiles(fileToUpload: Array<any>, guid: string, entityTypeCode: string) {
        const input = new FormData();

        for (const file of fileToUpload) {
                input.append('File', file);
            }
        input.append('guid', guid);
        input.append('entityTypeCode', entityTypeCode);

        return this.http
            .post(AppConfig.urlPrefix + this.url + '/uploadNewAsset', input);
    }

    public addOfferFiles(fileToUpload: Array<any>, guid: string, entityTypeCode: string, partnerId: number) {
        const input = new FormData();

        for (const file of fileToUpload) {
            input.append('File', file);
        }
            input.append('guid', guid);
            input.append('entityTypeCode', entityTypeCode);
            input.append('partnerId', partnerId.toString());

        return this.http
            .post(AppConfig.urlPrefix + this.url + '/uploadNewOffer', input);
    }

    // public getByEntity(entityTypeCode: string, entityId: number): Observable<EntityFile> {
    //     let params: Array<Param> = new Array<Param>();

    //     params.push(new Param('entityTypeCode', entityTypeCode));
    //     params.push(new Param('entityId', entityId.toString()));

    //     return this.get(null, null, '', '', params, null).map((data: any) => {
    //             //return data != null ? data.json() : null;
    //             return data;
    //         });
    // }

    public getByEntity(entityTypeCode: string, entityId: number): Observable<Array<EntityFile>> {
        let params: Array<Param> = new Array<Param>();

        params.push(new Param('entityTypeCode', entityTypeCode));
        params.push(new Param('entityId', entityId.toString()));

        return this.get(null, null, null, null, params, null).pipe(map((data: any) => {
                return data;
            }));
    }

    public deletePhoto(id: number): Observable<any> {
        return this.http.delete(AppConfig.urlPrefix + this.url + '/deletePhoto' + `/${id}`);
    }

    public downloadPdf(entityFileId: number): Observable<Blob> {
        const url = AppConfig.urlPrefix + this.url + '/downloadpdf' + `/${entityFileId}`;

        return this.http.get(url, { observe: 'response', responseType: 'blob' }).pipe(
                        map(res => res.body));
    }

    public deleteAccoutingMFEntityFile(entityFileId: number): Observable<EntityFileResult> {
      return this.http.post(AppConfig.urlPrefix + this.url + '/deleteAccountingMF' + `/${entityFileId}`, []).pipe(
      map((data: EntityFileResult) => {
          return data;
      }));
  }


  public deleteMarketPlacePhoto(entityFileId: number): Observable<EntityFileResult> {
    return this.http.post(AppConfig.urlPrefix + this.url + '/deleteMarketPlacePhoto' + `/${entityFileId}`, []).pipe(
    map((data: EntityFileResult) => {
        return data;
    }));
}
}
