import { PagedResult } from './../../../model/common/paged-result';
import { Param } from './../../../model/common/param';
import { Component, ViewChild } from '@angular/core';
import { GenericManage, GenericManageViewMode } from '../../generic/generic.manage';
import { PermissionRoleDetail as PermissionRoleDetailUI } from "./permission-role.detail";
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AppUtils } from '../../../common/app.utils';
import { Permission } from '../../../model/api/common/permission';
import { PermissionHttpService } from '../../../services/http/common/permission.http.service';
import { RoleList } from '../../auth/role.list';
import { RoleEntity } from '../../../model/api/common/role-entity';
import { RoleService } from '../../../services/http/identity/role.service';
import { PermissionRole } from '../../../model/api/common/permission-role';
import { PermissionRoleList } from './permission-role.list';
import { PermissionRoleHttpService } from '../../../services/http/common/permission-role.http.service';
import { PermissionList } from '../permission/permission.list';
import { PermissionTypeList } from '../permission-type/permission-type.list';
import { PermissionType } from '../../../model/api/common/permission-type';
import { PermissionTypeHttpService } from '../../../services/http/common/permission-type.http.service';
import { Res } from '../../../resources/resources';

@Component({
    selector: 'permission-role-manage',
    templateUrl: 'permission-role.manage.html',
    styleUrls: ['permission-role.manage.scss'],
})
export class PermissionRoleManage extends GenericManage<PermissionRole, number> {

    @ViewChild('permissionRoleDetailModal') permissionRoleDetailModal: ModalDirective;
    @ViewChild('permissionRoleList') permissionRoleList: PermissionRoleList;
    @ViewChild('permissionRoleDetail') permissionRoleDetail: PermissionRoleDetailUI;
    @ViewChild('permissionListModal') permissionListModal: ModalDirective;
    @ViewChild('permissionList') permissionList: PermissionList;
    @ViewChild('permissionTypeListModal') permissionTypeListModal: ModalDirective;
    @ViewChild('permissionTypeList') permissionTypeList: PermissionTypeList;
    @ViewChild('permissionTypeCloneListModal') permissionTypeCloneListModal: ModalDirective;
    @ViewChild('permissionTypeCloneList') permissionTypeCloneList: PermissionTypeList;
    @ViewChild('roleListModal') roleListModal: ModalDirective;
    @ViewChild('roleList') roleList: RoleList;

    @ViewChild('roleCloneListModal') roleCloneListModal: ModalDirective;
    @ViewChild('roleCloneList') roleCloneList: RoleList;

    public exportBtnInfo = Res.ExportBtnInfo;
    public filter: string = '';
    public selectedPermission: Permission = null;
    public selectedPermissionType: PermissionType = null;
    public selectedPermissionTypeClone: PermissionType = null;
    public selectedRole: RoleEntity = null;

    public selectedRoleClone: RoleEntity = null;
    isCollapsed: boolean = true;

    constructor(public permissionHttpService: PermissionHttpService,
                public permissionTypeHttpService: PermissionTypeHttpService,
                public roleHttpService: RoleService,
                public permissionRoleHttpService: PermissionRoleHttpService) {

        super();
    }

    public addNewItem() {
        super.addNewItem();

        this.permissionRoleDetail.permission = null;
        this.permissionRoleDetail.permissionType = null;
        this.permissionRoleDetail.role = null;
    }

    public editItem() {
        super.editItem();

        let permissionRole: PermissionRole = this.selectedItem as PermissionRole;

        this.permissionRoleDetail.permission = null;

        if (permissionRole != null && permissionRole.permission != null) {
            this.permissionHttpService
                .getById(permissionRole.permission.id)
                .subscribe((permission: Permission) => {
                    this.permissionRoleDetail.permission = permission;
                });
        }

        this.permissionRoleDetail.permissionType = null;

        if (permissionRole != null && permissionRole.permissionType != null) {
            this.permissionTypeHttpService
                .getById(permissionRole.permissionType.id)
                .subscribe((permissionType: PermissionType) => {
                    this.permissionRoleDetail.permissionType = permissionType;
                });
        }

        this.permissionRoleDetail.role = null;

        if (permissionRole != null && permissionRole.role != null) {
            this.roleHttpService
                .getById(permissionRole.role.id)
                .subscribe((role: RoleEntity) => {
                    this.permissionRoleDetail.role = role;
                });
        }
    }

    public detailInitialize() {
        super.detailInitialize();
        this.permissionRoleDetailModal.show();
    }

    public detailTerminate() {
        super.detailTerminate();
        this.permissionRoleDetailModal.hide();
    }

    public onPermissionRoleDetailPermissionNeeded() {
        this.permissionRoleDetailModal.hide();
        this.selectPermission();
    }

    public onPermissionListCancel() {
        this.permissionListModal.hide();
        if (this.viewMode === GenericManageViewMode.ItemDetail) {
            this.permissionRoleDetailModal.show();
        }
    }


    public onPermissionRoleDetailPermissionTypeNeeded() {
        this.permissionRoleDetailModal.hide();
        this.selectPermissionType();
    }

    public onPermissionTypeListCancel() {
        this.permissionTypeListModal.hide();
        if (this.viewMode === GenericManageViewMode.ItemDetail) {
            this.permissionRoleDetailModal.show();
        }
    }


    public onPermissionTypeCloneListCancel() {
        this.permissionTypeCloneListModal.hide();
        if (this.viewMode === GenericManageViewMode.ItemDetail) {
            this.permissionRoleDetailModal.show();
        }
    }

    public onPermissionRoleDetailRoleNeeded() {
        this.permissionRoleDetailModal.hide();
        this.selectRole();
    }

    public onRoleListCancel() {
        this.roleListModal.hide();
        if (this.viewMode === GenericManageViewMode.ItemDetail) {
            this.permissionRoleDetailModal.show();
        }
    }

    public refresh() {
        let params: Array<Param> = new Array<Param>();

        params.push(new Param('filter', this.filter));
        params.push(new Param("permissionIds", AppUtils.getIdsList<Permission, number>([ this.selectedPermission ])));
        params.push(new Param("permissionTypeIds", AppUtils.getIdsList<PermissionType, number>([ this.selectedPermissionType ])));
        params.push(new Param("roleIds", this.selectedRole != null ? this.selectedRole.id : null ));
        this.permissionRoleList.refresh(params);
    }

    public selectPermission() {
        this.permissionListModal.show();
        this.permissionList.refresh(null);
    }

    public setSelectedPermission() {
        switch(this.viewMode) {
            case GenericManageViewMode.ItemList:
                this.selectedPermission = this.permissionList.selectedItem;
                this.permissionListModal.hide();
                this.refresh();
                break;
            case GenericManageViewMode.ItemDetail:
                this.permissionRoleDetail.permission = this.permissionList.selectedItem;
                this.permissionListModal.hide();
                this.permissionRoleDetailModal.show();
                break;
        }
    }


    public unselectPermission() {
        this.selectedPermission = null;
        this.refresh();
    }

    public selectPermissionType() {
        this.permissionTypeListModal.show();
        this.permissionTypeList.refresh(null);
    }

    public setSelectedPermissionType() {
        switch(this.viewMode) {
            case GenericManageViewMode.ItemList:
                this.selectedPermissionType = this.permissionTypeList.selectedItem;
                this.permissionTypeListModal.hide();
                this.refresh();
                break;
            case GenericManageViewMode.ItemDetail:
                this.permissionRoleDetail.permissionType = this.permissionTypeList.selectedItem;
                this.permissionTypeListModal.hide();
                this.permissionRoleDetailModal.show();
                break;
        }
    }


    public unselectPermissionType() {
        this.selectedPermissionType = null;
        this.refresh();
    }


    public selectPermissionTypeClone() {
        this.permissionTypeCloneListModal.show();
        this.permissionTypeCloneList.refresh(null);
    }

    public setSelectedPermissionTypeClone() {
        this.permissionRoleHttpService.clone(this.selectedPermissionType.id, this.permissionTypeCloneList.selectedItems[0].id).subscribe( (result) => {
            if (result){
                this.permissionTypeCloneListModal.hide();
                this.refresh();
            }
        });
    }


    public unselectPermissionTypecLONE() {
        this.setSelectedPermissionTypeClone = null;
        this.refresh();
    }


    public selectRole() {
        this.roleListModal.show();
        this.roleList.refresh(null);
    }

    public setSelectedRole() {
        switch(this.viewMode) {
            case GenericManageViewMode.ItemList:
                this.selectedRole = this.roleList.selectedItem;
                this.roleListModal.hide();
                this.refresh();
                break;
            case GenericManageViewMode.ItemDetail:
                this.permissionRoleDetail.role = this.roleList.selectedItem;
                this.roleListModal.hide();
                this.permissionRoleDetailModal.show();
                break;
        }
    }

    public unselectRole() {
        this.selectedRole = null;
        this.refresh();
    }

    public exportToExcel() {

         let params: Array<Param> = null;

        if ((this.filter != null) && (this.filter.length > 0)) {
            params = new Array<Param>();
            params.push(new Param('filter', this.filter));
        }

        this.permissionHttpService.get(1, 1000000, 'id', 'asc', params, null).subscribe(
            (data: PagedResult<Permission>) => {

                let options = {
                    sheetid: 'emailManager',
                    headers: true,
                    column: { style: { Font: { Bold: '1' } } },
                    rows: { 1: { style: { Font: { Color: '#FF0077' } } } },
                    cells: { 1: { 1: { style: { Font: { Color: '#00FFFF' } } } } }
                };

                // let res = alasql(`SELECT id as [Id]
                //                     INTO XLSX("emailManager.xlsx",?) FROM ?`,[ options, data.items ]);

            });
    }

    collapsed(event: any): void {
        // console.log(event);
      }

      expanded(event: any): void {
        // console.log(event);
      }

      clone (){
        this.selectPermissionTypeClone();
      }


      cloneAll (){
        this.selectRouteCloneAll();
      }

      public selectRouteCloneAll() {
        this.roleCloneListModal.show();
        this.roleCloneList.refresh(null);
    }

    public setSelectedRouteCloneAll() {
      let cloneAll = false;
      if(this.selectedPermissionType == null){
        if(confirm('Esti sigur ca vrei sa clonezi toate permisiunile?')){
          cloneAll = true;
          this.selectedPermissionType = new PermissionType(0, '', '');
        }
      }

        this.permissionRoleHttpService.cloneAll(this.selectedPermissionType.id, this.roleList.selectedItems[0].id, this.roleCloneList.selectedItems[0].id, cloneAll).subscribe( (result) => {
            if (result){
                this.roleCloneListModal.hide();
                this.refresh();
            }
        });
    }


    public unselectRouteCloneAll() {
        this.roleCloneListModal.hide();
        this.selectedRoleClone = null;
        this.refresh();
    }
}
