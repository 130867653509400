export class OfferMaterialUpdate {
    id: number;
    quantity: number;
    price: number;
    wip: boolean;

    constructor (id: number, quantity: number, price: number, wip: boolean) {
        this.id = id;
        this.quantity = quantity;
        this.price = price;
        this.wip = wip;
    }
}
