import { PagedResult } from './../../../model/common/paged-result';
import { Param } from './../../../model/common/param';
import { Component, ViewChild } from '@angular/core';
import { GenericManage } from '../../generic/generic.manage';
import { DictionaryType } from '../../../model/api/administration/dictionary-type';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { OfferTypeHttpService } from '../../../services/http/offfers/offer-type.http.service';
import { OfferType } from '../../../model/api/offer/offer-type';
import { OfferTypeListComponent } from './offer-type.list';
import { OfferTypeDetailComponent } from './offer-type.detail';
@Component({
    selector: 'app-offer-type-manage',
    templateUrl: 'offer-type.manage.html',
    providers: [ OfferTypeHttpService ]
})
export class OfferTypeManageComponent extends GenericManage<OfferType, number> {

    @ViewChild('offerTypeDetailModal') offerTypeDetailModal: ModalDirective;
    @ViewChild('offerTypeList') offerTypeList: OfferTypeListComponent;
    @ViewChild('offerTypeDetail') offerTypeDetail: OfferTypeDetailComponent;
    isCollapsed: boolean = true;
    public filter: string = '';

    constructor(public offerTypeHttpService: OfferTypeHttpService) {
        super();
    }

    public addNewItem() {
        super.addNewItem();
    }

    public editItem() {
        super.editItem();
    }

    public detailInitialize() {
        super.detailInitialize();
        this.offerTypeDetailModal.show();
    }

    public detailTerminate() {
        super.detailTerminate();
        this.offerTypeDetailModal.hide();
    }

    public refresh() {
        const params: Array<Param> = new Array<Param>();

        params.push(new Param('filter', this.filter));

        this.offerTypeList.refresh(params);
    }

    public exportToExcel(){

         let params: Array<Param> = null;

        if ((this.filter != null) && (this.filter.length > 0)) {
            params = new Array<Param>();
            params.push(new Param('filter', this.filter));
        }

        this.offerTypeHttpService.get(1, 10000000, 'code', 'asc', params, null).subscribe(
            (data: PagedResult<DictionaryType>) => {

                const options = {
                    sheetid: 'Orase',
                    headers: true,
                    column: { style: { Font: { Bold: '1' } } },
                    rows: { 1: { style: { Font: { Color: '#FF0077' } } } },
                    cells: { 1: { 1: { style: { Font: { Color: '#00FFFF' } } } } }
                };

                // alasql(`SELECT id as [Id],
                //     code as [Cod],
                //     name as [Denumire]
                //     INTO XLSX("Orase.xlsx",?) FROM ?`, [ options, data.items ]);

            });

    }

    collapsed(event: any): void {
        // console.log(event);
      }
      expanded(event: any): void {
        // console.log(event);
      }
}
