import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Router } from "@angular/router";
import { GenericTableList } from '../generic/generic.table.list';


@Component({
    selector: 'app-role-list',
    templateUrl: 'role.list.html',
    styleUrls: ['role.list.scss']
})
export class RoleList extends GenericTableList<any, string> {

      constructor(public router: Router) {
        super('name', 'asc', 'roles');
    }

    ngAfterViewInit() {
    }
}
