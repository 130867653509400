import { Component, EventEmitter, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { EmployeeManager } from '../../../model/api/administration/employee-manager';
import { EmployeeResource } from '../../../model/api/administration/employee-resource';
import { Manager } from '../../../model/api/administration/manager';
import { GenericDetail } from '../../generic/generic.detail';

@Component({
    selector: 'employee-manager-detail',
    templateUrl: 'employee-manager.detail.html',
    outputs: ['employeeNeeded', 'managerNeeded']
})
export class EmployeeManagerDetail extends GenericDetail<EmployeeManager, number> {

    @ViewChild('detailForm') detailForm: FormGroup;

    public managerNeeded: EventEmitter<void> = new EventEmitter<void>();
    public employeeNeeded: EventEmitter<void> = new EventEmitter<void>();

    public selectedManager: EmployeeResource = null;
    public selectedEmployee: EmployeeResource = null;

    setItemDefaultValues() {
        this.item = new EmployeeManager(0, null, null);
    }

    public resetForm() {
        this.detailForm.reset();
    }

    public saveItem() {
      super.saveItem();
    }

    public set manager(manager: Manager) {
        this.selectedManager = manager;
        this.item.manager = manager != null ? new EmployeeResource(manager.id, manager.internalCode, manager.firstName, manager.lastName, manager.email) : null;
    }

    public askForCostCenter() {
        this.managerNeeded.emit();
    }

    public set employee(employee: EmployeeResource) {
        this.selectedEmployee = employee;
        this.item.employee = employee != null ? new EmployeeResource(employee.id, employee.internalCode, employee.firstName, employee.lastName, employee.email) : null;
    }

    public askForEmployee() {
        this.employeeNeeded.emit();
    }
}
