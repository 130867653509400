import { EmployeeListComponent } from '../administrations/employees/employee.list';
import { EmployeeHttpService } from '../../services/http/administration/employee.http.service';
import { AdmCenterListComponent } from '../administrations/adm-centers/adm-center.list';
import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DivisionListComponent } from '../administrations/divisions/division.list';
import { RoomListComponent} from '../administrations/rooms/room.list';
import { RoomHttpService } from '../../services/http/administration/room.http.service';
import { DivisionHttpService } from '../../services/http/administration/division.http.service';
import { AdmCenterHttpService } from '../../services/http/administration/adm-center.http.service';
import { UserListComponent } from './user.list';
import { AssetEntityListComponent } from '../assets/assets/asset-entity.list';
import { IdentityService } from '../../services/http/identity/identity.service';
import { AssetHttpService } from '../../services/http/assets/asset.http.service';
import { Param } from '../../model/common/param';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { NotificationService } from '../../services/notification.service';
import { DeviceList } from '../common/devices/device.list';
import { DeviceHttpService } from '../../services/http/common/device.http.service';
import { RoleView } from '../../model/api/identity/role-view';
import { RoleSave } from '../../model/api/identity/role-save';
import { RoleResult } from '../../model/api/result/role-result';
import { RoleService } from '../../services/http/identity/role.service';
import { AppUtils } from '../../common/app.utils';
import { Res } from '../../resources/resources';

@Component({
    selector: 'identity-manage',
    templateUrl: 'identity.manage.html',
    styleUrls: ['identity.manage.scss'],
    providers: [ AdmCenterHttpService, EmployeeHttpService, DivisionHttpService, RoomHttpService ]
})
export class IdentityManage {

    public exportBtnInfo = Res.ExportBtnInfo;


    @ViewChild('registerModal') registerModal: ModalDirective;
    @ViewChild('userList') userList: UserListComponent;


    public role: string = 'all';
    public filter: string = '';
    public model: any = {};
    public errorMessages: string[] = [];
    public errorMessage: string = '';

    public allTemps: string = 'NO';
    public fromDate: Date = new Date();
    public toDate: Date = new Date();

    availableRoles: RoleView[] = null;
    selectedRoles: RoleView[] = [];
    selectedRoleIds: Array<number> = new Array<number>();
    public aspNetRole: RoleView = null;

    public item: RoleSave = new RoleSave();
    public isSaved: boolean = true;
    public initialSortColumn = '';
    public initialSortDirection = 'asc';
    public initialPageSize = 50;
    public refreshBtnInfo = Res.RefreshBtnInfo;

    @ViewChild('admCenterList') public admCenterList: AdmCenterListComponent;
    @ViewChild('admCenterListModal') public admCenterListModal: ModalDirective;
    @ViewChild('assetTempList') public assetTempList: AssetEntityListComponent;
    @ViewChild('assetTempListModal') public assetTempListModal: ModalDirective;
    @ViewChild('employeeList') public employeeList: EmployeeListComponent;
    @ViewChild('employeeListModal') public employeeListModal: ModalDirective;
    @ViewChild('substituteList') public substituteList: EmployeeListComponent;
    @ViewChild('substituteListModal') public substituteListModal: ModalDirective;
    @ViewChild('roomList') public roomList: RoomListComponent;
    @ViewChild('roomListModal') public roomListModal: ModalDirective;
    @ViewChild('divisionList') public divisionList: DivisionListComponent;
    @ViewChild('divisionListModal') public divisionListModal: ModalDirective;
    @ViewChild('deviceList') public deviceList: DeviceList;
    @ViewChild('deviceListModal') public deviceListModal: ModalDirective;

    constructor(public router: Router,
                public identityHttpService: IdentityService,
                public identityService: IdentityService,
                public notificationService: NotificationService,
                public assetHttpService: AssetHttpService,
                public admCenterHttpService: AdmCenterHttpService,
                public divisionHttpService: DivisionHttpService,
                public roomHttpService: RoomHttpService,
                public deviceHttpService: DeviceHttpService,
                public roleHttpService: RoleService,
                public employeeHttpService: EmployeeHttpService) {

    }

    ngAfterViewInit() {
        this.initialSortColumn = this.userList.sortColumn;
        this.initialSortDirection = this.userList.sortDirection;
        this.initialPageSize = this.userList.pageSize;
        this.refreshUsers();
    }

    public resetAllFilters(){
        //set first page + refetch data
        this.userList.currentPage = 1;
        this.userList.pageSize = this.initialPageSize;
        this.userList.resetRows(this.initialPageSize);
        
        //reset sorting
        this.userList.sortColumn = this.initialSortColumn;
        this.userList.sortDirection = this.initialSortDirection;
        this.userList.resetSort();
        
        //reset filters
        this.clearFilters();
    }

    public clearFilters() {
        this.role = 'all'; 
        this.filter = '';
        this.refreshUsers();
    }

    public export() {
        this.userList.export$('Administrare').subscribe(
          () => {},
          error => {}
        );
      }
    
      get canExport() {
        return this.userList?.canExport;
      }

    public onUserSelectionChanged(users: Array<any>) {
      if(users.length > 0) {
      let params:Array<Param> = Array<Param>();
        params.push(new Param('userId', users.length > 0 ? users[0].id : null));

        this.roleHttpService.get(1, 500, 'id', 'asc', params, null, 'selectedRoles').subscribe( (res: any) => {
          this.selectedRoles = [];
          res.items.forEach(element => {
              element.isLeftSide = true;
              this.selectedRoles.push(element);
          });

          // Available //

          params.push(new Param('exceptRoleIds', AppUtils.getIdsList<RoleView, string>(this.mappedRoles())));

            this.roleHttpService.get(1, 500, 'id', 'asc', params, null, 'availableRoles').subscribe( (res: any) => {
              this.availableRoles = [];
              res.items.forEach(element => {
                  element.isLeftSide = true;
                  this.availableRoles.push(element);
              });

              users.forEach(element => {
                element.isLeftSide = false;
            });
          });

          // Available //

          users.forEach(element => {
            element.isLeftSide = false;
        });
      });
      } else {
        this.selectedRoles = [];
        this.availableRoles = [];
      }
  }

  public mappedRoles(): Array<RoleView> {
    let mappedRoles: Array<RoleView> = new Array<RoleView>();

    if(this.selectedRoles.length > 0) {
        this.selectedRoles.forEach(element => {
          let item = new RoleView();
          item.id = element.id;
          item.alias = element.alias;
          item.name = element.name;
          mappedRoles.push(item);
        });
    }
    return mappedRoles;
}

    public saveUpdateRoles() {

      this.isSaved = false;
      this.item.userId = this.userList != null && this.userList.selectItem != null ? this.userList.selectedItem.id : null;

      this.item.roleIds = new Array<string>();
          this.selectedRoles.forEach(item => {
              this.item.roleIds.push(item.id);
          });
      this.identityService.updateUserRoles(this.item)
      .subscribe((result: RoleResult) => {
          if (result.success) {
            this.notificationService.showSuccess(result.message, 'Update Roluri');
            this.refreshUsers();
          } else if (!result.success) {
              this.notificationService.showError('Motiv: ' + result.message + '!', 'Eroare salvare date');
              this.isSaved = true;
          }
      });
  }


  public moveToTarget(event: any) {
    // this.notificationService.showInfo('', 'Move');
    // console.log(JSON.stringify(event));
    // this.showTemplate(1);
    this.selectedRoles.forEach(element => {
            // element.isLeftSide = false;
    });
  }

    public changeRole(claimsValue: string) {
        if(this.userList.selectedItem != null){
            if (confirm('Modificati utilizatorul selectat?')) {
                this.identityService.updateUser(this.userList.selectedItem.userName, claimsValue)
                    .subscribe(() => {
                        this.refreshUsers();
                        this.notificationService.showSuccess('Actualizarea rolului s a finalizat cu success!', 'Modificare rol user');
                    });
            }
        }else {
            this.notificationService.showSuccess('Eroare actualizare date!', 'Modificare rol user');
        }
    }

    public refreshUsers() {
        if (this.filter !== '' && this.filter !== undefined){
            this.userList.currentPage = 1;
            this.userList.first = 1;
            this.userList.resetDt();
        }

        let params: Array<Param> = new Array<Param>();
        params.push(new Param('filter', this.filter));
        params.push(new Param('role', this.role));

        this.userList.refresh(params);
    }

    public register() {
        // this.registerModal.show();
        this.router.navigate(['/register']);
    }

    public setSelectedRole(role: string) {
        this.role = role;
        this.refreshUsers();
    }
    public changePassword(user: string) {
        // this.router.navigate(['/passwordreset', user]);

        if (confirm('Doriti sa resetati parola?')) {
          this.identityService.generateNewPassword(user)
              .subscribe((res) => {
                  if(res) {
                    alert('Notificarea a fost transmisa cu succes!!');
                  }else {
                    alert('Userul nu exista!!');
                  }

              }, (error) => {
                alert(error);
              });

      }
    }
    public  deleteUser(user: string) {
        if (confirm('Stergeti utilizatorul selectat?')) {
            this.identityService.Delete(user)
                .subscribe(() => {
                   this.refreshUsers();
                    alert('Utilizatorul a fost sters.');
                });
        }
    }

    public selectAdmCenter() {
        this.admCenterListModal.show();
        this.admCenterList.refresh(null);
    }

    public setSelectedAdmCenter() {

        this.model.userId = this.userList.selectedItem.id;
        // this.model.admCenterId = this.admCenterList.selectedItem.id;

        if (this.admCenterList.selectedItems != null) {
            this.model.admCenterIds = new Array<number>();
            this.admCenterList.selectedItems.forEach((selectedItem) => {
                this.model.admCenterIds.push(selectedItem.id);
            });
        }



        this.identityService.UpdateUserAdmCenter(this.model)
            .subscribe(
            (res: any) => {
                // // IdentityResult.
                // if (res.succeeded) {
                //     // Signs in the user.
                //     this.router.navigate(['/identity']);
                // } else {
                //     this.errorMessages = res.errors;
                // }

                this.refreshUsers();

            },
            (error: any) => {

                // Error on post request.
                let errMsg = (error.message) ? error.message :
                    error.status ? `${error.status} - ${error.statusText}` : 'Server error';

                this.errorMessage = "Server error. Try later.";

            });

        this.admCenterListModal.hide();
    }

    public selectEmployee() {
        this.employeeListModal.show();
        this.employeeList.refresh(null);
    }

    public setSelectedEmployee() {
                this.model.userId = this.userList.selectedItem.id;
                this.model.employeeId = this.employeeList.selectedItem.id;

                this.identityService.UpdateUserEmployee(this.model)
                    .subscribe(
                    (res: any) => {
                        this.refreshUsers();
                    },
                    (error: any) => {
                        // Error on post request.
                        let errMsg = (error.message) ? error.message :
                            error.status ? `${error.status} - ${error.statusText}` : 'Server error';

                        this.errorMessage = "Server error. Try later.";
                    });

                this.employeeListModal.hide();
            }

            public selectSubstitute() {
                this.substituteListModal.show();
                this.substituteList.refresh(null);
            }

            public setSelectedSubstitute() {
                        this.model.userId = this.userList.selectedItem.id;
                        this.model.employeeId = this.substituteList.selectedItem.id;
                        this.model.fromDate = this.fromDate;
                        this.model.toDate = this.toDate;


                        this.identityService.UpdateUserSubstitute(this.model)
                            .subscribe(
                            (res: any) => {
                                this.refreshUsers();
                            },
                            (error: any) => {
                                // Error on post request.
                                let errMsg = (error.message) ? error.message :
                                    error.status ? `${error.status} - ${error.statusText}` : 'Server error';
                                this.errorMessage = "Server error. Try later.";
                            });
                        this.substituteListModal.hide();
                    }

            public selectRoom() {
                this.roomListModal.show();
                this.roomList.refresh(null);
            }
            public setSelectedRoom() {
                        this.model.userId = this.userList.selectedItem.id;
                        this.model.roomId = this.roomList.selectedItem.id;
                        this.identityService.UpdateUserRoom(this.model)
                            .subscribe(
                            (res: any) => {
                                this.refreshUsers();
                            },
                            (error: any) => {
                                // Error on post request.
                                let errMsg = (error.message) ? error.message :
                                    error.status ? `${error.status} - ${error.statusText}` : 'Server error';

                                this.errorMessage = "Server error. Try later.";
                            });

                        this.roomListModal.hide();
                    }

            public selectDivision() {
                this.divisionListModal.show();
                this.divisionList.refresh(null);
            }

            public setSelectedDivision() {
                        this.model.userId = this.userList.selectedItem.id;
                        this.model.divisionId = this.divisionList.selectedItem.id;

                        this.identityService.UpdateUserDivision(this.model)
                            .subscribe(
                            (res: any) => {
                                this.refreshUsers();
                            },
                            (error: any) => {
                                // Error on post request.
                                let errMsg = (error.message) ? error.message :
                                    error.status ? `${error.status} - ${error.statusText}` : 'Server error';

                                this.errorMessage = "Server error. Try later.";
                            });

                        this.divisionListModal.hide();
                    }



            public selectAssetTemp() {
                let selectedAssets = new Array<AssetEntityListComponent>();
                this.assetTempList.selectedItems = selectedAssets;

                this.assetTempListModal.show();
                this.assetTempList.refresh(null);
            }

            public setSelectedAssetTemp() {

                this.model.userId = this.userList.selectedItem.id;

                if (this.assetTempList.selectedItems != null) {
                    this.model.assetTempIds = new Array<number>();
                    this.assetTempList.selectedItems.forEach((selectedItem) => {
                        this.model.assetTempIds.push(selectedItem.id);
                    });
                }



                this.assetHttpService.mapAssetTemp(this.model)
                    .subscribe(
                    (res: any) => {
                        this.refreshUsers();
                    },
                    (error: any) => {

                        // Error on post request.
                        let errMsg = (error.message) ? error.message :
                            error.status ? `${error.status} - ${error.statusText}` : 'Server error';

                        this.errorMessage = "Server error. Try later.";

                    });

                this.assetTempListModal.hide();
            }

            public showAllTemps(){
                let params = new Array<Param>();

                if (this.allTemps === 'NO'){
                    this.allTemps = 'YES';
                }else {
                    this.allTemps = 'NO';
                }

                params.push(new Param('showAll', this.allTemps.toString()));
                params.push(new Param('companyCode', ''));
                this.assetTempList.refresh(params);
            }

            public showAllByCompanyTemps(code: string){

                let params = new Array<Param>();

                params.push(new Param('companyCode', code));
                this.assetTempList.refresh(params);
            }


            public selectDevice() {
                this.deviceListModal.show();
                this.deviceList.refresh(null);
            }

            public updateDevice(){
              if(confirm('Esti sigur ca vrei sa anulezi dispozitivul?')){
                  this.identityService.updateDevice(this.userList.selectedItem.id).subscribe((res) => {});
              }

              this.refreshUsers();
          }


          public setSelectedDevice() {

            this.model.userId = this.userList.selectedItem.id;
            this.model.deviceId = this.deviceList.selectedItem.id;

            this.identityService.UpdateUserDevice(this.model)
                .subscribe(
                (res: any) => {
                    // // IdentityResult.
                    // if (res.succeeded) {
                    //     // Signs in the user.
                    //     this.router.navigate(['/identity']);
                    // } else {
                    //     this.errorMessages = res.errors;
                    // }
                    this.refreshUsers();
                },
                (error: any) => {
                    // Error on post request.
                    let errMsg = (error.message) ? error.message :
                        error.status ? `${error.status} - ${error.statusText}` : 'Server error';
                    this.errorMessage = 'Server error. Try later.';
                });
            this.deviceListModal.hide();
        }

    public changeSubstituteFromDate(event) {
        this.fromDate = event.target.value;
        }

    public changeSubstituteToDate(event) {
        this.toDate = event.target.value;
        }

    public parseDate(dateString: any): Date {
        if (dateString) {
            return new Date(dateString.value);
        } else {
            return null;
        }
    }

    getRandomColor() {
      let letters = '0123456789ABCDEFG';
      let color = '#';
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      const styles = {
        'background-color' : color,
        'padding':"27px",
        "color":"white"
      };
      return styles;
    }
}
