import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Pocket } from '../../../model/api/common/device';
import { GenericHttpService } from '../generic.http.service';

@Injectable()
export class DeviceHttpService extends GenericHttpService<Pocket, number> {
    constructor(public http: HttpClient) {
        super(http, '', 'devices');
    }
}