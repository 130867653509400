import { PagedResult } from './../../../model/common/paged-result';
import { Param } from './../../../model/common/param';
import { Component, EventEmitter, ViewChild } from '@angular/core';
import { GenericManage } from '../../generic/generic.manage';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AmortizationHttpService } from '../../../services/http/sap/asset-dep-md-sync';
import { AssetDepMDSync } from '../../../model/api/sap/asset-dep-md-sync';
import { AmortizationListComponent } from './amortization.list';
import { AccMonthListComponent } from '../../accounting/acc-month.list';
import { AccMonth } from '../../../model/api/accounting/acc-month';
import { AccMonthHttpService } from '../../../services/http/accounting/acc-month.http.service';

@Component({
    selector: 'app-amortization-manage',
    templateUrl: 'amortization.manage.html',
    providers: [ AmortizationHttpService ]
})
export class AmortizationManageComponent extends GenericManage<AssetDepMDSync, number> {

    @ViewChild('itemDetailModal') modal: ModalDirective;
    @ViewChild('amortizationList') amortizationList: AmortizationListComponent;

    @ViewChild('accMonthList') public accMonthList: AccMonthListComponent;
    @ViewChild('accMonthListModal') public accMonthListModal: ModalDirective;

    isCollapsed: boolean = true;
    public filter: string = '';
    hideBtnSmdcSync = false;
    hideBtnSyncToOptima = false;
    public selectedAccMonths: Array<AccMonth> = new Array<AccMonth>();

    constructor(public amortizationHttpService: AmortizationHttpService, public accMonthHttpService: AccMonthHttpService) {
        super();
    }

    public detailInitialize() {
        this.modal.show();
    }

    public detailTerminate() {
        this.modal.hide();
    }

    public exportToExcel(){

        let params: Array<Param> = null;

        if ((this.filter != null) && (this.filter.length > 0)) {
            params = new Array<Param>();
            params.push(new Param('filter', this.filter));
        }

        this.amortizationHttpService.get(1, 10000000, 'code', 'asc', params, null).subscribe(
            (data: PagedResult<AssetDepMDSync>) => {

                let options = {
                    sheetid: 'Tipuri',
                    headers: true,
                    column: { style: { Font: { Bold: '1' } } },
                    rows: { 1: { style: { Font: { Color: '#FF0077' } } } },
                    cells: { 1: { 1: { style: { Font: { Color: '#00FFFF' } } } } }
                };

                // alasql.promise(`SELECT id as [Id], 
                //                     code as [Cod], 
                //                     name as [Denumire],
                //                     INTO XLSX("Tipuri-Observatii.xlsx", ?) FROM ?`, [ options, data.items ]);

            });
    }

    public syncSAP() {
        this.hideBtnSmdcSync = true;
        let month = this.selectedAccMonths.length > 0 ? this.selectedAccMonths[0].month.toString() : '';
        let year = this.selectedAccMonths.length > 0 ? this.selectedAccMonths[0].year.toString() : '';
        if (month.length === 1) {
            month = '00' + month;
        } else if (month.length === 2) {
            month = '0' + month;
        }
        this.amortizationHttpService.getDataFromSAP(month, year).subscribe((count) => {
            alert(JSON.stringify(count) + ' au fost importate!' );
            this.hideBtnSmdcSync = false;
            this.amortizationList.refresh(null);
       });

    }

    public syncToOptima() {
        this.hideBtnSyncToOptima = true;
        this.amortizationHttpService.updateOptima().subscribe((count) => {
            alert(JSON.stringify(count) + ' au fost importate in optima!' );
            this.hideBtnSyncToOptima = false;
            this.amortizationList.refresh(null);
       });

    }

    collapsed(event: any): void {
        // console.log(event);
      }

      expanded(event: any): void {
        // console.log(event);
      }

      /* begin AccMonth */

  public selectAccMonths() {
    this.accMonthListModal.show();
    this.accMonthList.selectedItems = this.selectedAccMonths;
    this.accMonthList.refresh(null);
  }

  public removeFromAccMonthSelection(accMonth: AccMonth) {
    const index: number = this.selectedAccMonths.indexOf(accMonth);
    this.selectedAccMonths.splice(index, 1);
  }

  public clearAccMonthSelection() {
    this.selectedAccMonths = new Array<AccMonth>();
  }

  public setSelectedAccMonths() {
    this.selectedAccMonths = this.accMonthList.selectedItems;
    this.accMonthListModal.hide();
  }

  /*end acc month */
}
