import { Component } from '@angular/core';
import { AppData } from '../../../app-data';
import { AppConfig } from '../../../config';
import { Param } from '../../../model/common/param';
import { ExportService } from '../../../services/export.service';
import { GenericPrimeNgDataTableList } from '../../generic/generic.prime-ng-data-table.list';

@Component({
    selector: 'app-asset-service-history-ops-detail-list',
    //templateUrl: '../../generic/generic.table.list.html',
    templateUrl: '../../generic/generic.prime-ng-data-table.list.html',
    inputs: [ 'listTemplate' ],
})
// export class AssetOpDetailListComponent extends GenericTableList<any, number> {
export class AssetServiceOpHistoryDetailListComponent extends GenericPrimeNgDataTableList<any, number> {

    public showEmployeeDetails: boolean = AppConfig.SHOW_EMPLOYEE_DETAILS;
    public listTemplate: string = 'SERVICEHISTORIES';

    constructor(public exportService: ExportService) {
        super('modifiedAt', 'desc', 'details', exportService);

        this.columns = AppData.ColumnDefinitions[this.listTemplate];
    }

    public refresh(filters: Array<Param>) {
        this.columns = AppData.ColumnDefinitions[this.listTemplate];
        super.refresh(filters);
    }
}
