import { Component } from '@angular/core';

@Component({
    selector: 'confirm-email-page',
    templateUrl: 'confirm-email-page.html'
})
export class ConfirmEmailPage {
    constructor() {

    }
}
