import { PagedResult } from './../../../model/common/paged-result';
import { Param } from './../../../model/common/param';
import { Component, EventEmitter, ViewChild } from '@angular/core';
import { GenericManage, GenericManageViewMode } from '../../generic/generic.manage';
import { DictionaryTypeHttpService } from '../../../services/http/administration/dictionary-type.http.service';
import { DictionaryType } from '../../../model/api/administration/dictionary-type';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { DictionaryTypeListComponent } from './dictionary-type.list';
import { DictionaryTypeDetailComponent } from './dictionary-type.detail';
@Component({
    selector: 'app-dictionary-type-manage',
    templateUrl: 'dictionary-type.manage.html',
    providers: [ DictionaryTypeHttpService ]
})
export class DictionaryTypeManageComponent extends GenericManage<DictionaryType, number> {

    @ViewChild('dictionaryTypeDetailModal') dictionaryTypeDetailModal: ModalDirective;
    @ViewChild('dictionaryTypeList') dictionaryTypeList: DictionaryTypeListComponent;
    @ViewChild('dictionaryTypeDetail') dictionaryTypeDetail: DictionaryTypeDetailComponent;
    isCollapsed: boolean = true;
    public filter: string = '';

    constructor(public dictionaryTypeHttpService: DictionaryTypeHttpService) {
        super();
    }

    public addNewItem() {
        super.addNewItem();
    }

    public editItem() {
        super.editItem();
    }

    public detailInitialize() {
        super.detailInitialize();
        this.dictionaryTypeDetailModal.show();
    }

    public detailTerminate() {
        super.detailTerminate();
        this.dictionaryTypeDetailModal.hide();
    }

    public refresh() {
        const params: Array<Param> = new Array<Param>();

        params.push(new Param('filter', this.filter));

        this.dictionaryTypeList.refresh(params);
    }

    public exportToExcel(){

         let params: Array<Param> = null;

        if ((this.filter != null) && (this.filter.length > 0)) {
            params = new Array<Param>();
            params.push(new Param('filter', this.filter));
        }

        this.dictionaryTypeHttpService.get(1, 10000000, 'code', 'asc', params, null).subscribe(
            (data: PagedResult<DictionaryType>) => {

                const options = {
                    sheetid: 'Orase',
                    headers: true,
                    column: { style: { Font: { Bold: '1' } } },
                    rows: { 1: { style: { Font: { Color: '#FF0077' } } } },
                    cells: { 1: { 1: { style: { Font: { Color: '#00FFFF' } } } } }
                };

                // alasql(`SELECT id as [Id], 
                //     code as [Cod], 
                //     name as [Denumire] 
                //     INTO XLSX("Orase.xlsx",?) FROM ?`, [ options, data.items ]);

            });

    }

    collapsed(event: any): void {
        // console.log(event);
      }
    
      expanded(event: any): void {
        // console.log(event);
      }
}
