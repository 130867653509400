import { Component, EventEmitter, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { GenericDetail } from '../../generic/generic.detail';
import { Department } from '../../../model/api/administration/department';
import { Employee } from '../../../model/api/administration/employee';
import { AppConfig } from '../../../config';
import { CostCenter } from '../../../model/api/administration/cost-center';
import { RoleEntity } from '../../../model/api/common/role-entity';
import { Company } from '../../../model/api/assets/company';
import { Manager } from '../../../model/api/administration/manager';
import { NotificationService } from '../../../services/notification.service';
import { EmployeeHttpService } from '../../../services/http/administration/employee.http.service';

@Component({
    selector: 'app-employee-allocate-detail',
    templateUrl: 'employee-allocate.detail.html',
    outputs: ['departmentNeeded', 'costCenterNeeded', 'managerNeeded', 'companyNeeded', 'roleNeeded']
})
export class EmployeeAllocateDetailComponent extends GenericDetail<Employee, number> {

  constructor(private notificationService: NotificationService, private employeeService: EmployeeHttpService) {
    super();

  }

    @ViewChild('detailForm') detailForm: FormGroup;

    public departmentRequired: boolean = AppConfig.EMPLOYEE_DEPARTMENT_REQUIRED;
    public departmentSelectedEvent: EventEmitter<Department>;
    public departmentNeeded: EventEmitter<void> = new EventEmitter<void>();
    public costCenterNeeded: EventEmitter<void> = new EventEmitter<void>();
    public companyNeeded: EventEmitter<void> = new EventEmitter<void>();
    public managerNeeded: EventEmitter<void> = new EventEmitter<void>();
    public roleNeeded: EventEmitter<void> = new EventEmitter<void>();

    public selectedDepartment: Department;
    // public selectedCostCenter: CostCenter[];
    public selectedDetailCostCenter: CostCenter;
    public selectedRole: RoleEntity = null;
    public selectedCompany: Company;
    public selectedManager: Manager;
    public state  = '';
    public empType  = '';
    public checkUniqueIC = true;
    public checkUniquePID = true;

    setItemDefaultValues() {
        this.item = new Employee(0, '', '', '', null, '', '', false, '');
    }

    public edit(item: Employee) {

        super.edit(item);
        this.state = item.state;
        this.empType = item.empType;
    }

    public resetForm() {
        this.detailForm.reset();
    }

    public set department(department: Department) {
        this.setDepartment(department);
    }

    public setDepartment(department: Department) {
        this.selectedDepartment = department;
        this.item.departmentId = department != null ? department.id : null;
    }

    public askForDepartment() {
        this.departmentNeeded.emit(null);
    }

    // public set costCenter(costCenter: CostCenter[]) {
    //     this.setCostCenter(costCenter);
    // }


    public set costCenterDetail(costCenter: CostCenter) {
      this.setDetailCostCenter(costCenter);
  }

    // public setCostCenter(costCenters: CostCenter[]) {
    //     this.selectedCostCenter = costCenters;
    //     this.item.costCenters = costCenters.length > 0 ? costCenters : null;
    // }

    public setDetailCostCenter(costCenter: CostCenter) {
      this.selectedDetailCostCenter = costCenter;
      this.item.costCenter = costCenter != null ? new CostCenter(costCenter.id, costCenter.code, costCenter.name) : null;
  }

    public askForCostCenter() {
        this.costCenterNeeded.emit(null);
    }

    public saveItem() {
        if ((this.selectedDepartment == null) || (this.selectedRole == null)) {
            alert('Departamentul si rolul sunt obligatoriu!');
        }
        else {
            super.saveItem();
        }
    }

    public set company(company: Company) {
      this.setCompany(company);
  }

  public setCompany(company: Company) {
      this.selectedCompany = company;
      this.item.companyId = company != null ? company.id : null;
  }

  public askForCompany() {
      this.companyNeeded.emit(null);
  }

  public set manager(manager: Manager) {
    this.setManager(manager);
}

public setManager(manager: Manager) {
    this.selectedManager = manager;
    this.item.manager = manager != null ? new Manager(manager.id, manager.internalCode, manager.firstName, manager.lastName, manager.departmentId, manager.email, manager.erpCode, manager.isDeleted) : null;
}

public askForManager() {
    this.managerNeeded.emit(null);
}

    public setSelectedState(selectedState: string) {
        // this.item.isDeleted = selectedState;
        this.state = selectedState;
    }

    public setSelectedEmpType(selectedState: string) {
      // this.item.isDeleted = selectedState;
      this.empType = selectedState;
  }

    public set role(role: RoleEntity) {
      this.selectedRole = role;
      this.item.roleId = role != null ? role.id : null;
  }

  public setRole(role: RoleEntity) {
    this.selectedRole = role;
    this.item.roleId = role != null ? role.id : null;
}

  public askForRole() {
      this.roleNeeded.emit();
  }

  public checkUniqueInternalCode(event: any) {

    setTimeout(() => {
        // console.log(this.asset.serialNumber);
        if (this.item != null && this.item.internalCode !== '' && this.item.internalCode != null && this.item.internalCode !== undefined && this.item.internalCode.trim().length > 0) {
            this.checkUniqueIC = false;
            this.employeeService.checkEditUniqueIC(this.item.id, this.item.internalCode)
            .subscribe((result: any) => {
                // console.log(JSON.stringify(result));
                if (!result) {
                    this.notificationService.showSuccess('OK', 'Verificare unicitate Marca HR');
                    this.checkUniqueIC = true;
                } else if (result) {
                    this.notificationService.showError('NOT OK', 'Verificare unicitate Marca HR');
                    this.checkUniqueIC = false;
                }
            });
        } else {
            this.notificationService.showInfo('Lungimea este prea scurta pentru verificare!', 'Verificare unicitate Marca HR');
            return;
        }
      }, 500);
}

public checkUniquePersonID(event: any) {

  setTimeout(() => {
      // console.log(this.asset.serialNumber);
      if (this.item != null && this.item.erpCode !== '' && this.item.erpCode != null && this.item.erpCode !== undefined && this.item.erpCode.trim().length > 0) {
          this.checkUniquePID = false;
          this.employeeService.checkEditUniquePID(this.item.id, this.item.erpCode)
          .subscribe((result: any) => {
              // console.log(JSON.stringify(result));
              if (!result) {
                  this.notificationService.showSuccess('OK', 'Verificare unicitate Person ID');
                  this.checkUniquePID = true;
              } else if (result) {
                  this.notificationService.showError('NOT OK', 'Verificare unicitate Person ID');
                  this.checkUniquePID = false;
              }
          });
      } else {
          this.notificationService.showInfo('Lungimea este prea scurta pentru verificare!', 'Verificare unicitate Person ID');
          return;
      }
    }, 500);
}
}
