export class AddStockAsset {
    id: number;
    name: string;
    serialNumber: string;
    // costCenterId: number;
    // employeeId: number;
    name2: string;
    subCategoryId: number;
    stockId: number;
}
