// import { AuthHttp } from 'angular2-jwt';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Document } from '../../../model/api/documents/document';
import { DocumentUpload } from '../../../model/api/documents/document-upload';
import { GenericHttpService } from './../generic.http.service';
import { AppConfig } from '../../../config';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { TransferResult } from '../../../model/api/result/transfer-result';

@Injectable()
export class DocumentHttpService extends GenericHttpService<Document, number> {
    constructor(public authHttp: HttpClient) {
        super(authHttp, '', 'documents');
    }

    public saveFullDocument(document: DocumentUpload): Observable<TransferResult> {
        return this.http.post(AppConfig.urlPrefix + this.url + '/full', document, { headers: this.headers }).pipe(
            map((response: TransferResult) => {
                return response;
            }));
    }

    public saveFullTempDocument(document: DocumentUpload): Observable<TransferResult> {
      return this.http.post(AppConfig.urlPrefix + this.url + '/fullTemp', document, { headers: this.headers }).pipe(
          map((response: TransferResult) => {
              return response;
          }));
  }

  public saveServiceDocument(document: DocumentUpload): Observable<TransferResult> {
    return this.http.post(AppConfig.urlPrefix + this.url + '/fullService', document, { headers: this.headers }).pipe(
        map((response: TransferResult) => {
            return response;
        }));
}

    public saveStateChange(document: DocumentUpload): Observable<{}> {
        return this.http.post(AppConfig.urlPrefix + this.url + '/stateChange', document, { headers: this.headers }).pipe(
            map((response) => {

                return response;

            }));
    }

    public savePartnerChange(document: DocumentUpload): Observable<{}> {
      return this.http.post(AppConfig.urlPrefix + this.url + '/partnerChange', document, { headers: this.headers }).pipe(
          map((response) => {

              return response;

          }));
  }

    public operation(document: DocumentUpload): Observable<{}> {
        return this.http.post(AppConfig.urlPrefix + this.url + '/validate', document, { headers: this.headers }).pipe(
            map((response) => {

                return response;

            }));
    }

    public reject(document: DocumentUpload): Observable<{}> {
      return this.http.post(AppConfig.urlPrefix + this.url + '/reject', document, { headers: this.headers }).pipe(
          map((response) => {

              return response;

          }));
  }


  public rejectAdministrator(document: DocumentUpload): Observable<{}> {
    return this.http.post(AppConfig.urlPrefix + this.url + '/rejectAdmin', document, { headers: this.headers }).pipe(
        map((response) => {

            return response;

        }));
}

    public reactivate(document: DocumentUpload): Observable<{}> {
        return this.http.post(AppConfig.urlPrefix + this.url + '/reactivate', document, { headers: this.headers }).pipe(
            map((response) => {

                return response;

            }));
    }

    validateDstEmployee(guid: string): Observable<TransferResult> {
      const url: string = AppConfig.urlPrefix + this.url + '/validateDstEmployee/' + guid;
      return this.http.post(url, { headers: this.headers }).pipe(
          map((data: TransferResult) => {
              return data;
          }));
    }

    rejectDstEmployee(guid: string, reason: string): Observable<TransferResult> {
      const url: string = AppConfig.urlPrefix + this.url + '/rejectDstEmployee/' + guid + '/' + reason;
      return this.http.post(url, { headers: this.headers }).pipe(
          map((data: TransferResult) => {
              return data;
          }));
    }
}
