import { Component, EventEmitter, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { GenericDetail } from '../../generic/generic.detail';
import { Manager } from '../../../model/api/administration/manager';

@Component({
    selector: 'app-manager-detail',
    templateUrl: 'manager.detail.html',
})
export class ManagerDetailComponent extends GenericDetail<Manager, number> {

    @ViewChild('detailForm') detailForm: FormGroup;

    public state  = 'INACTIV';

    setItemDefaultValues() {
        this.item = new Manager(0, '', '', '', null, '', '', false);
    }

    public edit(item: Manager) {

        super.edit(item);
        this.state = item.isDeleted === true ? 'INACTIV' : 'ACTIV';
    }

    public resetForm() {
        this.detailForm.reset();
    }

    public saveItem() {
      super.saveItem();
    }

    public setSelectedState(selectedState: boolean) {
        this.item.isDeleted = selectedState;
        this.state = selectedState === true ? 'INACTIV' : 'ACTIV';
    }
}
