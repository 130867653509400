import { PagedResult } from './../../../model/common/paged-result';
import { Param } from './../../../model/common/param';
import { Component, EventEmitter, ViewChild } from '@angular/core';
import { GenericManage } from '../../generic/generic.manage';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { RetireAssetSAPHttpService } from '../../../services/http/sap/retire-asset-sap-http.service';
import { RetireAssetSAP } from '../../../model/api/sap/retire-asset-sap';
import { RetireAssetSAPListComponent } from './retire-asset-sap.list';

@Component({
    selector: 'app-retire-asset-sap-manage',
    templateUrl: 'retire-asset-sap.manage.html',
    providers: [ RetireAssetSAPHttpService ]
})
export class RetireAssetSAPManageComponent extends GenericManage<RetireAssetSAP, number> {

    @ViewChild('itemDetailModal') modal: ModalDirective;
    @ViewChild('retireAssetSAPList') retireAssetSAPList: RetireAssetSAPListComponent;

    isCollapsed: boolean = true;
    public filter: string = '';
    hideBtnSmdcSync = false;
    hideBtnSyncToOptima = false;

    constructor(public retireAssetSAPHttpService: RetireAssetSAPHttpService) {
        super();
    }

    public detailInitialize() {
        this.modal.show();
    }

    public detailTerminate() {
        this.modal.hide();
    }

    public exportToExcel() {

        let params: Array<Param> = null;

        if ((this.filter != null) && (this.filter.length > 0)) {
            params = new Array<Param>();
            params.push(new Param('filter', this.filter));
        }

        this.retireAssetSAPHttpService.get(1, 10000000, 'code', 'asc', params, null).subscribe(
            (data: PagedResult<RetireAssetSAP>) => {

                // let options = {
                //     sheetid: 'Tipuri',
                //     headers: true,
                //     column: { style: { Font: { Bold: '1' } } },
                //     rows: { 1: { style: { Font: { Color: '#FF0077' } } } },
                //     cells: { 1: { 1: { style: { Font: { Color: '#00FFFF' } } } } }
                // };

                // alasql.promise(`SELECT id as [Id],
                //                     code as [Cod],
                //                     name as [Denumire],
                //                     INTO XLSX("Tipuri-Observatii.xlsx", ?) FROM ?`, [ options, data.items ]);

            });
    }

    collapsed(event: any): void {
        // console.log(event);
      }

      expanded(event: any): void {
        // console.log(event);
      }
}
