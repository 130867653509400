export class AssetSave {
    id: number;
    erpCode: string;
    serialNumber: string;
    invNo: string;
    // invNoOld: string;
    name: string;


    info: string;
    infoEmployee: string;
    lastTransferNumber: string;
    lastTransferReason: string;
    netAmount: number;
    lastRejectedReason: string;
    valueInvRon: number;
    // identifier: string;

    // documentTypeId: number;
    // documentId: number;
    // docNo1: string;
    // docNo2: string;
    // model: string;
    // documentDate: Date;
    // partnerDate: Date;
    // partnerId: number;

    assetTypeId: number;
    // valueInv: number;
    // assetCategoryId: number;
    companyId: number;
    invStateId: number;
    employeeId: number;
    assetNatureId: number;
    insuranceCategoryId: number;
    // isReconcile: string;
    // allowLabel: boolean;
    // departmentId: number;
    // costCenterId : number;
    // roomId: number;
    // quantity: number;
    // validated: boolean;
    // uomId: number;
    // dimensionId: number;
    // uomIdFinal: number;
    // dimensionIdFinal: number;
    // info2019: string;
    // serialNumberInv: string;
    // isDuplicate: boolean;
    // allowLabel: boolean;
    // administrationId: number;
    // //accStateId: number;
    // companyId: number;
    // //costCenterId: number;
    // //assetClassId: number;

    // partnerCUI: string;
    // partnerName: string;


    // valueRem: number;

    purchaseDate: Date;
    printDate: Date;
    lastTransferValidatedDate: Date;
    // partner: string;
    // invState: string;
    // details: string;
    infoMarket: string;
    infoMarket2: string;
    url: string;

    hasERPCodeError: boolean;
    hasDescriptionError: boolean;
    hasSerialNumberError: boolean;
    hasCompanyError: boolean;
    hasEmployeeError: boolean;
    hasEntryDateError: boolean;
    hasIsReconcileError: boolean;
    hasAllowLabelError: boolean;
    hasAssetTypeError: boolean;
}
