import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, forwardRef, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AppData } from '../../../app-data';
import { AppUtils } from '../../../common/app.utils';
import { DictionaryItem } from '../../../model/api/administration/dictionary-item';
import { AssetEmployeeEdit } from '../../../model/api/assets/asset-employee-edit';
import { AssetEmployeeSave } from '../../../model/api/assets/asset-employee-save';
import { Brand } from '../../../model/api/assets/brand';
import { Model } from '../../../model/api/assets/model';
import { CodeNameEntity } from '../../../model/api/common/code-name-entity';
import { ConfigValue } from '../../../model/api/common/config-value';
import { EmployeeValidateStatus } from '../../../model/api/common/employee-validate-status';
import { ColumnDefinition } from '../../../model/common/column-definition';
import { PagedResult } from '../../../model/common/paged-result';
import { Param } from '../../../model/common/param';
import { TableDefinition } from '../../../model/common/table-definition';
import { AuthenticationService } from '../../../services/authentication.service';
import { DictionaryItemHttpService } from '../../../services/http/administration/dictionary-item.http.service';
import { EmployeeHttpService } from '../../../services/http/administration/employee.http.service';
import { AssetHttpService } from '../../../services/http/assets/asset.http.service';
import { BrandHttpService } from '../../../services/http/assets/brand.http.service';
import { ModelHttpService } from '../../../services/http/assets/model.http.service';
import { ColumnDefinitionHttpService } from '../../../services/http/common/column-definition.http.service';
import { ConfigValuesHttpService } from '../../../services/http/common/config-values.service';
import { DashboardHttpService } from '../../../services/http/common/dashboard.http.service';
import { TableDefinitionHttpService } from '../../../services/http/common/table-definition.http.service';
import { NotificationService } from '../../../services/notification.service';
import { DictionaryItemDetailComponent } from '../../administrations/dictionary-item/dictionary-item.detail';
import { DictionaryItemListComponent } from '../../administrations/dictionary-item/dictionary-item.list';
import { BrandList } from '../brands/brand.list';
import { ModelList } from '../models/model.list';
import { JwtHelperService } from '@auth0/angular-jwt';
import { FileHttpService } from '../../../services/file.service';
import { saveAs as fileSaveAs } from 'file-saver-es';
import { AppConfig } from '../../../config';
import { AssetEmployeePersonalValidateListComponent } from './asset-employee-personal-validate.list';
import { Operation } from '../../../model/api/documents/operation';
import { DocumentUpload } from '../../../model/api/documents/document-upload';
import { DocumentHttpService } from '../../../services/http/documents/document.http.service';
import { Document } from '../../../model/api/documents/document';
import { AssetSimpleDetail } from '../../../model/api/assets/asset-simple-detail';
import { AssetImage, EntityFile } from '../../../model/api/common/entity-file';
import { EntityFileHttpService } from '../../../services/http/common/entity-file.http.service';
import { MatDialog } from '@angular/material/dialog';
import { UploadBuyModalComponent } from '../../common/upload-buy-modal.component';
import { ValidateAssetBuy } from '../../../model/api/assets/validate-asset-buy';
import { BuyResult } from '../../../model/api/result/buy-result';
import { EmployeeManagerList } from '../../administrations/employee-managers/employee-manager.list';
import { EmployeeManagerHttpService } from '../../../services/http/administration/employee-manager.http.service';
import { EmployeeManager } from '../../../model/api/administration/employee-manager';
import { ValidateEmployeeManager } from '../../../model/api/administration/validate-employee-manager';
import { AssetFilter } from '../../../model/api/assets/asset.filter';
import { Res } from '../../../resources/resources';


@Component({
    selector: 'app-asset-employee-allocate-approve-detail-ui',
    templateUrl: 'asset-employee-allocate-approve.detail.ui.html',
    styleUrls: ['asset-employee-allocate-approve.detail.ui.scss'],
    providers: [ AssetHttpService ]
})
export class AssetEmployeeAllocateApproveDetailUIComponent implements AfterViewInit  {

    @ViewChild('employeeManagerList') public employeeManagerList: EmployeeManagerList;

    @ViewChild('dictionaryItemDetail') public dictionaryItemDetail: DictionaryItemDetailComponent;
    @ViewChild('dictionaryItemList') public dictionaryItemList: DictionaryItemListComponent;

    @ViewChild('dictionaryItemDetailModal') public dictionaryItemDetailModal: ModalDirective;
    @ViewChild('dictionaryItemListModal') public dictionaryItemListModal: ModalDirective;

    @ViewChild('brandList') public brandList: BrandList;
    @ViewChild('brandListModal') public brandListModal: ModalDirective;

    @ViewChild('modelList') public modelList: ModelList;
    @ViewChild('modelListModal') public modelListModal: ModalDirective;

    @ViewChild('confirmationModal') public confirmationModal: ModalDirective;

    public assetEmployee: AssetEmployeeSave = new AssetEmployeeSave();
    // public selectedAsset: AssetSimpleDetail = null;
    public selectedAssets: Array<EmployeeManager> = new Array<EmployeeManager>();
    isSaved = true;
    decodedToken: any;
    public token = '';
    roleName = '';
    reasonReject = '';
    filter = '';
    //public pageSize = 50;
    public refreshBtnInfo = Res.RefreshBtnInfo; 
    public exportBtnInfo = Res.ExportBtnInfo;
    public rightsAcceptanceBtnInfo = Res.RightsAcceptanceBtnInfo;
    public rightsDeclineBtnInfo = Res.RightsDeclineBtnInfo;

    public itemsInventory = new Array<EmployeeValidateStatus>();

    public confirmationMessage: string = '';
    public operationType: OperationType = OperationType.NotSet;

    public imageCount: number = 0;
    public imageIndex: number = 0;
    public imageLoading: boolean = false;
    public assetImagesPdf: Array<AssetImage> = new Array<AssetImage>();
    public assetImages: Array<AssetImage> = new Array<AssetImage>();
    public assetFiles: Array<EntityFile> = new Array<EntityFile>();
    public existingAssetImages: Array<AssetImage> = new Array<AssetImage>();
    public initialSortColumn = '';
    public initialSortDirection = 'asc';
    public initialPageSize = 50;

    constructor(
        public route: ActivatedRoute,
        public router: Router,
        public assetHttpService: AssetHttpService,
        public employeeManagerHttpService: EmployeeManagerHttpService,
        public dictionaryItemHttpService: DictionaryItemHttpService,
        public brandHttpService: BrandHttpService,
        public modelHttpService: ModelHttpService,
        public notificationService: NotificationService,
        public employeeHttpService: EmployeeHttpService,
        public dashboardHttpService: DashboardHttpService,
        public tableDefinitionHttpService: TableDefinitionHttpService,
        public columnDefinitionHttpService: ColumnDefinitionHttpService,
        public configValuesHttpService: ConfigValuesHttpService,
        public authenticationService: AuthenticationService,
        private entityFileHttpService: EntityFileHttpService,
        private jwtService: JwtHelperService,
        private fileService: FileHttpService,
        public documentHttpService: DocumentHttpService,
        public dialog: MatDialog,
        public http: HttpClient) {

            this.itemsInventory.push(new EmployeeValidateStatus());

            this.token = localStorage.getItem('id_token');

            if (this.token === '' || this.token == null) {
                this.router.navigate(['/login']);
            }

            // this.route.params.subscribe((params: Params) => {
            //     if (params['id']) {
            //         this.guid = params['id'];
            //     }
            // });
    }

    ngAfterViewInit() {

        this.initialSortColumn = this.employeeManagerList.sortColumn;
        this.initialSortDirection = this.employeeManagerList.sortDirection;
        this.initialPageSize = this.employeeManagerList.pageSize;

        this.decodedToken = this.jwtService.decodeToken(this.token);
        const index = this.decodedToken.role[1];
        if (index.length > 1) {
          this.decodedToken.role = this.decodedToken.role.slice(0 , -1);
        }

        setTimeout(() => {
            this.loadTableConfigurationData(this.decodedToken.role);
            // this.refreshAssets();
            this.loadData();

       }, 2000);
    }

    public export() {
        this.employeeManagerList.export$('Aprobare drepturi').subscribe(
          () => {},
          error => {}
        );
      }
    
      get canExport() {
        return this.employeeManagerList?.canExport;
      }

    public onAssetInvFullDetailSelectionChanged(assets: Array<any>) {

        this.selectedAssets = new Array<any>();
        assets.forEach((asset: any) => {
            this.selectedAssets.push(asset);
        });
        // this.selectedAsset = ((assets != null) && (assets.length === 1)) ? assets[0] : null;
        // this.selectedAsset = this.assetInvFullDetailList.selectedItem;
    }

    onAssetEmployeeValidateListAfterViewInit() {
    }

    public refreshAssets() {
        if (this.filter !== '' && this.filter !== undefined){
            this.employeeManagerList.currentPage = 1;
            this.employeeManagerList.first = 1;
            this.employeeManagerList.resetDt();
        }

        const params: Array<Param> = this.getFilters();
        this.employeeManagerList.refresh(params);
    }

    /*end MODEL */

    public deleteAssetOp() {
        this.assetHttpService.deleteAssetValidation(this.employeeManagerList.selectedItem.id).subscribe( (assetId) => {
            if (assetId > 0){
                this.notificationService.showSuccess('Datele au fost salvate!', 'Stergere echipament');
                this.refreshAssets();
                this.loadData();
            }
        }, error => {
            this.notificationService.showError('Eroare!', 'Stergere echipament');
        });
    }

    public onDeleteAssetOp() {
        this.operationType = OperationType.AssetReject;
        this.confirmationMessage = 'Refuzati drepturile alocate?';
        this.confirmationModal.show();
    }

    public onTransferValidate() {
        this.operationType = OperationType.AssetValidation;
        this.confirmationMessage = 'Aprobati drepturile alocate?';
        this.confirmationModal.show();
    }

    public onConfirmationCanceled() {
        this.operationType = OperationType.NotSet;
        this.confirmationModal.hide();
    }

    public onConfirmationApproved() {

        switch (this.operationType) {
            case OperationType.Delete:
                this.deleteAssetOp();
                break;
            case OperationType.AssetValidation:
                 this.validate();
                break;
                case OperationType.AssetReject:
                  this.reject();
                 break;
            default:
                break;
        }

        this.operationType = OperationType.NotSet;
        this.confirmationModal.hide();
    }

    public clearFilters() {
        this.loadData();
        this.loadTableConfigurationData(this.decodedToken.role);
        // this.assetInvFullDetailList.refresh(null);
        this.isSaved = true;
    }

    public clearFilter(){
        this.filter = '';
    }

    public resetAllFilters(){
        //set first page + refetch data
        this.employeeManagerList.currentPage = 1;
        this.employeeManagerList.pageSize = this.initialPageSize;
        this.employeeManagerList.resetRows(this.initialPageSize);

        //reset sorting
        this.employeeManagerList.sortColumn = this.initialSortColumn;
        this.employeeManagerList.sortDirection = this.initialSortDirection;
        this.employeeManagerList.resetSort();
        
        //reset filters
        this.clearFilter();

        //clear selections
        this.clearSelection();
        
        //reset filters
        this.refreshAssets();
    }

    public loadData() {
        this.dashboardHttpService.employeesStatus().subscribe( (res: any[]) => {
          this.itemsInventory = res;
      });
    }

    public loadTableConfigurationData(userRole: string) {
        this.tableDefinitionHttpService.get(1, 1000000, 'id', 'asc', null, null, 'allowAnonymous')
          .subscribe((tableDefinitions: PagedResult<TableDefinition>) => {

            const params: Array<Param> = new Array<Param>();
            params.push(new Param('roleName', userRole));
            this.columnDefinitionHttpService.get(1, 1000000, 'tableDefinitionId', 'asc', params, null, 'allowAnonymous')
              .subscribe((columnDefinitions: PagedResult<ColumnDefinition>) => {
                AppData.UpdateColumnDefinitions(tableDefinitions.items, columnDefinitions.items);
                this.refreshAssets();
                // this.configValuesHttpService.get(1, 1000000, 'id', 'asc', params)
                //   .subscribe((configValues: PagedResult<ConfigValue>) => {
                //     AppData.UpdateConfigValues(configValues.items);

                //   });
              });
          });
      }

    public get isAdmin(): boolean {
        return this.decodedToken != null && this.decodedToken.role != null && this.decodedToken.role === 'administrator';
    }

    public getSN(): void {
        const url = `${AppConfig.urlPrefix}documents/manual/serie`;
            window.open(url);
          }

    public getIMEI(): void {
    const url = `${AppConfig.urlPrefix}documents/manual/imei`;
        window.open(url);
        }

    public validate() {
        this.isSaved = false;
        let validate = new ValidateEmployeeManager();
        validate.id = this.selectedAssets.length > 0 ? this.selectedAssets[0].id : 0;
        validate.reason = '';
        this.employeeManagerHttpService.acceptAllocate(validate).toPromise().then((data) => {
            this.isSaved = false;
            this.notificationService.showSuccess(data.message, 'Alocare drepturi');
            this.clearSelection();
            this.refreshAssets();
    }, (error) => {
        alert('Eroare server');
    });
    }

    public reject() {
      this.isSaved = false;
      let validate = new ValidateEmployeeManager();
          validate.id = this.selectedAssets.length > 0 ? this.selectedAssets[0].id : 0;
          validate.reason = this.reasonReject;
    this.employeeManagerHttpService.rejectAllocate(validate)
    .subscribe((result: BuyResult) => {
        if (result.success) {
            this.notificationService.showSuccess(result.message, 'Alocare drepturi');
            this.isSaved = true;
            this.clearSelection();
            this.refreshAssets();
        } else if (!result.success) {
            this.notificationService.showError('Motiv: ' + result.message + '!', 'Eroare salvare date');
            this.isSaved = true;
            this.clearSelection();
            this.refreshAssets();
        }
    });
    }

    public saveDocument() {
        this.isSaved = false;
        let document: Document = null;
        let operation: Operation = null;
        let operations: Array<Operation> = null;
        let documentUpload: DocumentUpload = null;

        document = new Document(0, 2, '', '', new Date(), new Date(), false, '', '', new Date(), new Date());

        operations = new Array<Operation>();

        if (this.selectedAssets.length > 0) {
            this.selectedAssets.forEach(element => {
                operation = new Operation(0, element.id, false, null, 949, 0, 0, 0, 0, 3275, 0, 0, 0, 0, 0, 0);
                operations.push(operation);
            });

        }

        documentUpload = new DocumentUpload();
        documentUpload.id = document.id;
        documentUpload.documentTypeId = document.documentTypeId;
        documentUpload.docNo1 = document.docNo1;
        documentUpload.docNo2 = document.docNo2;
        documentUpload.documentDate = document.documentDate;
        documentUpload.registerDate = document.registerDate;
        documentUpload.validated = document.validated;
        documentUpload.details = document.details;
        documentUpload.serialNumber = document.serialNumber;
        documentUpload.operations = operations;

        this.documentHttpService.saveFullDocument(documentUpload).toPromise().then((data) => {
            this.isSaved = true;
            alert('Operatia a fost finalizata cu success!');
            this.clearSelection();
            this.refreshAssets();
    }, (error) => {
        alert('Eroare server');
    });
    }

        public get allowValidate(): boolean {
            return this.selectedAssets.length > 0 && !this.selectedAssets[0].complete;
        }

        public get allowUpload(): boolean {
          return this.selectedAssets.length > 0 && this.selectedAssets[0].state != null && (this.selectedAssets[0].state.code === 'BUY_BOOK' || this.selectedAssets[0].state.code === 'EXTRASE_BUY_REJECT');
      }

        public get allowTransferStock(): boolean {
            return this.selectedAssets.length > 0 && this.selectedAssets[0].state == null;
        }

                public showPhoto(type: string){

                switch (type) {
                    case 'TRANSFER':
                    // this.refreshAssetOpEntityFiles(this.assetInvFullDetailList.selectedItem.valueAdd, true);
                        break;
                    default:
                        break;
                }
        }

        public clearSelection() {
            this.selectedAssets = new Array<EmployeeManager>();
            this.employeeManagerList.selectedItems = this.selectedAssets;
        }


        refreshAssetOpEntityFiles(assetId: number, loadAssetImages: boolean) {
            this.entityFileHttpService.getByEntity('TRANSFER', assetId)
            .subscribe((entityFiles: Array<EntityFile>) => {
                this.existingAssetImages.splice(0, this.existingAssetImages.length);
                this.assetImages.forEach((assetImage: AssetImage) => this.existingAssetImages.push(assetImage));
                this.assetImages.splice(0, this.assetImages.length);
                this.assetFiles.splice(0, this.assetFiles.length);
                // this.entityFileMemoryDataSource.clear();
                entityFiles.forEach((entityFile: EntityFile) => {
                    if (entityFile.fileType.startsWith('application/')) {
                        let fileContent: any = null;
                        this.existingAssetImages.forEach((assetImage: AssetImage) => {
                            if (assetImage.entityFile.id === entityFile.id) {
                                fileContent = assetImage.fileContent;
                            }
                        });
                        this.assetImages.push(new AssetImage(entityFile, fileContent));
                    } else {
                        this.assetFiles.push(entityFile);
                        // this.entityFileMemoryDataSource.addItem(entityFile);
                    }
                });
               // this.fileList.refresh(null);
                if (loadAssetImages) { this.loadAssetImages(); }
            });
        }
        private loadAssetImages() {
            if ((this.assetImages !== null) && (this.assetImages.length > 0)) {
                this.imageCount = this.assetImages.length;
                this.imageIndex = 0;
                this.imageLoading = true;
                this.loadAssetImageLoop();
            }
        }

        private loadAssetImageLoop() {
            if (this.assetImages.length > this.imageIndex) {
                const assetImage: AssetImage = this.assetImages[this.imageIndex];
                if (assetImage.fileContent === null) {
                    this.entityFileHttpService.downloadPdf(assetImage.entityFile.id).subscribe((image) => {
                        this.createImageFromBlob(assetImage, image as any);
                        this.loadNextAssetImage();
                    });
                } else {
                    this.loadNextAssetImage();
                }
            }
        }

        private createImageFromBlob(assetImage: AssetImage, image: Blob) {
            const reader = new FileReader();
            reader.addEventListener('load', () => {
               // this.images.push(reader.result);
               assetImage.fileContent = reader.result;
               // console.log(assetImage);
            }, false);
            if (image) {
               reader.readAsDataURL(image);
               fileSaveAs(image, 'BM - ' + assetImage.entityFile.entityId + '.pdf');
            }
        }

        private loadNextAssetImage() {
            if (this.imageIndex < (this.assetImages.length - 1)) {
                this.imageIndex++;
                this.loadAssetImageLoop();
            } else {
                this.imageLoading = false;
            }
        }

        public onAssetValidate() {
          this.operationType = OperationType.AssetValidation;
          this.confirmationMessage = 'Validati propunerea selectata?';
          this.confirmationModal.show();
      }

      public onAssetReject() {
        this.operationType = OperationType.AssetReject;
        this.confirmationMessage = 'Refuzati propunerea selectata?';
        this.confirmationModal.show();
    }

    onAssetFileUpload() {
      const requestId = this.selectedAssets.length > 0 ? this.selectedAssets[0].id : 0;
      const dialogRef = this.dialog.open(UploadBuyModalComponent, {
        panelClass: 'centered-middle-modal', height: '80%', maxHeight: '80%', disableClose: true, width: '600px', position: { bottom: '15%', top: 'auto'},
        data: { uploadType: 'BUY_BOOK', uploadFolder: 'BUY', assetId: requestId }
      });
      dialogRef.afterClosed().subscribe(() => {
        this.refreshAssets();
      });
    }

    // public onPageUpdate(number: number) {
    //   this.pageSize = number;
    //   this.refreshAssets();
    // }

    public getFilters(): Array<Param> {
      const params = new Array<Param>();
      // const assetFilter: AssetFilter = new AssetFilter();

      //assetFilter.filter = this.filter;
      //params.push(new Param('pageSize', this.pageSize.toString()));
      //params.push(new Param('jsonFilter', JSON.stringify(assetFilter)));
      params.push(new Param('filter', this.filter));
      return params;
    }

    // public get allowUploadFile(): boolean {
    //   return this.selectedAssets.length > 0 && this.selectedAssets[0].state != null && this.selectedAssets[0].state.code === 'BUY_BOOK';
    // }
}

enum OperationType {
    NotSet = 1,
    AssetValidation = 2,
    AssetReject = 3,
    Delete = 4,
    ProcessAssetOp = 5
}
