import { Param } from './../../../model/common/param';
import { Component, ViewChild } from '@angular/core';
import { GenericManage } from '../../generic/generic.manage';
import { Router } from '@angular/router';
import { saveAs as fileSaveAs } from 'file-saver-es';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AppData } from '../../../app-data';
import { Manager } from '../../../model/api/administration/manager';
import { ManagerListComponent } from './manager.list';
import { ManagerDetailComponent } from './manager.detail';
import { ManagerHttpService } from '../../../services/http/administration/manager.http.service';
import { ManagerDetailHttpService } from '../../../services/http/administration/manager-detail.http.service';

@Component({
    selector: 'app-manager-manage',
    templateUrl: 'manager.manage.html',
})
export class ManagerManageComponent extends GenericManage<Manager, number> {

    @ViewChild('managerDetailModal') managerDetailModal: ModalDirective;
    @ViewChild('managerList') managerList: ManagerListComponent;
    @ViewChild('managerDetail') managerDetail: ManagerDetailComponent;

    public filter: string = '';
    isCollapsed: boolean = true;
    pageSize = 10;
    public data;
    public deletedEmployees: boolean = false;
    public get isAdmin(): boolean { return AppData.UserIsAdmin; }

    constructor(public managerHttpService: ManagerHttpService,
                public router: Router,
                public managerDetailHttpService: ManagerDetailHttpService) {

        super();
    }

    public syncEmp() {

        this.managerHttpService.updateAllEmp().subscribe((count) => {
             alert('Au fost actualizati ' + JSON.stringify(count) + ' angajati!');
            // this.toastr.success('Au fost actualizati ' + JSON.stringify(count) + ' angajati!');
       });

}

    public showDeletedEmployees(){

        let params = new Array<Param>();
        params.push(new Param('deleted', JSON.stringify(this.deletedEmployees)));

        this.managerList.refresh(params);

    }

    public addNewItem() {
        super.addNewItem();
    }

    public editItem() {
        super.editItem();
    }

    public detailInitialize() {
        super.detailInitialize();
        this.managerDetailModal.show();
    }

    public detailTerminate() {
        super.detailTerminate();
        this.managerDetailModal.hide();
    }

    public refresh() {
        let params: Array<Param> = new Array<Param>();

        params.push(new Param('filter', this.filter));
        params.push(new Param('pageSize', this.pageSize.toString()));
        params.push(new Param('deleted', JSON.stringify(this.deletedEmployees)));

        this.managerList.refresh(params);
    }

    public getFilters(): Array<Param> {
        let params: Array<Param> = new Array<Param>();
        params.push(new Param('filter', this.filter));
        params.push(new Param('deleted', JSON.stringify(this.deletedEmployees)));
        return params;
    }

    public exportToExcel() {

        let params: Array<Param> = null;

        params = this.getFilters();
        this.managerHttpService
            .export(params)
            .subscribe((blob) => {
                fileSaveAs(blob.body, 'Export.xlsx');
            });
    }

    public editEmployee() {
        let selectedEmployeeId = this.managerList.selectedItems.length > 0 ? this.managerList.selectedItems[0].id : 0;
        if (selectedEmployeeId > 0) {
            this.router.navigate(['/manager', selectedEmployeeId]);
        }
    }



    collapsed(event: any): void {
        // console.log(event);
      }

      expanded(event: any): void {
        // console.log(event);
      }

      public onPageUpdate(number: number) {
        this.pageSize = number;
        this.refresh();
    }

}
