import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DictionaryType } from '../../../model/api/administration/dictionary-type';
import { GenericHttpService } from '../generic.http.service';
// import { AuthHttp } from 'angular2-jwt';

@Injectable()
export class DictionaryTypeHttpService extends GenericHttpService<DictionaryType, number> {
    constructor(public http: HttpClient) {
        super(http, "", "dictionarytypes");
    }
}