import { Component } from '@angular/core';
import { EntityFile } from '../../model/api/common/entity-file';
import { GenericTableList } from '../generic/generic.table.list';

@Component({
    selector: 'app-entity-file-list',
    templateUrl: 'entity-file.list.html'
})
export class EntityFileListComponent extends GenericTableList<EntityFile, number> {
    constructor() {
        super('name', 'asc');
    }
}