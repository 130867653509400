import { Component, EventEmitter, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DeviceType } from '../../../model/api/common/device-type';
import { AssetDepMDSync } from '../../../model/api/sap/asset-dep-md-sync';
import { GenericDetail } from '../../generic/generic.detail';

@Component({
    selector: 'app-amortization-detail',
    templateUrl: 'amortization.detail.html'
})
export class AmortizationDetailComponent extends GenericDetail<AssetDepMDSync, number> {

    @ViewChild('detailForm') detailForm: FormGroup;

    setItemDefaultValues() {
        this.item = new AssetDepMDSync();
    }

    public resetForm() {
        this.detailForm.reset();
    }
}