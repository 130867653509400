import { Component, EventEmitter, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Region } from '../../../model/api/administration/region';
import { PagedResult } from '../../../model/common/paged-result';
import { Param } from '../../../model/common/param';
import { RegionHttpService } from '../../../services/http/administration/region.http.service';
import { GenericManage } from '../../generic/generic.manage';

@Component({
    selector: 'app-region-manage',
    templateUrl: 'region.manage.html',
    providers: [ RegionHttpService ]
})
export class RegionManageComponent extends GenericManage<Region, number> {

     @ViewChild('itemDetailModal') modal: ModalDirective;

     isCollapsed: boolean = true;
    public filter: string = '';

    constructor(public regionHttpService: RegionHttpService) {
        super();
    }

     public detailInitialize() {
        this.modal.show();
    }

    public detailTerminate() {
        this.modal.hide();
    }

    public exportToExcel() {

        // let params: Array<Param> = null;

        // if ((this.filter != null) && (this.filter.length > 0)) {
        //     params = new Array<Param>();
        //     params.push(new Param('filter', this.filter));
        // }

        // this.regionHttpService.get(1, 1000000, 'name', 'asc', params, null).subscribe(
        //     (data: PagedResult<Region>) => {

        //         let options = {
        //             sheetid: 'centre_logistice',
        //             headers: true,
        //             column: { style: { Font: { Bold: '1' } } },
        //             rows: { 1: { style: { Font: { Color: '#FF0077' } } } },
        //             cells: { 1: { 1: { style: { Font: { Color: '#00FFFF' } } } } }
        //         };

        //         alasql(`SELECT id as [Id],
        //             name as [Denumire]
        //             INTO XLSX("centre_logistice.xlsx",?) FROM ?`, [ options, data.items ]);

        //     });
    }

    collapsed(event: any): void {
        // console.log(event);
      }
    
      expanded(event: any): void {
        // console.log(event);
      }
}
